import React                    from 'react'
import {QueryRenderer, graphql} from 'react-relay'
import env                      from './../../Environment'
import styled               from 'styled-components'
import {withTranslation}    from 'react-i18next'
import {getLanguageFromUrl}         from './../../utility/LanguageHelper'

import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import "react-tabs/style/react-tabs.css"

import HotelDetailsDescription  from './HotelDetailsDescription'
import HotelDetailsMap          from './HotelDetailsMap'
import HotelDetailsComments     from './HotelDetailsComments'

import styles from './HotelDetails.module.css'
import { Container } from '../SearchResults/ResultDealShowLoading'

const HotelName = styled.div`
    color: #fff;
    font-size: 1.4em;
    letter-spacing: 1px;
    cursor: pointer;
    text-transform: uppercase;
`
const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

const DetailsNotAvailable = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    color: #777;
    text-transform: uppercase; 
`

const query = graphql`
    query HotelDetailsQuery(
            $hotelId: String!, 
            $language: String!
    ){
        hotelDetails(
            hotelId: $hotelId, 
            language: $language
        ){
            address
            category
            chain
            city
            country
            description
            importantInformation
            name
            photos
            rankingCanarias
            rateCanarias
            rateTripAdvisor
            theme
            phone
            latitude
            longitude
            facilities{
                code
                group
                groupName
                isPayment
                name
                type
                value      
            }
            checkinOut{
                checkinFrom
                checkinTo
                checkoutFrom
                checkoutTo      
            }  
            canariasComments{
                generalRating
                rateCleaning
                rateDreamQuality
                rateDrinkingSelection
                rateFood
                rateLocation
                rateQualityPriceRelation
                rateRooms
                rateService
                reviewContent
                reviewDate
                title
                tripType 
                user{
                    name
                    place	
                  }             
              }    		
              tripAdivsorComments{
                numReviews
                rating
                reviewRating1
                reviewRating2
                reviewRating3
                reviewRating4
                reviewRating5
                awards{
                  award_type
                  category
                  image
                  name
                  year                
                }
                breakdown{
                  name
                  value
                }
                comments{
                  date
                  helpfulVotes
                  publishedDate
                  rating
                  ratingImageUrl
                  text
                  title
                  tripType
                  userLocation
                  username 
                  url               
                }              
                subrating{
                  name
                  value
                }
              }
            
        }
    }
`


const HotelDetails = ({t, hotelId, language}) => {

    const render = hotelDetails => {
        const showComments = (hotelDetails && hotelDetails.canariasComments && hotelDetails.canariasComments.length > 0) || 
                             (hotelDetails && hotelDetails.tripAdivsorComments !== null)

        return !hotelDetails 
                    ?   <DetailsNotAvailable>{t("hotel_details_not_available")}</DetailsNotAvailable>
                    :
                        <Tabs className={styles.tabs} selectedTabClassName={styles.tabSelected}>
                            <TabList className={styles.tabList}>
                                <Tab className={styles.tab}>{t("hotel_details.description")}</Tab>    
                                <Tab className={styles.tab}>{t("hotel_details.map")}</Tab>    
                                {showComments && <Tab className={styles.tab}>{t("hotel_details.reviews")}</Tab> }
                            </TabList>  
                            
                            <TabPanel className={styles.tabPanel} selectedClassName={styles.tabPanelSelected}>
                                <HotelDetailsDescription 
                                    name={hotelDetails.name}
                                    address={hotelDetails.address}
                                    description={hotelDetails.description}
                                    phone={hotelDetails.phone}
                                    ii={hotelDetails.importantInformation}
                                    checkInOut={{
                                        checkinFrom:    "09:01",
                                        checkinTo:      "10:01",
                                        checkoutFrom:   "11:01",
                                        checkoutTo  :   "12:01",
                                    }}
                                    facilities={hotelDetails.facilities}
                                />
                            </TabPanel>  
                            <TabPanel className={styles.tabPanel} selectedClassName={styles.tabPanelSelected}>
                                <HotelDetailsMap latitude={parseFloat(hotelDetails.latitude)} longitude={parseFloat(hotelDetails.longitude)} />
                            </TabPanel>  
                            <TabPanel className={styles.tabPanel} selectedClassName={styles.tabPanelSelected}>
                                <HotelDetailsComments canarias={hotelDetails.canariasComments} tripadvisor={hotelDetails.tripAdivsorComments} />                    
                            </TabPanel>  
                        </Tabs>             
        }

    return <QueryRenderer 
                environment={env.environment}
                query={query}
                variables={{hotelId, language: getLanguageFromUrl()}}
                render={({error, props}) => 
                            error ? <div>{alert('error')}</div>
                         : !props ? <Loading><FontAwesomeIcon icon="sync" className={styles.iconSync} /></Loading>
                         : render(props.hotelDetails)
                       }
            />
}

export default withTranslation()(HotelDetails)