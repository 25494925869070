export default {
    translation: {
        "send_by_email.message_sent_1":                     "Ошибка при отправке электронной почты",
        "send_by_email.message_sent_0":                     "Email отправлено",
        "send_by_email.email-placeholder":                  "Ваш электронный адрес",
        "send_by_email.message-placeholder":                "написать сообщение",
        "send_by_email.title":                              "Отправить по электронной почте",
        "send_by_email.button":                             "послать",        

        "map.open-button":                                  "Посмотреть карту",

        "hotel_blocking_error":                             "Ошибка при создании бронирования с этим отелем",
        "hotel_blocking_error_button":                      "Забронировать другой отель",

        "filters.filters":                                  "фильтр",
        "resultDeal.totalPriceText":                        "Общая стоимость",

        "resultDeal.roomTypeTitle":                         "тип комнаты",
        "resultDeal.changeRoomTitle":                       "раздевалка",
        "resultDeal.changeRoomsTitle":                      "раздевалки",
        "resultDistribution.roomsQuantity":                 "(Еще __num__ комнат)",

        "hotel_blocking.message":                           "Мы создаем ваше бронирование...",

        "hotel_details_not_available":                      "детали не доступны",                
        "distributions.up_to_x_person":                     "До __num__ человек",

        "hotel_details.description":                        "Описание",
        "hotel_details.map":                                "карта",
        "hotel_details.reviews":                            "Отзывы",
        "hotel_details.CheckIn":                            "прибытие",
        "hotel_details.CheckOut":                           "Покидать",        
        "hotel_details.FacilitiesTitle":                    "Оборудование",        
        "canariasComments.title":                           "Отзывы Canarias.com",        
        "canariasComments.rateCleaning":                    "очищающий",
        "canariasComments.rateDreamQuality":                "Качество сна",
        "canariasComments.rateDrinkingSelection":           "Выбор напитков",
        "canariasComments.rateFood":                        "питание",
        "canariasComments.rateLocation":                    "Место нахождения",
        "canariasComments.rateQualityPriceRelation":        "Цена денег",
        "canariasComments.rateRooms":                       "спальни",
        "canariasComments.rateService":                     "обслуживание",
        "canariasComments.resumeTitle":                     "Результаты Canarias.com",
        "canariasComments.numberOfComments":                "На основании __num__ отзывов",        
        "canariasComments.fromUser":                        "",        
        "canariasComments.fromPlace":                       "из",    
        "canariasComments.trip_type_1":                     "Командировка",                                
        "canariasComments.trip_type_2":                     "Путешествие в паре",
        "canariasComments.trip_type_3":                     "Семейное путешествие",
        "canariasComments.trip_type_4":                     "Путешествие друзей",
        "canariasComments.trip_type_5":                     "Пасьянс Путешествие",

        "TripadvisorComments.title":                        "Отзывы TripAdvisor",        
        "TripadvisorComments.resumeTitle":                  "Результаты TripAdvisor",                
        "TripadvisorComments.readMore":                     "Прочитайте больше",        
        "TripadvisorComments.NumerOfComments":              "На основании __num__ отзывов",        

        "TripadvisorComments.rate_value":                   "Цена денег",        
        "TripadvisorComments.rate_cleanliness":             "очищающий",        
        "TripadvisorComments.rate_sleep":                   "Качество сна",                
        "TripadvisorComments.rate_service":                 "обслуживание",        
        "TripadvisorComments.rate_location":                "Место нахождения",        
        "TripadvisorComments.rate_room":                    "спальни",        
        
        "TripadvisorComments.TripType":                     "Тип поездки",              
        "TripadvisorComments.NULL":                         "n.d.", 
        "TripadvisorComments.business":                     "Для бизнеса", 
        "TripadvisorComments.couples":                      "В паре", 
        "TripadvisorComments.family":                       "В семье", 
        "TripadvisorComments.friends":                      "С друзьями", 
        "TripadvisorComments.solo":                         "Одинокий", 

        "RoomNotesModalInfo":                               "Информация",
        "search_box.horizontal_label_place":                "Use our Hotel finder to find your perfect hotel",
        "search_box.label_place":                           "Введите отель, город ...",
        "search_box.place_placeholder":                     "Введите свой отель или пункт назначения",
        "search_box.label_pickup_date":                     "Дата поступления",
        "search_box.label_dropoff_date":                    "Дата отбытия",
        "search_box.label_room_and_people":                 "Количество комнат и людей",
        "search_box.search_button_text":                    "Поиск",
        "search_box.room_selection.confirm_button_text":    "подтвердить",
        "search_box.room_selection.adult":                  "для взрослых",        
        "search_box.room_selection.adults":                 "Взрослые",
        "search_box.room_selection.child":                  "ребенок",        
        "search_box.room_selection.children":               "Дети",
        "search_box.room_selection.childrenAges":           "Возраст детей",
        "search_box.room_selection.room":                   "Номер",   
        "search_box.room_selection.rooms":                  "Номера",          
        "search_box.place":                                 "Место",  
        "search_box.hotels":                                "Отели",  
        "search_box.errors.errors_title":                   "Есть следующие ошибки:", 
        "search_box.errors.select_place":                   "Выберите место в отеле", 
        "search_box.errors.wrong_start_date":               "Дата получения не действительна", 
        "search_box.errors.wrong_end_date":                 "Дата сдачи не действительна", 
        "search_box.errors.wrong_distributions":            "Распределение недействительно",
        "filters.main_title":                               "Фильтровать по имени",
        "filters.filter_by_category":                       "Фильтровать по категории",
        "filters.filter_by_deal":                           "Фильтр по сделке",
        "filters.filter_by_facilities":                     "Фильтр по объектам",
        "filters.filter_by_price":                          "Фильтровать по цене",
        "filters.button_filter_text":                       "Фильтр",
        "filters.button_remove_filter_text":                "Очистить фильтры",
        "filters.change_search":                            "Изменить поиск",                                                
        "deal.button_select_text":                          "Выбрать",
        "deal.button_modify_text":                          "изменять",    
        "deal_selection.button_select_text":                "Выбрать",

        "category.0":		"Не доступно",
        "category.0L":		"Не доступно",
        "category.1":		"1",
        "category.10":		"Pоскошь",
        "category.11":		"предложение",
        "category.13":		"13",
        "category.1L":		"1L",
        "category.2":		"2",
        "category.20":		"3L",
        "category.23":		"3",
        "category.26":		"26",
        "category.28":		"28",
        "category.29":		"29",
        "category.2L":		"2L",
        "category.3":		"3",
        "category.30":		"30",
        "category.31":		"31",
        "category.32":		"32",
        "category.34":		"34",
        "category.36":		"36",
        "category.3L":		"3L",
        "category.4":		"4",
        "category.40":		"40",
        "category.4L":		"4L",
        "category.5":		"5",
        "category.5L":		"5L",
        "category.8":		"первый",
        "category.93":		"93",
        "category.A":		"A",
        "category.AB":		"общежитие",
        "category.AGRO":	"агротуризм",
        "category.Ak":		"Туристическая квартира",
        "category.AP":		"Апарт-отель",
        "category.AR":		"Сельская квартира",
        "category.AT":		"квартира",
        "category.BA":		"спа",
        "category.BB":		"BB",
        "category.BO":		"бутик",
        "category.BU":		"бунгало",
        "category.CA":		"замок",
        "category.CAM":		"CAM",
        "category.CB":		"кабина",
        "category.CC":		"Пещерные дома",
        "category.CE":		"Специальная категория",
        "category.CH":		"Гостевой Дом",
        "category.CM":		"Сельский Комплекс",
        "category.CO":		"дом",
        "category.CP":		"кемпинг",
        "category.CR":		"Сельский дом",
        "category.CS":		"Сельский дом со СПА",
        "category.CT":		"Центр сельского туризма",
        "category.Cz":		"Cz",
        "category.FO":		"гостиница",
        "category.H1":		"общежитие *",
        "category.H2":		"общежитие **",
        "category.HO":		"общежитие",
        "category.HP":		"гостиница",
        "category.HR":		"Сельская Гостиница",
        "category.HS":		"Отель Со СПА",
        "category.HT":		"отель",
        "category.IN":		"Не доступно",
        "category.LO":		"домик",
        "category.MH":		"Мини Отель",
        "category.MO":		"монастырь",
        "category.MT":		"мотель",
        "category.PA":		"общежитие",
        "category.PE":		"пенсия",
        "category.PENDI":	"котловина",
        "category.PO":		"Pousada",
        "category.PR":		"Посада Реал",
        "category.RA":		"RA",
        "category.RE":		"жилой",
        "category.RS":		"Отель высшей категории в сельской местности",
        "category.RT":		"курорт",
        "category.ST":		"стандарт",
        "category.TU":		"турист",
        "category.TUS":		"Улучшенный турист",
        "category.VI":		"вилла",
        "category.VV":		"Дома для отдыха",

        "package.Add":      "Добавить опыт",
        "package.Selected": "ИЗБРАННЫЕ",
        "package.Select":   "ВЫБРАТЬ",
        "package.Book":     "резерв",

        "availability.NoResults": "Мы не нашли доступных отелей. Попробуйте еще раз, изменив параметры поиска.",

        "availability.orderBy.Recommended": "рекомендуемый",
        "availability.orderBy.Price": "цена",
        "availability.orderBy.Category": "категория",
    }
}
