import React from 'react'
import styled from 'styled-components'
import {withTranslation} from 'react-i18next'

import ReviewRate from './../Widget/ReviewRate'

const Container = styled.div`
    padding: 0px 100px;   

    @media (max-width: 768px){
        padding: 0;   
    }
`


const Resume = styled.div`
    display: flex;
    margin-top: 0px;    
    border-bottom: 2px dotted #ccc;
    padding-bottom: 30px;
    margin-bottom: 30px;  
    
    @media (max-width: 768px){
        flex-direction: column;
    }
` 

const ResumeLeft = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;  
    
    @media (max-width: 768px){
        margin-bottom: 20px;
    }
` 
const ResumeRight = styled.div`
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    :after {
        content: '';
        flex: auto;
    }
` 

const ResumeTitle = styled.div`
    font-size: 1.2em;
    margin-bottom: 10px;
` 

const NumReviews = styled.div`

`

const Comment = styled.div`
    display: flex;
    border-bottom: 2px dotted #ddd;
    padding-bottom: 40px;
    margin-bottom: 40px;

    @media (max-width: 768px){
        flex-direction: column;
    }
`

const CommentRight = styled.div`
    flex: 4;
    display: flex;
    flex-direction: column;
`

const CommentRates = styled.div`
    display: flex;
    flex-wrap: wrap;
`


const Title = styled.div`
    display: flex;
    font-size: 1.2em;
    line-height: 1.7em;
    text-transform: uppercase;
    background-color: #f5f5f5;

    @media (max-width: 768px){
        flex-direction: column;
        background-color: transparent;
    }
`

const UserRating = styled(ReviewRate)`
    margin: 0 15px 0  0;
`
const Rating = styled(ReviewRate)`
    width: 200px;
    margin: 5px 0 15px 0;
`

const CommentText = styled.div`
    padding: 20px 0;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 20px;
`
const CommentDate = styled.div`

`

const TripType = styled.div`
    font-style: italic;
    margin-top: 5px;
`
const User = styled.div`
    display: block;
    padding: 0 20px;
    width: 250px;
    
    @media (max-width: 768px){
        width: 100%;
        padding: 0 0 20px
    }
`

const UserName = styled.div`
    width: 100%;
    display: block;
    clear: both;
    
    @media (max-width: 768px){
        display: inline-blocK;
        float: left;
    }
`
const UserPlace = styled.div`
    width: 100%;
    display: block;
    clear: both;
    margin-bottom: 10px;

    @media (max-width: 768px){
        display: inline-blocK;
        float: left;
    }
`

const UserImage = styled.img`
    margin: 0 auto;
    border-radius: 50%;
    margin-bottom: 10px;  
    
    @media (max-width: 768px){
        display: none;
    }
`

const HotelCommentsCanarias = ({t, comments}) => {

    // se calculan los rating haciendo una media
    const globalRate = {
        generalRating:              0,
        rateCleaning:               0,
        rateDreamQuality:           0,
        rateDrinkingSelection:      0,
        rateFood:                   0,
        rateLocation:               0,
        rateQualityPriceRelation:   0,
        rateRooms:                  0,
        rateService:                0,
    }    

    if(comments){
        comments.map(c => {
            globalRate.generalRating            += c.generalRating
            globalRate.rateCleaning             += c.rateCleaning
            globalRate.rateDreamQuality         += c.rateDreamQuality
            globalRate.rateDrinkingSelection    += c.rateDrinkingSelection
            globalRate.rateFood                 += c.rateFood
            globalRate.rateLocation             += c.rateLocation
            globalRate.rateQualityPriceRelation += c.rateQualityPriceRelation
            globalRate.rateRooms                += c.rateRooms
            globalRate.rateService              += c.rateService
        })

        const numComments = comments.length

        globalRate.generalRating            = Math.round(globalRate.generalRating / numComments)
        globalRate.rateCleaning             = Math.round(globalRate.rateCleaning / numComments)
        globalRate.rateDreamQuality         = Math.round(globalRate.rateDreamQuality / numComments)
        globalRate.rateDrinkingSelection    = Math.round(globalRate.rateDrinkingSelection / numComments)
        globalRate.rateFood                 = Math.round(globalRate.rateFood / numComments)
        globalRate.rateLocation             = Math.round(globalRate.rateLocation / numComments)
        globalRate.rateQualityPriceRelation = Math.round(globalRate.rateQualityPriceRelation / numComments)
        globalRate.rateRooms                = Math.round(globalRate.rateRooms / numComments)
        globalRate.rateService              = Math.round(globalRate.rateService / numComments)
    }

    const renderResume = () => {
        return (
            <>
                <Resume>
                    <ResumeLeft>
                        <ResumeTitle>{t("canariasComments.resumeTitle")}</ResumeTitle>
                        <Rating label={""} value={globalRate.generalRating} />
                        <NumReviews>{t("canariasComments.numberOfComments").replace("__num__", comments.length)}</NumReviews>
                    </ResumeLeft>
                    <ResumeRight>
                        <Rating label={t("canariasComments.rateCleaning")}             value={globalRate.rateCleaning} />
                        <Rating label={t("canariasComments.rateDreamQuality")}         value={globalRate.rateDreamQuality} />
                        <Rating label={t("canariasComments.rateDrinkingSelection")}    value={globalRate.rateDrinkingSelection} />
                        <Rating label={t("canariasComments.rateFood")}                 value={globalRate.rateFood} />
                        <Rating label={t("canariasComments.rateLocation")}             value={globalRate.rateLocation} />
                        <Rating label={t("canariasComments.rateQualityPriceRelation")} value={globalRate.rateQualityPriceRelation} />
                        <Rating label={t("canariasComments.rateRooms")}                value={globalRate.rateRooms} />
                        <Rating label={t("canariasComments.rateService")}              value={globalRate.rateService} />                  
                    </ResumeRight>
                </Resume>
            </>
        )
    }
    
    const renderComment = (comment, key) => 
        <Comment key={key}>
                {renderUser(comment.user, comment.reviewDate, comment.tripType)}
                <CommentRight>
                    <Title>
                        <UserRating value={comment.generalRating} /> {comment.title}
                    </Title>  
                    <CommentText>{comment.reviewContent}</CommentText>                  
                    <CommentRates>                        
                        <Rating label={t("canariasComments.rateCleaning")}              value={comment.rateCleaning} />
                        <Rating label={t("canariasComments.rateDreamQuality")}          value={comment.rateDreamQuality} />
                        <Rating label={t("canariasComments.rateDrinkingSelection")}     value={comment.rateDrinkingSelection} />
                        <Rating label={t("canariasComments.rateFood")}                  value={comment.rateFood} />
                        <Rating label={t("canariasComments.rateLocation")}              value={comment.rateLocation} />
                        <Rating label={t("canariasComments.rateQualityPriceRelation")}  value={comment.rateQualityPriceRelation} />
                        <Rating label={t("canariasComments.rateRooms")}                 value={comment.rateRooms} />
                        <Rating label={t("canariasComments.rateService")}               value={comment.rateService} />
                    </CommentRates>                    
                </CommentRight>
        </Comment>

    const renderUser = (user, reviewDate, tripType) => 
        <User>
            <UserImage src="https://assets.canarias.com/Hotel/Content/images/user-avatar.jpg"/>
            <UserName>{t("canariasComments.fromUser")} {user.name}</UserName>
            <UserPlace>{t("canariasComments.fromPlace")} {user.place}</UserPlace> 
            <CommentDate>{reviewDate}</CommentDate>
            <TripType>{t(`canariasComments.trip_type_${tripType}`)}</TripType>            
        </User>

    return comments && comments.length > 0 && (
        <Container>
            {renderResume()}
            {comments.map((comment,index) => renderComment(comment, index))}
        </Container>
    )
}


export default withTranslation()(HotelCommentsCanarias)