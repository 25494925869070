import React                        from 'react'
import {QueryRenderer, graphql}     from 'react-relay'
import env                  from './../../Environment'
import { FontAwesomeIcon }          from '@fortawesome/react-fontawesome'

import AvailabilityResult           from './../Availability/AvailabilityResult'
import styles                       from './TempHotelResults.module.css'

class TempHotelByCodesResults extends React.Component{

    handleLoading = () => 
        <div style={{display:"flex", justifyContent:"center", alignItems:"center", width: "100px", margin: "auto auto"}}>
            <FontAwesomeIcon icon="sync" className={styles.iconSync} />
        </div>

    render() {
        return (
            // se cargan los hoteles de la zona y se muestran durante la espera de los resultados
            <QueryRenderer 
                environment={env.environment}
                query={graphql`
                    query TempHotelByCodesResultsQuery($hotelId: String!, $language: String!, $pageSize: Int, $pageNum: Int, $order: Int){
                        hotelsByCodes(hotelId: $hotelId, language: $language, pageSize: $pageSize, pageNum: $pageNum, order: $order){
                            _id
                            name
                            address
                            category
                            description      
                            photos
                            rankingCanarias
                            rateCanarias
                            rateTripAdvisor
                        }
                    }
                `}
                variables={{
                    hotelId:  this.props.hotelId,
                    language: this.props.language   || "es",
                    pageSize: this.props.pageSize   || 20,
                    pageNum:  this.props.pageNum    || 1,
                    order:    this.props.order      || 0
                }}
                
                // en base al estado se muestra el loading o los hoteles de la zona
                // en caso de error se deja el loading (solo es un componente para engañar la espera y cuando llegan los resultados se quita)
                render={  result => result.error       ?  this.handleLoading()
                        : !(result.props)              ?  this.handleLoading()  
                        :                                 <AvailabilityResult hotelsByZone={null} hotelsByCodes={result.props} filterDisabled={true} />         
                }
            />
        )
    }
}


export default TempHotelByCodesResults