import styled from 'styled-components'

import hotelFacilities from './../Widget/HotelFacilities'

export const Hotel = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1em 0;
`

export const HotelImage = styled.div`
    width: 300px;
    height: 200px;
    background-image: url(${props => props.src});
    background-position: center center;
    background-size: cover;
    cursor: pointer;

    @media (max-width: 768px){
        margin-top: 10px;
    }
`

export const HotelHiddenImage = styled.img`
    width: 1px;
    height: 1px;
    opacity: 0.01;
    position: absolute;
`

export const HotelDetails = styled.div`
    display: flex;
    @media (max-width: 768px){
        flex-direction: column;
        align-items: center;
    }
`

export const HotelInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

export const HotelName = styled.a`
    margin: 0;
    padding: 5px 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none; 
    flex: 1;  
    text-align: left;
    font-size: 1.2em;    
`

export const HotelAddress = styled.div`
    text-align: left;
    padding: 10px 20px;
`

export const HotelFacilities = styled(hotelFacilities)`
    display: flex;
    padding: 10px 20px;
    flex-wrap: wrap;
`

export const HotelTop = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

export const HotelHeader = styled.div`
    padding: 10px 20px;
    display: flex; 
    background-color: #b71c1c; 
    align-items: center; 
    @media (max-width: 768px){
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
`

export const HotelDescription = styled.div`
    background-color: #f5f5f5;
    flex: 0.65;
    display: flex;
    align-items: center;
    text-align: left;
    padding: 0 20px;

    @media(max-width: 1200px){
        display: none;
    }
`


export const HotelMinPrice = styled.div`
    font-size: 1.6em;
    color: #348427;
    margin: 5px 0;
    font-weight: bold;
`

export const HotelDeals = styled.div`
    display: flex;
    flex-direction: column;

`