import styled from 'styled-components'

export const Distribution = styled.div`
    display: flex;
    margin: 5px 0;

    @media (max-width: 768px){
        flex-direction: column;
        border-bottom: 1px dotted #ccc;
        margin: 5px 0;
        padding: 5px 0;
    }
`
export const Provider = styled.img`
    height: 32px;
    margin-left: 20px;

    @media (max-width: 768px){
        height: 24px;
    }
`

export const Name = styled.div`
    flex: 1.5;
    text-align: left;
    padding: 5px 0px 5px 20px;
`


export const FirstColumn = styled.div`
    flex: 1;
    display: flex;
`