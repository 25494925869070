import React, { useState, useEffect } from 'react'
import styles              from '../ModifySelection/ModifySelectedRooms.module.css'
import Modal               from 'react-responsive-modal'
import {withTranslation}    from 'react-i18next'
import styled from 'styled-components'

class RoomNotesModal extends React.Component{
    render(){
        const {
            t,
            notes, 
            isModalOpen, 
            onClose,
        } = this.props

        /*var finalNotes = "<ul><li>" + notes.replace(/(<br\/>)|(<br \/>)/g, "<br/></li><li>");
        finalNotes = finalNotes.replace(/\/\/($)/g, "$1");
        finalNotes = finalNotes.replace(/(\/\/)|(\. \/\/)/g, "<br/></li><li>");
        finalNotes = finalNotes.replace(/(\. )/g, ". <br/></li><li>");
        finalNotes = finalNotes + "</li></ul>";*/

        var auxNotes = notes.replace(/(<br\/>)|(<br \/>)/g, "¡");
        auxNotes = auxNotes.replace(/(&nbsp;)/g, "");
        auxNotes = auxNotes.replace(/\/\/($)/g, "$1¡");
        auxNotes = auxNotes.replace(/(\/\/)|(\. \/\/)/g, "¡");
        auxNotes = auxNotes.replace(/(\. )/g, "¡");

        var notesArray = auxNotes.split("¡");

        var finalNotes = "<ul><li>";
        var i;
        for (i = 0; i < notesArray.length; i++) {
            if(i == 0 || finalNotes == "<ul><li>"){
                if(notesArray[i].length > 0 && notesArray[i].replace(/\s/g, '').length > 0){
                    finalNotes += notesArray[i];
                    finalNotes += "</li>";
                }
                continue;
            }
            if(i == notesArray.length){
                if(notesArray[i].length > 0 && notesArray[i].replace(/\s/g, '').length > 0){
                    finalNotes += notesArray[i];
                }
                finalNotes += "</li></ul>";
            }
            else{
                if(notesArray[i].length > 0 && notesArray[i].replace(/\s/g, '').length > 0){
                    finalNotes += "<li>" + notesArray[i] + "</li>";
                }
            }
        }
        
        if(finalNotes == "<ul><li>"){
            finalNotes = "";
        }

        var InfoHeader = styled.div`
            font-size: 2em;
            font-weight: bold;
            width: 97%;
            background: #b71c1c;
            color: white;
            padding: 0 20px;
        `

        var InfoBody = styled.div`
            .innerInfo ul {
                list-style: none; /* Remove list bullets */
                padding: 0;
                margin: 0;
            }
            
            .innerInfo ul li {
                padding-left: 16px;
            }
            
            .innerInfo ul li:before {
                content: "🗹"; /* Insert content that looks like bullets */
                padding-right: 8px;
                margin-left: -24px;
            }

            .innerInfo ul li{
                margin-bottom: 15px;
            }
        `
                                                                            
        return (
            <Modal open={isModalOpen} onClose={() => onClose(null)} classNames={{modal: styles.root, closeButton: styles.closeButton}}>
                <InfoHeader>{t("RoomNotesModalInfo")}</InfoHeader><hr></hr>
                <InfoBody><div class="innerInfo" dangerouslySetInnerHTML={{ __html: finalNotes }} /></InfoBody>              
            </Modal>
        )
    }
}

export default withTranslation()(RoomNotesModal)