import React, { useState }          from 'react'
import queryString                  from 'query-string'
import {QueryRenderer, graphql} 	from 'react-relay'
import env                          from './../../Environment'
import TempHotelResult              from  './TempHotelResults'
import TempHotelByCodesResult       from  './TempHotelByCodesResults'
import HotelIdsByFilter             from  './HotelIdsByFilter'
import SearchResult                 from './../SearchResults/SearchResults'
import SearchResultShowSameHotels   from './../SearchResults/SearchResultsShowSameHotels'
import {getLanguageFromUrl}         from './../../utility/LanguageHelper'
import TempHotelByCodesResults      from './TempHotelByCodesResults'

// RANKING, PRICE, RATING, CATEGORY_ASC, CATEGORY_DESC

const OrderStrToNum = str =>
      str === "Price" ? 1
      : str === "Rating" ? 2
      : str === "Category_Asc" ? 3
      : str === "Category_Desc" ? 4
      : 0

const DistributionsObjToVar = distributions => 
      distributions.map(distribution => ({
                              adults:       distribution.adults,
                              childrenAges: distribution.childrenAges,
                        })) 


const SearchingWrapperQuery = graphql`
    query SearchingWrapperQuery(
        $zoneCode:       String, 
        $hotelId:        String, 
        $language:       String!,
        $startDate:      String!,
        $endDate:        String!,
        $canaryResident: Boolean!,
        $pageSize:       Int!,
        $pageNum:        Int!,
        $order:          Int!,
        $roomsDistribution: [InputDistributionModelType]!,
        $filter:            InputAvailabilityFilterType,
        $channelCode:       String!,
        $channelKey:        String!,
    ){     
        availability(
                    zoneCode:           $zoneCode, 
                    hotelId:            $hotelId, 
                    language:           $language,
                    startDate:          $startDate,
                    endDate:            $endDate,
                    canaryResident:     $canaryResident,
                    pageSize:           $pageSize,
                    pageNum:            $pageNum,
                    order:              $order,
                    roomsDistribution:  $roomsDistribution,
                    filter:             $filter,
                    channelCode :       $channelCode,
                    channelKey:         $channelKey
        ){                     
            ...SearchResults_availability                        
        }
    }  
`

let hotelsIds = null;

function fetchQuery(operation, vars)
{
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function(){
        if(this.readyState == 4 && this.status == 200){
            hotelsIds = JSON.parse(this.response).data.hotelIdsByFilter;
        }
    };
    //The third argument "false" tells javascript to not make the call asynch, so it waits til the response comes
    //FINALLY
    xhttp.open("POST", env.__RELAY_API_ENDPOINT__, false); 
    xhttp.setRequestHeader("Content-type", "application/json");
    xhttp.send(JSON.stringify({ 
        query: operation, 
        variables: vars
    }));
    /*return fetch(env.__RELAY_API_ENDPOINT__, {
        method: 'POST',
        headers: {
        'content-type': 'application/json'
        },
        body:   JSON.stringify({ 
                    query: operation, 
                    variables: vars
                })
    })/*.then(response => { 
        return response; 
    
    })*/
}

async function getHotelIdsByFilter(fil, lang){
    let queryAux = `query HotelIdsByFilterQuery($filter: String!, $language: String!){ hotelIdsByFilter(filter: $filter, language: $language) }`
    fetchQuery(queryAux, {filter: JSON.stringify(fil), language: lang})
    /*let result = await fetchQuery(queryAux, {filter: JSON.stringify(fil), language: lang})
    if(result){
        let hotelsIdsAux = await result.json()
        if(hotelsIdsAux){
            hotelsIds = hotelsIdsAux.data.hotelIdsByFilter
            finished = 1
        }
    }*/
}

export default ({location, backTosearchUrl, availability, history, channelCode, channelKey}) => {

    const [searchParams, setSearchParams]             = useState(null)
    const [queryVariables, setQueryVariables]         = useState(null)
    const [visibleHotels, setVisibleHotels]           = useState(null)
    const [visibleFacilities, setVvisibleFacilities]  = useState(null)
    
 
    const setQueryVariablesFromUrl = () => {
        // se convertien los parametros de la url en parametri por la query GraphQL
       if(!queryString.parse(location.search).params) return

        const params       = queryString.parse(location.search).params
        const searchParams = JSON.parse(params)

        const qv = {
            zoneCode:          searchParams.zoneId,
            hotelId:           searchParams.hotelId,
            language:          getLanguageFromUrl(),
            startDate:         searchParams.initDate,
            endDate:           searchParams.endDate,
            canaryResident:    searchParams.canaryResident,
            pageSize:          searchParams.pageSize,
            pageNum:           searchParams.pageNum,
            order:             OrderStrToNum(searchParams.order),
            roomsDistribution: DistributionsObjToVar(searchParams.distributions),
            filter:            searchParams.filter,
            subfilter:         searchParams.subfilter,
            channelCode:       channelCode,
            channelKey:        channelKey
        }
        setSearchParams(searchParams)  
        setQueryVariables(qv)
    }

    const reloadForFilterModified = (newFilterParams, visibleHotels, visibleFacilities, nextPageNumber, token, newOrder) => {
      const newZoneCode = newFilterParams && newFilterParams.zoneCode ? newFilterParams.zoneCode : queryVariables.zoneCode

      const newQv = Object.assign({}, queryVariables)
      newQv.pageNum = 1 // reset to the first page

      if(newFilterParams){
        newQv.zoneCode = newZoneCode
        newQv.filter   = newFilterParams
      }
      if(newOrder){
          newQv.order = OrderStrToNum(newOrder);
      }
      
      if(nextPageNumber) newQv.pageNum = nextPageNumber
      if(token)          newQv.token   = token

      setQueryVariables(newQv)
      setVvisibleFacilities(visibleFacilities)
      setVisibleHotels(visibleHotels)
    }

    if(queryVariables === null) setQueryVariablesFromUrl()

    if(searchParams != null){
        if(searchParams.subfilter != null){
            getHotelIdsByFilter(searchParams.subfilter, searchParams.language)
        }
    }


    // en base al estado se muestra:
    // - un mensaje de error
    // - el listado hoteles de la zone (en la espera que cargan los resultados)
    // - los mismos hoteles de una busqueda anterior mientras se estàn cargando otros resultados
    // - los resultaos
    
    //This here is to select which preview to use depending on what information we have
    let previewRendering = null;
    if(searchParams != null){
        if((searchParams.zoneCode == null || searchParams.zoneCode === "") &&
            (searchParams.hotelId == null || searchParams.hotelId === "") &&
            hotelsIds != null){
                searchParams.hotelId = hotelsIds
                queryVariables.hotelId = hotelsIds
                searchParams.subfilter = []
                queryVariables.subfilter = []
                hotelsIds = null;
                setSearchParams(searchParams)
                setQueryVariables(queryVariables)
        }
        if(searchParams.hotelId != null && searchParams.hotelId !== ""){
            previewRendering = <TempHotelByCodesResults  
                                    hotelId={searchParams.hotelId} 
                                    language={searchParams.language}
                                />;
        }
        else{
            if(searchParams.zoneId != null && searchParams.zoneId !== ""){
                previewRendering = <TempHotelResult  
                                        zoneId={searchParams.zoneId} 
                                        hotelId={searchParams.hotelId} 
                                        language={searchParams.language}
                                    />;
            }
        }
    }

    const render = ({error, props}) => 
            error   ? <div>{alert('error')}</div> 
        : !props &&  !visibleHotels ?  previewRendering //This is the preview list of hotels when searching
        : !props &&  visibleHotels  ?  <SearchResultShowSameHotels  
                                            hotels={visibleHotels} 
                                            facilitiesFilter={visibleFacilities} 
                                        />
        :                              <SearchResult 
                                            availability={props.availability} 
                                            language={searchParams.language}
                                            searchParams={searchParams} 
                                            history={history} 
                                            location={location}
                                            //searchParams={searchParams}
                                            reloadForFilterModified={reloadForFilterModified}
                                            channelCode={channelCode}
                                            channelKey={channelKey}
                                        /> 

    // Relay executa la query con variables y hace el render de los resultados
    return <QueryRenderer 
            environment={env.environment}
            query={SearchingWrapperQuery}
            variables={queryVariables}						        
            render={render}
            />
}


