export default {
    translation: {
        "send_by_email.message_sent_1":                     "Si è veriifcato un errore durante l'invio dell'email",
        "send_by_email.message_sent_0":                     "Email Inviate",
        "send_by_email.email-placeholder":                  "La tua email",
        "send_by_email.message-placeholder":                "scrivi un messaggio",
        "send_by_email.title":                              "Inviare per email",
        "send_by_email.button":                             "Invia",        

        "map.open-button":                                  "Vedi Mappa",
        
        
        "hotel_blocking_error":                             "Si è verificato un errore durante la prenotazione",
        "hotel_blocking_error_button":                      "Prenota un altro Hotel",

        "filters.filters":                                  "Applica Filtri",
        "resultDeal.totalPriceText":                        "Prezzo totale",

        "resultDeal.roomTypeTitle":                         "tipo di camera",
        "resultDeal.changeRoomTitle":                       "cambiare camera",
        "resultDeal.changeRoomsTitle":                      "cambiare camere",
        "resultDistribution.roomsQuantity":                 "(__num__ altre camere)",


        "hotel_blocking.message":                           "Stiamo creando la tua prenotazione...",

        "hotel_details_not_available":                      "Dettagli non disponibili",        
        "distributions.up_to_x_person":                     "Fino a __num__ persone",

        "hotel_details.description":                        "Descrizione",
        "hotel_details.map":                                "Mappa",
        "hotel_details.reviews":                            "Opinioni",
        "hotel_details.CheckIn":                            "Arrivo",
        "hotel_details.CheckOut":                           "Partenza",        
        "hotel_details.FacilitiesTitle":                    "Attrezzature",        
        "canariasComments.title":                           "Opinioni Canarias.com",        
        "canariasComments.rateCleaning":                    "Pulizia",
        "canariasComments.rateDreamQuality":                "Qualitá del sonno",
        "canariasComments.rateDrinkingSelection":           "Selezione bevande",
        "canariasComments.rateFood":                        "Cibo",
        "canariasComments.rateLocation":                    "Posizione",
        "canariasComments.rateQualityPriceRelation":        "Qualitá prezzo",
        "canariasComments.rateRooms":                       "Camere",
        "canariasComments.rateService":                     "Servizio",
        "canariasComments.resumeTitle":                     "Punteggio Canarias.com",
        "canariasComments.numberOfComments":                "Basato su __num__ opinioni",        
        "canariasComments.fromUser":                        "di",        
        "canariasComments.fromPlace":                       "da",    
        "canariasComments.trip_type_1":                     "Viaggio di lavoro",
        "canariasComments.trip_type_2":                     "Viaggio di coppia",
        "canariasComments.trip_type_3":                     "Viaggio in famiglia",
        "canariasComments.trip_type_4":                     "Viaggio con amici",
        "canariasComments.trip_type_5":                     "Viaggio da solo",

        "TripadvisorComments.title":                        "Opinioni TripAdvisor",        
        "TripadvisorComments.resumeTitle":                  "Punteggio TripAdvisor",                
        "TripadvisorComments.readMore":                     "Continua a leggere",        
        "TripadvisorComments.NumerOfComments":              "Basato su __num__ opinioni",        

        "TripadvisorComments.rate_value":                   "Qualitñà prezzo",        
        "TripadvisorComments.rate_cleanliness":             "Pulizia",        
        "TripadvisorComments.rate_sleep":                   "Qualitá del sonno",                
        "TripadvisorComments.rate_service":                 "Servizio",        
        "TripadvisorComments.rate_location":                "Posizione",        
        "TripadvisorComments.rate_room":                    "Camere",        
        
        "TripadvisorComments.TripType":                     "Tipo di viaggio",              
        "TripadvisorComments.NULL":                         "n.d.", 
        "TripadvisorComments.business":                     "D'affati", 
        "TripadvisorComments.couples":                      "In coppia", 
        "TripadvisorComments.family":                       "In famiglia", 
        "TripadvisorComments.friends":                      "Con amici", 
        "TripadvisorComments.solo":                         "Solo", 



        "RoomNotesModalInfo":                               "Info",
        "search_box.horizontal_label_place":                "Use our Hotel finder to find your perfect hotel",
        "search_box.label_place":                           "Scrivi un hotele, una cittá...",
        "search_box.place_placeholder":                     "Scrivi l'hotel o la destinazione",
        "search_box.label_pickup_date":                     "Data di arrivo",
        "search_box.label_dropoff_date":                    "Data di partenza",
        "search_box.label_room_and_people":                 "Nº stanze e persone",
        "search_box.search_button_text":                    "Cerca",
        "search_box.room_selection.confirm_button_text":    "Conferma",
        "search_box.room_selection.adult":                  "Adulto",        
        "search_box.room_selection.adults":                 "Adulti",
        "search_box.room_selection.child":                  "Bambino",        
        "search_box.room_selection.children":               "Bambini",
        "search_box.room_selection.childrenAges":           "Etá dei bambini",
        "search_box.room_selection.room":                   "Stanza",   
        "search_box.room_selection.rooms":                  "Stanze",          
        "search_box.place":                                 "Luogo",  
        "search_box.hotels":                                "Hotel",  
        "search_box.errors.errors_title":                   "Si sono verificati i seguenti errori: ", 
        "search_box.errors.select_place":                   "Seleziona un luogo e un hotel", 
        "search_box.errors.wrong_start_date":               "La data di arrivo non è valida", 
        "search_box.errors.wrong_end_date":                 "La data di partnza non è valida", 
        "search_box.errors.wrong_distributions":            "La distribuzione non è valida",
        "filters.change_search":                            "Modifica la Ricerca",                        
        "filters.main_title":                               "Filtra per nome",
        "filters.filter_by_category":                       "Filtra per Categoria",
        "filters.filter_by_deal":                           "Filtra per regime",
        "filters.filter_by_facilities":                     "Filtra per servizi",
        "filters.filter_by_price":                          "Filtra per prezzo",
        "filters.button_filter_text":                       "Filtra",
        "filters.button_remove_filter_text":                "Rimuovi Filtri",
        "deal.button_select_text":                          "Seleziona",
        "deal.button_modify_text":                          "Modifica",    
        "deal_selection.button_select_text":                "Seleziona",
        "category.0":		"Non Disponibile",
        "category.0L":		"Non Disponibile",
        "category.1":		"1",
        "category.10":		"Lusso",
        "category.11":		"Offerta",
        "category.13":		"13",
        "category.1L":		"1L",
        "category.2":		"2",
        "category.20":		"3L",
        "category.23":		"3",
        "category.26":		"26",
        "category.28":		"28",
        "category.29":		"29",
        "category.2L":		"2L",
        "category.3":		"3",
        "category.30":		"30",
        "category.31":		"31",
        "category.32":		"32",
        "category.34":		"34",
        "category.36":		"36",
        "category.3L":		"3L",
        "category.4":		"4",
        "category.40":		"40",
        "category.4L":		"4L",
        "category.5":		"5",
        "category.5L":		"5L",
        "category.8":		"Prima",
        "category.93":		"93",
        "category.A":		"A",
        "category.AB":		"Albergo",
        "category.AGRO":	"Agriturismo",
        "category.Ak":		"Appartamento turistico",
        "category.AP":		"Apartahotel",
        "category.AR":		"Apartamento Rurale",
        "category.AT":		"Appartamento",
        "category.BA":		"Balneario",
        "category.BB":		"BB",
        "category.BO":		"Boutique",
        "category.BU":		"Bungalow",
        "category.CA":		"Castello",
        "category.CAM":		"CAM",
        "category.CB":		"Capanna",
        "category.CC":		"Casa nella Grotta",
        "category.CE":		"Categoria Speciale",
        "category.CH":		"Guest House",
        "category.CM":		"Complesso rurale",
        "category.CO":		"Fattoria",
        "category.CP":		"Campeggio",
        "category.CR":		"Casa Rurale",
        "category.CS":		"Casa Rurale Con SPA",
        "category.CT":		"Centro Turismo Rurale",
        "category.Cz":		"Cz",
        "category.FO":		"Fonda",
        "category.H1":		"Ostello *",
        "category.H2":		"Ostello **",
        "category.HO":		"Ostello",
        "category.HP":		"Locanda",
        "category.HR":		"Hotel Rurale",
        "category.HS":		"Hotel Con SPA",
        "category.HT":		"Hotel",
        "category.IN":		"No Disponible",
        "category.LO":		"Casetta",
        "category.MH":		"Mini Hotel",
        "category.MO":		"Monastero",
        "category.MT":		"Motel",
        "category.PA":		"Ostello",
        "category.PE":		"Pensione",
        "category.PENDI":	"Pendi",
        "category.PO":		"Pousada",
        "category.PR":		"Posada Real",
        "category.RA":		"RA",
        "category.RE":		"Residenziale",
        "category.RS":		"Hotel Rurale Categoria Superiore",
        "category.RT":		"Resort",
        "category.ST":		"Standard",
        "category.TU":		"Turista",
        "category.TUS":		"Turista Superiore",
        "category.VI":		"Villa",
        "category.VV":		"Case Vacanze",

        "package.Add":      "Aggiungi una esperienza",
        "package.Selected": "SELEZIONATO",
        "package.Select":   "SELEZIONA",
        "package.Book":     "Prenota",

        "availability.NoResults": "Non abbiamo trovato disponibilitá, ritenta con un'altra ricerca.",

        "availability.orderBy.Recommended": "Raccomandato",
        "availability.orderBy.Price": "Prezzo",
        "availability.orderBy.Category": "Categoria",
    }
}
