import React, { Component }     from 'react'
import Popup                    from 'reactjs-popup'
import styled                   from 'styled-components'

//import { RowTitle, TriggerLabel, PopupOpenButton, ChildrenAges, InputField, RoomRow, RoomRowItem, ChildrenAgesContainer, 
//         ChildrenAgesTitle, PopupContent, ConfirmButton, } from './HotelSearchRoomsSelection.styled'
import {withTranslation}    from 'react-i18next'

const MAX_NUMBER_OF_ROOMS    = 3
const MAX_NUMBER_OF_ADULTS   = 8
const MAX_NUMBER_OF_CHILDREN = 4

var TriggerLabel = styled.div`
    text-align: left;    
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 3px;
`

var PopupOpenButton = styled.div`
    cursor: pointer;
    background-color: #fff;
    color: #333;
    text-align: left;

    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    border: 1px solid #ccc;
    font-size: 1em;
    padding: 7px 20px 7px 10px;
`

var ChildrenAges = styled.div`
    display: flex;
    justify-content: space-between;
`

var InputField = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
`

var RowTitle = styled.div`
    margin: 15px 0 0 0;
    text-align: center;
    border-top: 1px dotted #ccc;
    padding-top: 10px;
    letter-spacing: 1px;
`

var RoomRow = styled.div`
    display: flex;
    margin-top: 10px;
`

var RoomRowItem = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 5%;
`

var ChildrenAgesContainer = styled.div`
    margin: 10px 5%;
    display: flex;
    flex-direction: column;
`

var ChildrenAgesTitle = styled.div`
    margin: 5px 0;
`

var PopupContent = styled.div`
    width: 300px;
`

var ConfirmButton = styled.div`
    margin: 20px 15px 10px 15px;
    padding: 3px;
    font-size: 1.2em;
    cursor: pointer;
    color: #fff;
    text-align: center;
    background-color: rgb(5,183,79);
`


const SelectNumberOfRoom = styled.select`
     font-size: 1.1em;
     margin: 15px 15px 0px 15px;
     padding: 3px 20px 0px 20px;
`

const SelectPersonNumber = styled.select`
    padding: 3px 10px;
    letter-spacing: 1px;
`

const SelectChildrenAge = styled.select`
    flex: 1;
`

const defaultRoomDistribution = () => ({ adults: 2, children: 0, childrenAges: [] })

const buildSummaryText = (t, numAdults, numChildren, numRooms) => {
    const adultTxt    = `${numAdults} ${numAdults === 1 
                            ? t("search_box.room_selection.adult") 
                            : t("search_box.room_selection.adults")}`
    const childrenTxt = numChildren === 0 ? `` 
                            : numChildren === 1 ? `, 1 ${t("search_box.room_selection.child")}` 
                            :  `, ${numChildren} ${t("search_box.room_selection.children")}`
    const roomsTxt    = numRooms === 1 
                            ? ` - 1 ${t("search_box.room_selection.room")}` 
                            : ` - ${numRooms} ${t("search_box.room_selection.rooms")}`

    return adultTxt + childrenTxt + roomsTxt
}

export class HotelSearchRoomsSelection extends Component {
    constructor(props){
        super(props)

        this.state = {
            summaryText:        this.props.summaryText          || buildSummaryText(this.props.t, 2, 0, 1),
            roomsDistribution:  this.props.roomsDistribution    || [defaultRoomDistribution()],
            popupPosition:      "bottom center" //VIC123 changed it from right center to bottom center
        }

        this.popupTrigger                   = this.popupTrigger.bind(this)
        this.renderNumericOptions           = this.renderNumericOptions.bind(this)        
        this.handleOnChangeAdultsNumber     = this.handleOnChangeAdultsNumber.bind(this)
        this.handleOnChangeChildrenNumber   = this.handleOnChangeChildrenNumber.bind(this)
        this.renderRoomsDistribution        = this.renderRoomsDistribution.bind(this)   
        this.getRoomIndexFromSelect         = this.getRoomIndexFromSelect.bind(this)     
        this.updateAdultsChildrenQuantity   = this.updateAdultsChildrenQuantity.bind(this)
        this.updateSummaryText              = this.updateSummaryText.bind(this)       
        this.renderChildrenAges             = this.renderChildrenAges.bind(this)     
        this.handleOnChangeChildrenAge      = this.handleOnChangeChildrenAge.bind(this)        
        
        if (props.getRoomsDistributions)
            props.getRoomsDistributions(this.getRoomsDistributions.bind(this));

    }


    getRoomsDistributions = () => this.state.roomsDistribution

    handleOnChangeRoomsNumber = event => {
        const numberOfRooms     = parseInt(event.target.value)
        const roomsDistribution = [...Array(numberOfRooms).keys()].map(i => this.state.roomsDistribution[i] || defaultRoomDistribution())

        this.setState({roomsDistribution}, this.updateSummaryText)
    }

    getRoomIndexFromSelect = event => event.target.attributes['data-room-index'].value

    // cuando en otras funciones la modifica del estado ha terminado, se executa esta funciòn que actualiza el resumen 
    updateSummaryText = () => {
        const numberOfAdults    = this.state.roomsDistribution.reduce((tot, room) => tot + parseInt(room.adults), 0)
        const numberOfChildren  = this.state.roomsDistribution.reduce((tot, room) => tot + parseInt(room.children), 0)
        const summaryText       = buildSummaryText(this.props.t, numberOfAdults, numberOfChildren, this.state.roomsDistribution.length)
        
        this.setState({summaryText})
    }

    updateAdultsChildrenQuantity = (roomIndex, adults, children) => {
        const roomsDistribution = this.state.roomsDistribution.slice(0)
        const distribution      = roomsDistribution[roomIndex]
        const childrenAges      = children <= distribution.children 
                                    ? distribution.childrenAges.slice(0, children)
                                    : distribution.childrenAges.concat([...Array(children - distribution.children)].map(i => 0))

        roomsDistribution[roomIndex] = { 
            adults   :    adults   !== null ? adults   : distribution.adults,
            children :    children !== null ? children : distribution.children,
            childrenAges: childrenAges
        }
        this.setState({roomsDistribution}, this.updateSummaryText)
    }

    handleOnChangeAdultsNumber = event => 
        this.updateAdultsChildrenQuantity(this.getRoomIndexFromSelect(event), 
                                          event.target.value, 
                                          null)
    
    handleOnChangeChildrenNumber = event => 
        this.updateAdultsChildrenQuantity(this.getRoomIndexFromSelect(event), 
                                          null,
                                          event.target.value)    

    handleOnChangeChildrenAge = event => {
        const roomsDistribution = this.state.roomsDistribution.slice(0)
        const roomIndex         = parseInt(event.target.attributes['data-room-index'].value)
        const childIndex        = parseInt(event.target.attributes['data-child-index'].value)
              
        roomsDistribution[roomIndex].childrenAges[childIndex] = parseInt(event.target.value)
        this.setState({roomsDistribution})

    }

    popupTrigger = () => <div>
                            <TriggerLabel>
                                {this.props.t("search_box.label_room_and_people")}                               
                            </TriggerLabel>
                            <PopupOpenButton>
                                {this.state.summaryText}
                            </PopupOpenButton>                            
                        </div>

    // crea un listado opciones por una select
    renderNumericOptions = (from, to, labelSingle, labelPlural) => {
        const options = []
        for(let i=from; i<=to; i++)
            options.push(<option value={i} key={i}>{i} {i === 1 ? this.props.t(labelSingle) : this.props.t(labelPlural)}</option>)

        return options
    }


    renderChildrenAges = (roomIndex, numOfChildren) => {
        const me = this
        return (
                <ChildrenAges>
                    {[...Array(numOfChildren).keys()].map(i => {
                        const name          = `room_${roomIndex}_child_${i}`
                        const defaultValue  = me.state.roomsDistribution[roomIndex].childrenAges[i] || 0
                        
                        return <SelectChildrenAge name={name} 
                                       defaultValue={defaultValue} 
                                       key={i} 
                                       onChange={this.handleOnChangeChildrenAge}
                                       data-room-index={roomIndex}
                                       data-child-index={i} >
                                    {me.renderNumericOptions(0, 17)}
                               </SelectChildrenAge>                
                    })}
                </ChildrenAges>
        )}

    renderRoomsDistribution = (room, index) => {
            const t = this.props.t
            const nameAdults   = `adults-${index}`
            const nameChildren = `children-${index}`

            return <InputField key={index}>
                        <RowTitle>{t("search_box.room_selection.room")} {index + 1}</RowTitle>
                        <RoomRow>
                            <RoomRowItem>
                                <SelectPersonNumber name={nameAdults} 
                                        id={nameAdults} 
                                        defaultValue={room.adults}
                                        data-room-index={index} 
                                        onChange={this.handleOnChangeAdultsNumber}>
                                    {this.renderNumericOptions(1, MAX_NUMBER_OF_ADULTS, "search_box.room_selection.adult", "search_box.room_selection.adults")}
                                </SelectPersonNumber>
                            </RoomRowItem>
                            <RoomRowItem>
                                <SelectPersonNumber name={nameChildren} 
                                        id={nameChildren} 
                                        defaultValue={room.children}
                                        data-room-index={index} 
                                        onChange={this.handleOnChangeChildrenNumber}>
                                    {this.renderNumericOptions(0, MAX_NUMBER_OF_CHILDREN, "search_box.room_selection.child", "search_box.room_selection.children")}
                                </SelectPersonNumber>
                            </RoomRowItem>
                        </RoomRow> 
                        { room.children > 0 && 
                          <ChildrenAgesContainer>
                              <ChildrenAgesTitle>{t("search_box.room_selection.childrenAges")}</ChildrenAgesTitle>
                              {this.renderChildrenAges(index, parseInt(room.children))}
                          </ChildrenAgesContainer>                                                               
                        }
                        
                    </InputField>
    }

    render() {
        const t = this.props.t

        var popupContentStyle = {
            width: "auto", 
            padding: 0, 
            boxShadow: "0px 3px 10px #888",
            borderRadius: "5px",
            overflow: "hidden"
        }

        if(this.props.horizontal == "true"){
            popupContentStyle = {
                width: "auto", 
                padding: 0, 
                boxShadow: "0px 3px 10px #888",
                borderRadius: "5px",
                overflow: "hidden",
                background: "#43aec0"
            }
            ConfirmButton = styled.div`
                margin: 20px 15px 10px 15px;
                padding: 3px;
                font-size: 1.2em;
                cursor: pointer;
                color: #fff;
                text-align: center;
                background-color: #002c47;
            `
            TriggerLabel = styled.div`
                text-align: left;    
                font-size: 1em;
                font-weight: bold;
                margin-bottom: 0px;
            `
            InputField = styled.div`
                text-align: left;
                display: flex;
                flex-direction: column;
            `
            PopupOpenButton = styled.div`
                cursor: pointer;
                background-color: #fff;
                color: #333;
                text-align: left;
                height: 36px;

                border-radius: 10px;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                border: 1px solid #ccc;
                font-size: 1em;
                padding: 7px 20px 7px 10px;
            `
        }

        return (
            <div className={this.props.className}>
                <Popup trigger={this.popupTrigger} position={this.state.popupPosition} contentStyle={popupContentStyle} arrow={false} offsetX={20}>
                    {close => (
                        <PopupContent>
                            <InputField>
                                <SelectNumberOfRoom 
                                    name="roomsNumber" 
                                    id="roomsNumber" 
                                    defaultValue={this.state.roomsDistribution.length}
                                    onChange={this.handleOnChangeRoomsNumber}
                                >
                                    {this.renderNumericOptions(1, MAX_NUMBER_OF_ROOMS, "search_box.room_selection.room", "search_box.room_selection.rooms")}
                                </SelectNumberOfRoom>
                            </InputField>

                            { this.state.roomsDistribution.map(this.renderRoomsDistribution)}

                            <ConfirmButton onClick={close}>{t("search_box.room_selection.confirm_button_text")}</ConfirmButton>
                        </PopupContent>
                    )}
                </Popup>                
            </div>        
        )
    }
}

export default withTranslation()(HotelSearchRoomsSelection)
