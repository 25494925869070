/**
 * @flow
 * @relayHash 45f2ddcc00a886e6bf27cf1c8b6fdf80
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InputHotelBlockRequestType = {|
  rooms: $ReadOnlyArray<?HotelBlockRoom>,
  packages?: ?$ReadOnlyArray<?HotelBlockPackage>,
  distributions: $ReadOnlyArray<?HotelBlockDistribution>,
|};
export type HotelBlockRoom = {|
  id: string,
  hotelId: string,
  initDate: string,
  endDate: string,
  code: string,
  dealCode: string,
  price: number,
  isPVP: boolean,
  directPayment: boolean,
  canaryResident: boolean,
  distributionId: string,
  roomAdditionalInfo: string,
|};
export type HotelBlockPackage = {|
  packageId?: ?string,
  distributionId?: ?string,
  initDate?: ?string,
  endDate?: ?string,
|};
export type HotelBlockDistribution = {|
  id?: ?number,
  adults?: ?number,
  childrenAges?: ?$ReadOnlyArray<?number>,
|};
export type HotelBlockQueryVariables = {|
  details: InputHotelBlockRequestType,
  channelCode: string,
  channelKey: string,
|};
export type HotelBlockQueryResponse = {|
  +hotelBlock: ?{|
    +hotels: ?$ReadOnlyArray<?{|
      +id: string,
      +sessionId: string,
      +roomsCancellationsCosts: ?$ReadOnlyArray<?{|
        +cancellationsCosts: ?$ReadOnlyArray<?{|
          +amount: any,
          +percent: any,
          +date: any,
        |}>,
        +roomId: string,
      |}>,
    |}>
  |}
|};
export type HotelBlockQuery = {|
  variables: HotelBlockQueryVariables,
  response: HotelBlockQueryResponse,
|};
*/


/*
query HotelBlockQuery(
  $details: InputHotelBlockRequestType!
  $channelCode: String!
  $channelKey: String!
) {
  hotelBlock(details: $details, channelCode: $channelCode, channelKey: $channelKey) {
    hotels {
      id
      sessionId
      roomsCancellationsCosts {
        cancellationsCosts {
          amount
          percent
          date
        }
        roomId
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "details",
    "type": "InputHotelBlockRequestType!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "channelCode",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "channelKey",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hotelBlock",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "channelCode",
        "variableName": "channelCode",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "channelKey",
        "variableName": "channelKey",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "details",
        "variableName": "details",
        "type": "InputHotelBlockRequestType!"
      }
    ],
    "concreteType": "HotelBlockResponse",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hotels",
        "storageKey": null,
        "args": null,
        "concreteType": "HotelBlocklResultHotel",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "sessionId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "roomsCancellationsCosts",
            "storageKey": null,
            "args": null,
            "concreteType": "HotelBlockingRoomCancellationCosts",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "cancellationsCosts",
                "storageKey": null,
                "args": null,
                "concreteType": "HotelBlockingCancellationCosts",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "amount",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "percent",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "date",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "roomId",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "HotelBlockQuery",
    "type": "CanariasQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "HotelBlockQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "HotelBlockQuery",
    "id": null,
    "text": "query HotelBlockQuery(\n  $details: InputHotelBlockRequestType!\n  $channelCode: String!\n  $channelKey: String!\n) {\n  hotelBlock(details: $details, channelCode: $channelCode, channelKey: $channelKey) {\n    hotels {\n      id\n      sessionId\n      roomsCancellationsCosts {\n        cancellationsCosts {\n          amount\n          percent\n          date\n        }\n        roomId\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fbbc385a2dd6d436232cf682006d071a';
module.exports = node;
