import styled from 'styled-components'

export const Deal = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 2px dotted #ddd;
    padding: 30px;
    
    &:first-child{
        border-top: none;
    }

    @media (max-width: 768px){
        padding: 30px 0px 0px 0px;
        margin-bottom: 30px;
    }
`

export const DealResume = styled.div`
    display: flex;
    height: 3em;
    background-color: #f2f2f2;
    border-radius: 5px;

    @media (max-height: 1378px){
        height: 5em;
        align-items: center;
    }
`

export const DealName = styled.div`
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 20px;

    @media (max-width: 1378px){
        width: 50%;
        justify-content: center;
        height: 2em;
    }

    @media (max-width: 768px){
        width: 100%;
        justify-content: center;
        padding-left: 0px;
        margin-bottom: 10px;
    }
`
export const DescriptionSpace = styled.div`
    flex: 1.5;
    display: flex;
    align-items: center;

    @media (max-width: 1378px) and (min-width: 768px){
        justify-content: flex-end;
        padding-right: 20px;
    }

    @media (max-width: 768px){
        justify-content: center;        
    }
`

export const DealPrice = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 1.4em;
    width: 150px;

    @media (max-width: 1378px){
        padding-top: 15px;
        padding-right: 45px;
        width: auto;
        justify-content: flex-end;
    }
`
export const DistributionsDetails = styled.div`
    display: flex;
`

export const Distributions = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const Button = styled.div`
    color: #fff;
    cursor: pointer;
    text-transform: uppercase; 
    padding: 5px;
    width: 200px; 
    text-align: center;
`

export const ButtonSelect = styled(Button)`
    background-color: #5cb85c;

`

export const ButtonModify = styled.div`
    padding: 5px 10px;
    font-size: 70%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    color: #fff;
    background-color: #dc0000;
    cursor: pointer;
    text-transform: uppercase;
    font-family: Arial;
    letter-spacing: 1px;
    margin-left: 40px;
    border-radius: 20px;

    @media (max-width: 1378px){
        margin-left: 0px;
    }
`
