import React, { Component } from 'react'
import Autocomplete         from 'react-autocomplete'
import styles               from './HotelSearchInputPlace.module.css'
import SearchUtility        from './../../utility/SearchUtility'
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome'

import {withTranslation} from 'react-i18next'

import { resultTypeZone,  resultTypeHotel} from "./../../utility/constants"


const seachUrlByTerm = term => `https://www.canarias.com/core/search-zone-and-hotel?take=10&term=${term}`

// este componente se connecta a la API de canarias.com para encortrar terminos  buscado
// le pasa el temino e recibe 2 tipos de resutlados: hoteles o destinos, que mostreran agrupados por tipo

class HotelSearchInputPlace extends Component {
  constructor(props){
    super(props)

    this.state = {
        term:        props.selectedItem.name  || '',
        lastTerm:    '',
        loading:     false,
        results:     props.results || [] 
    }

    this.requestTimer    = null
    this.doSearch        = this.doSearch.bind(this)
    this.isSearchedTerm  = this.isSearchedTerm.bind(this)
    this.handleOnSelect  = this.handleOnSelect.bind(this)
    this.handleOnChange  = this.handleOnChange.bind(this)
    this.renderItem      = this.renderItem.bind(this)
    this.renderMenu      = this.renderMenu.bind(this)
    this.receivedResults = this.receivedResults.bind(this)

  }


  isSearchedTerm = (url, term) => decodeURI(SearchUtility.getParamFromUrl('term', url)) === this.state.term

   doSearch = () => {
      const me = this
      const searchedTerm = this.state.term.trim()
      if(this.state.lastTerm && this.state.lastTerm.indexOf(searchedTerm) > -1){ 
        // si el termino es parte del anterion (se borrò in caracter) es inutil lanzar otra busqueda del termino
        this.setState({ loading: false})
        return 
      } else this.setState({ lastTerm: searchedTerm})
      
      fetch(seachUrlByTerm(searchedTerm))
        .then(response => me.isSearchedTerm(response.url, searchedTerm) ? response.json() : null )        
        .then(data     => me.receivedResults(data))        
        .catch(err     => console.log("TO DO - Error " + JSON.stringify(err)))
  }

  receivedResults = data => {
      if(data && data.length < 1) return

      // en los resultados hoteles y destino son mezclados, el buscador los muestras agrupados por tipo
      const zones  = data.filter(item => item.type === resultTypeZone)
      const hotels = data.filter(item => item.type === resultTypeHotel)

      let results = []
      if(zones.length > 0){
        results.push({header: this.props.t("search_box.place")})
        results = results.concat(zones)
      }
      if(hotels.length > 0){
        results.push({header: this.props.t("search_box.hotels")})
        results = results.concat(hotels)
      }

      data && this.setState({ results, loading: false})
  }

  handleOnSelect = (value, item) => {
    this.setState({ term: value })     
    this.props.onSelected(item)
  }

  handleOnChange = (event, value) => {
    // Al escribir un carater hay una espera de 1 segundo antes de iniciar la busqueda.
    // Si alguien escribe lentamente evitamos hacer busquedas cuando todavia està escribiendo 
    clearTimeout(this.requestTimer)
    this.requestTimer = setTimeout(this.doSearch, 1000)
    this.setState({term: value, loading: true})
  }

  renderItem = (item, isHighlighted) => 
    item ? 
    (item.header
        ? <div className={`${styles.item} ${styles.itemHeader}`}  key={item.header}>
            {item.header}
          </div>
        : <div className={`${styles.item} ${isHighlighted ? styles.itemHighlighted : ''}`} key={item.code}>
            {item.name}
          </div>
    ) : null
  

  renderMenu = (items, value) => 
      <div className={styles.menu}>
          {  value === ''       ? null
           : this.state.loading ? items
           : items.length === 0 ? null
           : items
          }
      </div>

        
  renderIcons = () => this.state.loading ? <FontAwesomeIcon icon="sync" className={styles.iconSync} /> : null

  render() {
    return (
      <div className={this.props.horizontal != "true" ? styles.lineWrapper : styles.lineWrapperHorizontal}>
        {this.props.showLabel && <div className={this.props.horizontal != "true" ? styles.label : styles.labelHorizontal}>{this.props.t("search_box.label_place")}</div>}        
        <div className={this.props.horizontal != "true" ? styles.inputContainer : styles.inputContainerHorizontal}>
          <Autocomplete value={this.state.term}
                        items={this.state.results}
                        getItemValue={item => item.name}
                        onSelect={this.handleOnSelect}
                        onChange={this.handleOnChange}
                        renderItem={this.renderItem}
                        renderMenu={this.renderMenu}
                        isItemSelectable={item => item && !(item.header)}
                        inputProps={{className: this.props.horizontal != "true" ? styles.input : styles.inputHorizontal, placeholder: this.props.placeholder, id: 'states-autocomplete', autoComplete: 'no'}}
                        wrapperProps={{className: this.props.horizontal != "true" ? styles.inputWrapper : styles.inputWrapperHorizontal}}
          />
         {this.renderIcons()}
        </div>
      </div>
    )
  }
}

export default withTranslation()(HotelSearchInputPlace)





