import React, { useState }      from 'react'
import {withTranslation}        from 'react-i18next'
import styled                   from 'styled-components'
import styles                   from './ResultDeal.module.css'
import {FontAwesomeIcon }       from "@fortawesome/react-fontawesome"
import { faInfoCircle }         from '@fortawesome/free-solid-svg-icons'
import responsiveHelper         from '../../utility/responsiveHelper'
import RoomNotesModal           from './RoomNotesModal'
import ReactTooltip             from 'react-tooltip'
import {
    Distribution, 
    Provider, 
    Name, 
    FirstColumn
} from './ResultDistribution.style'


const RoomQuantityMessage = styled.div`
    margin-left: 30px;
    font-style: italic;
    color: #555;
    
    @media (max-width: 1378px){
        display: none;
    }
`

const RoomName = styled.div`
    flex: 1.5;
    text-align: left;
    padding: 5px 0px 5px 20px;
    display: flex;
    font-weight:    ${props => props.withTooltip ? 'bold' :      'normal'};
    text-transform: ${props => props.withTooltip ? 'uppercase' : 'none'};
    font-size:      ${props => props.withTooltip ? '0.9em' :     '1em'};
    cursor:         ${props => props.withTooltip ? 'help' :      'normal'};
`

const RoomPrice = styled.div`
    width: 150px;
    padding: 5px 0px;
    display: flex;
    justify-content: center;

    @media (max-width: 768px){
        padding-right: 10px;
        width: auto;
    }
`

const ButtonModify = styled.div`
    padding: 5px 10px;
    font-size: 70%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    color: #000;
    cursor: pointer;
    text-transform: uppercase;
    font-family: Arial;
    letter-spacing: 1px;
    margin-left: 40px;
    border-radius: 20px;

    @media (max-width: 1378px){
        margin-left: 0px;
    }
`

function setIsModalSelectionOpen(test){

}

const FirstRowMobile = styled.div`
    display: flex;
`

const DirectPayment = styled.div`
    width: 200px;    
    text-align: center;
`

const DirectPaymentText = styled.div`
    background-color: #298ed7;
    color: #fff;
    display: inline-block;
    border-radius: 20px;
    padding: 1px 10px;
    margin: 0 auto;
    cursor: help;
    font-size: 0.9em;
`

const DirectPaymentTooltipText = styled.div`
    font-size: 12px;
    max-width: 300px;
    text-align: justify;
    padding: 10px 10px;
`
const ExtendedRoomName = styled.div`
    max-width: 250px;
    text-align: left;
    padding: 10px 10px;
    font-size: 16px;
`

/*var isModalInformationOpen = false;

function setIsModalInformationOpen(isOpen){
    isModalInformationOpen = isOpen;
}*/

const ResultDistribution = ({t, distribution, selectedRoom, className, showLoading, distributioName}) => {
    
    const [isModalInformationOpen, setIsModalInformationOpen] = useState(false)

    const room = distribution.rooms.find(room => room.id === selectedRoom.id)
    const imageProviderName = room.providerName.toLowerCase() !== 'Booking' ? 'Joinrates' : 'Booking';
    const providerImage = `/content/react/images/${imageProviderName}.png`
    const price         = (Math.round(room.price * 100) / 100).toFixed(2)


    const renderDistributionDesktop = () => 
        <Distribution className={className}>
            <FirstColumn>
                <Provider src={providerImage} />
                <Name>{distributioName}</Name>            
            </FirstColumn>

            <RoomName>
                {renderRoomName()}
                <RoomQuantityMessage>
                    {t("resultDistribution.roomsQuantity").replace("__num__", distribution.rooms.length)}
                </RoomQuantityMessage>
            </RoomName>
            <RoomPrice>
                {showLoading ? <FontAwesomeIcon icon="sync" className={styles.iconSync}/>
                             : `${price} €`
                }
            </RoomPrice>
            
             <DirectPayment>
             {room.directPayment && <>
                <DirectPaymentText data-tip="custom theme" data-for="direct-payment">Pago en hotel</DirectPaymentText>
                <ReactTooltip id="direct-payment" effect="solid" place="left" type="info">
                    <DirectPaymentTooltipText>                 
                        {t("direct_payment_Tooltip_text")}
                    </DirectPaymentTooltipText>
                </ReactTooltip>
             </>}
            </DirectPayment>
        </Distribution>
    //VIC123 this up here is the FirectPaymentTooltipText David told me to make smaller
    const renderDistributionTablet = () => 
        <Distribution className={className}>
            <FirstColumn>
                <Provider src={providerImage} />
                <Name>{distributioName}</Name>            
            </FirstColumn>

            <RoomName>
                {renderRoomName()}
                <RoomQuantityMessage>
                    {t("resultDistribution.roomsQuantity").replace("__num__", distribution.rooms.length)}
                </RoomQuantityMessage>
            </RoomName>
            <RoomPrice>
                {showLoading ? <FontAwesomeIcon icon="sync" className={styles.iconSync}/>
                            : `${price} €`
                }
            </RoomPrice>
        </Distribution>

    const renderDistributionMobile = () => 
        <Distribution className={className}>
            <FirstRowMobile>
                <Provider src={providerImage} />
                <Name>{distributioName}</Name>
                <RoomPrice>
                    {showLoading ? <FontAwesomeIcon icon="sync" className={styles.iconSync}/>
                                : `${price} €`
                    }
                </RoomPrice>           
            </FirstRowMobile>
            {renderRoomName()}
        </Distribution>

    const renderRoomName = () => 
        room.roomCount < 2 
                ?   <>
                        {/** VIC123 también puedo hacer la i aquí**/}
                        <RoomName>{room.name} <ButtonModify onClick={e => setIsModalInformationOpen(true)}><FontAwesomeIcon icon={faInfoCircle} className={styles.iconInfo} /></ButtonModify></RoomName> 
                        <RoomNotesModal 
                            t={t}
                            isModalOpen={isModalInformationOpen} 
                            onClose={closed => {setIsModalInformationOpen(false); return;}}
                            notes={room.roomAdditionalInfo} 
                        />
                    </>    
          : <>
                <RoomName withTooltip={true} data-tip="custom theme" data-for={room.name}>{room.roomCount} {t("canariasComments.rateRooms")}</RoomName> 
                <ReactTooltip id={room.name} effect="solid" place="left" type="info">
                    <ExtendedRoomName>                    
                        {room.name}
                    </ExtendedRoomName>
                </ReactTooltip>
          </>  
          

                

    return <>
        { responsiveHelper.isDesktop() && renderDistributionDesktop() }
        { responsiveHelper.isTablet()  && renderDistributionTablet()  }
        { responsiveHelper.isMobile()  && renderDistributionMobile()  }
    </>
}


export default withTranslation()(ResultDistribution)