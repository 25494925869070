export default {
    translation: {
        "send_by_email.message_sent_1":                     "Error al enviar el email",
        "send_by_email.message_sent_0":                     "Email Enviada",
        "send_by_email.email-placeholder":                  "Tu email",
        "send_by_email.message-placeholder":                "escribe un mensaje",
        "send_by_email.title":                              "Enviar por email",
        "send_by_email.button":                             "Enviar",        

        "map.open-button":                                  "Ver Mapa",




        "direct_payment_Tooltip_text":                      "PAGO EN HOTEL: Se podrá comprobar la validez de la tarjeta con el cargo de 1€. Este importe será devuelto inmediatamente de forma automática una vez hecha la comprobación y realizada la reserva. Por otra parte el hotel se reserva el derecho a comprobar la validez de la tarjeta por el importe de los gastos de cancelación pudiendo cargar los mismos desde el momento en que aplican. En este caso serán descontados del total a pagar en el hotel. Si usted elige esta modalidad de pago en lugar de pagar en nuestra web a usted le cobrará el establecimiento (hotel, apartamento). En la mayor parte de los casos el cobro se hará a su llegada al establecimiento, si bien en algunos casos el hotel, en función de las características de la tarifa, cobrará por anticipado una parte o la totalidad.Puedes pagar en hotel",

        "hotel_blocking_error":                             "Error al crear la reserva con este hotel",
        "hotel_blocking_error_button":                      "Reserva Otro Hotel",

        "filters.filters":                                  "Ver Filtros",
        "resultDeal.totalPriceText":                        "Precio Total",

        "resultDeal.roomTypeTitle":                         "tipo de habitación",
        "resultDeal.changeRoomTitle":                       "cambiar habitacion",
        "resultDeal.changeRoomsTitle":                      "cambiar habitaciones",
        "resultDistribution.roomsQuantity":                 "(__num__ habitaciones más)",
        "hotel_blocking.message":                           "Estamos creando tu reserva...",

        "hotel_details_not_available":                      "Detalles no disponibles",
        "distributions.up_to_x_person":                     "Hasta __num__ personas",

        "hotel_details.description":                        "Descripción",
        "hotel_details.map":                                "Mapa",
        "hotel_details.reviews":                            "Opiniones",
        "hotel_details.CheckIn":                            "Entrada",
        "hotel_details.CheckOut":                           "Salida",        
        "hotel_details.FacilitiesTitle":                    "Equipamiento",        
        "canariasComments.title":                           "Opiniones Canarias.com",        
        "canariasComments.rateCleaning":                    "Limpieza",
        "canariasComments.rateDreamQuality":                "Calidad del sueño",
        "canariasComments.rateDrinkingSelection":           "Selección de bebidas",
        "canariasComments.rateFood":                        "Comida",
        "canariasComments.rateLocation":                    "Ubicación",
        "canariasComments.rateQualityPriceRelation":        "Relación calidad-precio",
        "canariasComments.rateRooms":                       "Habitaciones",
        "canariasComments.rateService":                     "Servicio",
        "canariasComments.resumeTitle":                     "Puntuaciones Canarias.com",
        "canariasComments.numberOfComments":                "Basado en __num__ comentarios",        
        "canariasComments.fromUser":                        "por",        
        "canariasComments.fromPlace":                       "de",    
        "canariasComments.trip_type_1":                     "Viaje de negocios",                                
        "canariasComments.trip_type_2":                     "Viaje en pareja",
        "canariasComments.trip_type_3":                     "Viaje en familia",
        "canariasComments.trip_type_4":                     "Viaje de amigos",
        "canariasComments.trip_type_5":                     "Viaje de solitario",

        "TripadvisorComments.title":                        "Opiniones TripAdvisor",        
        "TripadvisorComments.resumeTitle":                  "Puntuaciones TripAdvisor",                
        "TripadvisorComments.readMore":                     "Leer opinión completa",        
        "TripadvisorComments.NumerOfComments":              "Basado en __num__ comentarios",        

        "TripadvisorComments.rate_value":                   "Relación calidad-precio",        
        "TripadvisorComments.rate_cleanliness":             "Limpieza",        
        "TripadvisorComments.rate_sleep":                   "Calidad del sueño",                
        "TripadvisorComments.rate_service":                 "Servicio",        
        "TripadvisorComments.rate_location":                "Ubicación",        
        "TripadvisorComments.rate_room":                    "Habitaciones",        
        
        "TripadvisorComments.TripType":                     "Tipo de Viaje",              
        "TripadvisorComments.NULL":                         "n.d.", 
        "TripadvisorComments.business":                     "Por negocio", 
        "TripadvisorComments.couples":                      "En pareja", 
        "TripadvisorComments.family":                       "En familia", 
        "TripadvisorComments.friends":                      "Con amigos", 
        "TripadvisorComments.solo":                         "Solitario", 
             
        


        "RoomNotesModalInfo":                               "Info",
        "search_box.horizontal_label_place":                "Usa nuestro buscador para encontrar tu hotel ideal",
        "search_box.label_place":                           "Escribe un Hotel, Ciudad...",
        "search_box.place_placeholder":                     "Escribe tu hotel o destino",        
        "search_box.label_pickup_date":                     "Fecha de Entrada",
        "search_box.label_dropoff_date":                    "Fecha de Salida",
        "search_box.label_room_and_people":                 "Nº de habitaciones y personas",
        "search_box.search_button_text":                    "Buscar",
        "search_box.room_selection.confirm_button_text":    "Confirmar",
        "search_box.room_selection.adult":                  "Adulto",
        "search_box.room_selection.adults":                 "Adultos",        
        "search_box.room_selection.child":                  "Niño",
        "search_box.room_selection.children":               "Niños",        
        "search_box.room_selection.childrenAges":           "Edad de los niños",            
        "search_box.room_selection.room":                   "Habitación",   
        "search_box.room_selection.rooms":                  "Habitaciones",          
        "search_box.place":                                 "Destinos",  
        "search_box.hotels":                                "Hoteles",  
        "search_box.errors.errors_title":                   "Ocurrieron los siguientes errores: ", 
        "search_box.errors.select_place":                   "Seleccione un lugar o un hotel", 
        "search_box.errors.wrong_start_date":               "La fecha de entrada no es válida", 
        "search_box.errors.wrong_end_date":                 "La fecha de salida no es válida", 
        "search_box.errors.wrong_distributions":            "La distribution no es valida",             
        "filters.main_title":                               "Filtrar por nombre",
        "filters.filter_by_category":                       "Filtrar por categoría",
        "filters.filter_by_deal":                           "Filtrar por régimen",
        "filters.filter_by_facilities":                     "Filtrar por servicios",
        "filters.filter_by_price":                          "Filtrar por precio",
        "filters.button_filter_text":                       "Filtrar",
        "filters.button_remove_filter_text":                "Quitar Filtros",
        "filters.change_search":                            "Modificar Búsqueda",
        "deal.button_select_text":                          "Seleccionar",
        "deal.button_modify_text":                          "Modificar",    
        "deal_selection.button_select_text":                "Seleccionar",

        "category.0":		"No Disponible",
        "category.0L":		"No Disponible",
        "category.1":		"1",
        "category.10":		"Lujo",
        "category.11":		"Oferta",
        "category.13":		"13",
        "category.1L":		"1L",
        "category.2":		"2",
        "category.20":		"3L",
        "category.23":		"3",
        "category.26":		"26",
        "category.28":		"28",
        "category.29":		"29",
        "category.2L":		"2L",
        "category.3":		"3",
        "category.30":		"30",
        "category.31":		"31",
        "category.32":		"32",
        "category.34":		"34",
        "category.36":		"36",
        "category.3L":		"3L",
        "category.4":		"4",
        "category.40":		"40",
        "category.4L":		"4L",
        "category.5":		"5",
        "category.5L":		"5L",
        "category.8":		"Primera",
        "category.93":		"93",
        "category.A":		"A",
        "category.AB":		"Albergue",
        "category.AGRO":	"Agroturismo",
        "category.Ak":		"Apartamento Turístico",
        "category.AP":		"Aparthotel",
        "category.AR":		"Apartamento Rural",
        "category.AT":		"Apartamento",
        "category.BA":		"Balneario",
        "category.BB":		"BB",
        "category.BO":		"Boutique",
        "category.BU":		"Bungalow",
        "category.CA":		"Castillo",
        "category.CAM":		"CAM",
        "category.CB":		"Cabaña",
        "category.CC":		"Casas Cueva",
        "category.CE":		"Categoría Especial",
        "category.CH":		"Casa de Huéspedes",
        "category.CM":		"Complejo Rural",
        "category.CO":		"Cortijo",
        "category.CP":		"Camping",
        "category.CR":		"Casa Rural",
        "category.CS":		"Casa Rural Con SPA",
        "category.CT":		"Centro De Turismo Rural",
        "category.Cz":		"Cz",
        "category.FO":		"Fonda",
        "category.H1":		"Hostal *",
        "category.H2":		"Hostal **",
        "category.HO":		"Hostal",
        "category.HP":		"Hospedería",
        "category.HR":		"Hotel Rural",
        "category.HS":		"Hotel Con SPA",
        "category.HT":		"Hotel",
        "category.IN":		"No Disponible",
        "category.LO":		"Lodge",
        "category.MH":		"Mini Hotel",
        "category.MO":		"Monasterio",
        "category.MT":		"Motel",
        "category.PA":		"Parador",
        "category.PE":		"Pensión",
        "category.PENDI":	"Pendi",
        "category.PO":		"Pousada",
        "category.PR":		"Posada Real",
        "category.RA":		"RA",
        "category.RE":		"Residencial",
        "category.RS":		"Hotel Rural Categoría Superior",
        "category.RT":		"Resort",
        "category.ST":		"Standard",
        "category.TU":		"Turista",
        "category.TUS":		"Turista Superior",
        "category.VI":		"Villa",
        "category.VV":		"Viviendas Vacacionales",

        "package.Add":      "Añade una Experiencia",
        "package.Selected": "SELECCIONADO",
        "package.Select":   "SELECCIONAR",
        "package.Book":     "Reservar",

        "availability.NoResults": "No hemos encontrado disponibilidad. Intente nuevamente, modificando su búsqueda.",
        "availability.orderBy.Recommended": "Recomendados",
        "availability.orderBy.Price": "Precio",
        "availability.orderBy.Category": "Categoría",
    }
}