import React from 'react'
import styled from 'styled-components'
import {withTranslation} from 'react-i18next'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import "react-tabs/style/react-tabs.css"

import styles from './HotelDetailsComment.module.css'
import HotelCommentCanarias from './HotelCommentCanarias'
import HotelCommentTripAdvisor from './HotelCommentsTripAdvisor'


const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`

// las opiniones se reparten en dos tab, uno por los usuario de TripAdvisor y otro por los usuario de Canarias.com

const HotelComments = ({t, canarias, tripadvisor}) => {
    let final = null;

    if(tripadvisor != null && canarias == null){
        final = <Tabs className={styles.tabs} selectedTabClassName={styles.tabSelected}>
                    <TabList className={styles.tabList}>
                        <Tab className={styles.tab}>{t("TripadvisorComments.title")}</Tab>
                    </TabList>
                    <TabPanel className={styles.tabPanel}>
                        <HotelCommentTripAdvisor comments={tripadvisor} />
                    </TabPanel> 
                </Tabs>
    }
    else if(tripadvisor == null && canarias != null){
        final = <Tabs className={styles.tabs} selectedTabClassName={styles.tabSelected}>
                    <TabList className={styles.tabList}>            
                        <Tab className={styles.tab}>{t("canariasComments.title")}</Tab>
                    </TabList>            
                    <TabPanel className={styles.tabPanel}>
                        <HotelCommentCanarias comments={canarias} />
                    </TabPanel>        
                </Tabs>
    }
    else if(tripadvisor != null && canarias != null){
        final = <Tabs className={styles.tabs} selectedTabClassName={styles.tabSelected}>
                    <TabList className={styles.tabList}>
                        <Tab className={styles.tab}>{t("TripadvisorComments.title")}</Tab>            
                        <Tab className={styles.tab}>{t("canariasComments.title")}</Tab>
                    </TabList>
                    <TabPanel className={styles.tabPanel}>
                        <HotelCommentTripAdvisor comments={tripadvisor} />
                    </TabPanel>            
                    <TabPanel className={styles.tabPanel}>
                        <HotelCommentCanarias comments={canarias} />
                    </TabPanel>            
                </Tabs>
    }

    return (
        final
    )
}


export default withTranslation()(HotelComments)