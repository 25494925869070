import React from 'react'
import styles from './desktop-map-hotel-details.module.css'

import HotelDetailsDescription from '../HotelDetails/HotelDetailsDescription'
import HotelDeal from  '../SearchResults/ResultDeal'


export default ({hotel, onClose}) => {

    const handleOnSelectRooms = () => {
        // to do
    }

    const realDistributionNumber = 0

    return ( hotel ?
        <div className={styles.container}>
            <div 
                onClick={() => onClose(null)} 
                style={{
                    padding: "1em", 
                    cursor: "pointer",
                    color: "#fff",
                    backgroundColor: "red"
                }}
            >
                CLOSE HOTEL
            </div>
             <HotelDetailsDescription 
                name={hotel.name}
                address={hotel.address}
                description={hotel.description}
                phone={hotel.phone}
                ii={hotel.importantInformation}
                checkInOut={{
                    checkinFrom:    "09:01",
                    checkinTo:      "10:01",    
                    checkoutFrom:   "11:01",
                    checkoutTo  :   "12:01",
                }}
                facilities={hotel.facilities}
            />
            <div className={styles.deals}>
				{hotel.deals.map((deal, index) => (
					<HotelDeal
						deal={deal}
						key={index}
						onSelectRooms={handleOnSelectRooms}
						packages={hotel.packages}
						showLoading={false}
						realDistributionNumber={realDistributionNumber}
					/>
				))}
			</div>
        </div> : <div></div>
    )
}