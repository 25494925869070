import React                        from 'react'
import {QueryRenderer, graphql}     from 'react-relay'
import env                  from './../../Environment'
import { FontAwesomeIcon }          from '@fortawesome/react-fontawesome'

import AvailabilityResult           from './../Availability/AvailabilityResult'
import styles                       from './TempHotelResults.module.css'

class HotelIdsByFilter extends React.Component{
    render() {
        return (
            // se cargan los hoteles de la zona y se muestran durante la espera de los resultados
            <QueryRenderer 
                environment={env.environment}
                query={graphql`
                    query HotelIdsByFilterQuery($filter: String!, $language: String!){
                        hotelIdsByFilter(filter: $filter, language: $language)
                    }
                `}
                variables={{
                    filter:   this.props.filter,
                    language: this.props.language   || "es",
                }}
                
                // en base al estado se muestra el loading o los hoteles de la zona
                // en caso de error se deja el loading (solo es un componente para engañar la espera y cuando llegan los resultados se quita)
                render={({error, props}) => {
                    if (error) {
                      return "error"
                    }
                    return props
                  }}
            />
        )
    }
}


export default HotelIdsByFilter