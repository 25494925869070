/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ResultDeal_deal$ref: FragmentReference;
export type ResultDeal_deal = {|
  +id: string,
  +code: string,
  +name: string,
  +defaultSelectionTotalPrice: number,
  +distributions: ?$ReadOnlyArray<?{|
    +id: string,
    +distributionId: string,
    +name: string,
    +rooms: ?$ReadOnlyArray<?{|
      +id: string,
      +roomId: string,
      +code: string,
      +name: string,
      +price: any,
      +providerName: string,
      +bookingLink: ?string,
      +canaryResident: boolean,
      +numRoomsAvailable: number,
      +roomCount: number,
      +cancellationCosts: ?$ReadOnlyArray<?{|
        +cost: string,
        +date: string,
        +time: string,
      |}>,
      +rawCancellationCosts: ?$ReadOnlyArray<?{|
        +amount: any,
        +percent: any,
        +date: any,
      |}>,
      +directPayment: boolean,
      +roomAdditionalInfo: string,
      +maxOccupancy: number,
      +isPVP: boolean,
    |}>,
    +defaultSelectionRoom: ?{|
      +id: string,
      +roomId: string,
      +roomName: string,
      +roomPrice: number,
    |},
  |}>,
  +$refType: ResultDeal_deal$ref,
|};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "code",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "roomId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "date",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ResultDeal_deal",
  "type": "AvailabilityResultHotelDeal",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "defaultSelectionTotalPrice",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "distributions",
      "storageKey": null,
      "args": null,
      "concreteType": "AvailabilityResultHotelDistribution",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "distributionId",
          "args": null,
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "rooms",
          "storageKey": null,
          "args": null,
          "concreteType": "AvailabilityResultHotelRoom",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "numRoomsAvailable",
              "args": null,
              "storageKey": null
            },
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "price",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "providerName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "bookingLink",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "canaryResident",
              "args": null,
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "roomCount",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "cancellationCosts",
              "storageKey": null,
              "args": null,
              "concreteType": "AvailabilityResultHotelRoomCancellationCost",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "cost",
                  "args": null,
                  "storageKey": null
                },
                (v4/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "time",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "rawCancellationCosts",
              "storageKey": null,
              "args": null,
              "concreteType": "AvailabilityResultHotelRoomRawCancellationCost",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "amount",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "percent",
                  "args": null,
                  "storageKey": null
                },
                (v4/*: any*/)
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "directPayment",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "roomAdditionalInfo",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "maxOccupancy",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isPVP",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "defaultSelectionRoom",
          "storageKey": null,
          "args": null,
          "concreteType": "AvailabilityDefaultSelectionRoom",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "roomName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "roomPrice",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '63fb5948a11bb948b666982cb0f783b2';
module.exports = node;
