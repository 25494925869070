import React          from 'react'
import styled         from 'styled-components'
import HotelsFilters  from './../HotelsFilters/HotelsFilters'
import ResultHotel    from './ResultHotel'

import 'react-image-lightbox/style.css'

const Container = styled.div`
    display: flex;
`
const Filters = styled(HotelsFilters)`
    width: 300px;
`

const Hotels = styled.div`
    flex: 1;
`
const Hotel = styled(ResultHotel)`
    margin: 0px 20px 100px 20px;
    box-shadow: 0 0 20px #555;    
`

// este componente se utiliza cuando se cambian los parametros de la busqueda en la pagina de resultados
// asì durante la actualizacion de resultados  se muestran los mismos hoteles y no el listado generico de la zona
// es mas sencillo obtener este resultado con un componente independiente que modificar rl SearchResult para manejar ambos casos.

 export default ({hotels, facilitiesFilter}) => 
    <Container>
        <Filters disabled={true} facilitiesFilter={facilitiesFilter}/>
        <Hotels>
            {hotels && hotels.map((hotel, index) => 
                <Hotel key={index} hotel={hotel} showLoading={true} /> 
            )}
        </Hotels>
    </Container>
