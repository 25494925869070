export default {
    translation: {
        "send_by_email.message_sent_1":                     "Feil ved sending av e-post",
        "send_by_email.message_sent_0":                     "E-post sendt",
        "send_by_email.email-placeholder":                  "Din e-post",
        "send_by_email.message-placeholder":                "skriv en melding",
        "send_by_email.title":                              "Send via e-post",
        "send_by_email.button":                             "Sende",        

        "map.open-button":                                  "Se kart",
        
        
        "hotel_blocking_error":                             "Feil ved å opprette reservasjonen med dette hotellet",
        "hotel_blocking_error_button":                      "Bestill annet hotell",

        "filters.filters":                                  "Filter",
        "resultDeal.totalPriceText":                        "Total pris",

        "resultDeal.roomTypeTitle":                         "type rom",
        "resultDeal.changeRoomTitle":                       "endre rom",
        "resultDeal.changeRoomsTitle":                      "endre rom",
        "resultDistribution.roomsQuantity":                 "(__num__ flere rom)",


        "hotel_blocking.message":                           "Vi lager din reservasjon...",

        "hotel_details_not_available":                      "Detaljer ikke tilgjengelig",                
        "distributions.up_to_x_person":                     "Opptil __num__ personer",

        "hotel_details.description":                        "Beskrivelse",
        "hotel_details.map":                                "Kart",
        "hotel_details.reviews":                            "Anmeldelser",
        "hotel_details.CheckIn":                            "Ankomst",
        "hotel_details.CheckOut":                           "Permisjon",        
        "hotel_details.FacilitiesTitle":                    "Utstyr",        
        "canariasComments.title":                           "Vurderinger Canarias.com",        
        "canariasComments.rateCleaning":                    "Rengjøring",
        "canariasComments.rateDreamQuality":                "Søvnkvalitet",
        "canariasComments.rateDrinkingSelection":           "Utvalg av drikkevarer",
        "canariasComments.rateFood":                        "Mat",
        "canariasComments.rateLocation":                    "Plassering",
        "canariasComments.rateQualityPriceRelation":        "Valuta for pengene",
        "canariasComments.rateRooms":                       "Soverom",
        "canariasComments.rateService":                     "Service",
        "canariasComments.resumeTitle":                     "Scorer Canarias.com",
        "canariasComments.numberOfComments":                "Basert på __num__ vurderinger",        
        "canariasComments.fromUser":                        "av",        
        "canariasComments.fromPlace":                       "fra",    
        "canariasComments.trip_type_1":                     "Forretningsreise",                                
        "canariasComments.trip_type_2":                     "Reise i par",
        "canariasComments.trip_type_3":                     "Familie reise",
        "canariasComments.trip_type_4":                     "Reise av venner",
        "canariasComments.trip_type_5":                     "Turen av kabal",

        "TripadvisorComments.title":                        "Anmeldelser TripAdvisor",        
        "TripadvisorComments.resumeTitle":                  "Scores TripAdvisor",                
        "TripadvisorComments.readMore":                     "Les mer",        
        "TripadvisorComments.NumerOfComments":              "Basert på __num__ vurderinger",        

        "TripadvisorComments.rate_value":                   "Valuta for pengene",        
        "TripadvisorComments.rate_cleanliness":             "Rengjøring",        
        "TripadvisorComments.rate_sleep":                   "Søvnkvalitet",                
        "TripadvisorComments.rate_service":                 "Service",        
        "TripadvisorComments.rate_location":                "Plassering",        
        "TripadvisorComments.rate_room":                    "Soverom",        
        
        "TripadvisorComments.TripType":                     "Type tur",              
        "TripadvisorComments.NULL":                         "n.d.", 
        "TripadvisorComments.business":                     "For forretninger", 
        "TripadvisorComments.couples":                      "I par", 
        "TripadvisorComments.family":                       "I familien", 
        "TripadvisorComments.friends":                      "Med venner", 
        "TripadvisorComments.solo":                         "Ensom", 

        "RoomNotesModalInfo":                               "Info",
        "search_box.horizontal_label_place":                "Use our Hotel finder to find your perfect hotel",
        "search_box.label_place":                           "Skriv inn et hotell, en by...",
        "search_box.place_placeholder":                     "Skriv inn ditt hotell eller destinasjon",
        "search_box.label_pickup_date":                     "Påmeldingsfrist",
        "search_box.label_dropoff_date":                    "Avgangsdato",
        "search_box.label_room_and_people":                 "Antall rom og personer",
        "search_box.search_button_text":                    "Søke",
        "search_box.room_selection.confirm_button_text":    "Bekrefte",
        "search_box.room_selection.adult":                  "Voksen",        
        "search_box.room_selection.adults":                 "Voksne",
        "search_box.room_selection.child":                  "Barn",        
        "search_box.room_selection.children":               "Barn",
        "search_box.room_selection.childrenAges":           "Barn eldre",
        "search_box.room_selection.room":                   "Rom",   
        "search_box.room_selection.rooms":                  "Rom",          
        "search_box.place":                                 "Sted",  
        "search_box.hotels":                                "Hoteller",  
        "search_box.errors.errors_title":                   "Det er følgende feil:", 
        "search_box.errors.select_place":                   "Velg et sted på et hotell", 
        "search_box.errors.wrong_start_date":               "Pickup-datoen er ikke gyldig", 
        "search_box.errors.wrong_end_date":                 "Drop-out dato er ikke gyldig",         
        "search_box.errors.wrong_distributions":            "Fordelingen er ikke gyldig",
        "filters.change_search":                            "Endre søk",                                
        "filters.main_title":                               "Filtrer etter navn",
        "filters.filter_by_category":                       "Filtrer etter kategori",
        "filters.filter_by_deal":                           "Filtrer etter avtale",
        "filters.filter_by_facilities":                     "Filtrer etter fasiliteter",
        "filters.filter_by_price":                          "Filtrer etter pris",
        "filters.button_filter_text":                       "Filter",
        "filters.button_remove_filter_text":                "Filters verwijderen",
        "deal.button_select_text":                          "Å velge",
        "deal.button_modify_text":                          "Eendre",    
        "deal_selection.button_select_text":                "Å velge",
        "category.0":		"Ikke tilgjengelig",
        "category.0L":		"Ikke tilgjengelig",
        "category.1":		"1",
        "category.10":		"Luksus",
        "category.11":		"Tilbud",
        "category.13":		"13",
        "category.1L":		"1L",
        "category.2":		"2",
        "category.20":		"3L",
        "category.23":		"3",
        "category.26":		"26",
        "category.28":		"28",
        "category.29":		"29",
        "category.2L":		"2L",
        "category.3":		"3",
        "category.30":		"30",
        "category.31":		"31",
        "category.32":		"32",
        "category.34":		"34",
        "category.36":		"36",
        "category.3L":		"3L",
        "category.4":		"4",
        "category.40":		"40",
        "category.4L":		"4L",
        "category.5":		"5",
        "category.5L":		"5L",
        "category.8":		"Første",
        "category.93":		"93",
        "category.A":		"A",
        "category.AB":		"Vandrer",
        "category.AGRO":	"Agroturisme",
        "category.Ak":		"Toeristische appartement",
        "category.AP":		"Apart",
        "category.AR":		"Rural leilighet",
        "category.AT":		"Leilighet",
        "category.BA":		"Spa",
        "category.BB":		"BB",
        "category.BO":		"Boutique",
        "category.BU":		"Bungalow",
        "category.CA":		"Slott",
        "category.CAM":		"CAM",
        "category.CB":		"Hytta",
        "category.CC":		"Hulehus",
        "category.CE":		"Spesiell kategori",
        "category.CH":		"Guest House",
        "category.CM":		"Rural Complex",
        "category.CO":		"Våningshuset",
        "category.CP":		"Camping",
        "category.CR":		"Rural hus",
        "category.CS":		"Landlig hus med SPA",
        "category.CT":		"Rural Tourism Center",
        "category.Cz":		"Cz",
        "category.FO":		"Vertshuset",
        "category.H1":		"Hostel *",
        "category.H2":		"Hostel **",
        "category.HO":		"Hostel",
        "category.HP":		"Gjestgiveri",
        "category.HR":		"Rural Hotel",
        "category.HS":		"Hotell med SPA",
        "category.HT":		"Hotel",
        "category.IN":		"Ikke Tilgjengelig",
        "category.LO":		"Lodge",
        "category.MH":		"Mini Hotel",
        "category.MO":		"Kloster",
        "category.MT":		"Motell",
        "category.PA":		"Vandrer",
        "category.PE":		"Pension",
        "category.PENDI":	"Pensjon",
        "category.PO":		"Pousada",
        "category.PR":		"Posada Real",
        "category.RA":		"RA",
        "category.RE":		"Bolig",
        "category.RS":		"Superior kategori Rural Hotel",
        "category.RT":		"Resort",
        "category.ST":		"Standard",
        "category.TU":		"Turist",
        "category.TUS":		"Superior Turist",
        "category.VI":		"Villa",
        "category.VV":		"Feriehus",

        "package.Add":      "Voeg een ervaring toe",
        "package.Selected": "GESELECTEERD",
        "package.Select":   "SELECT",
        "package.Book":     "Reserve",

        "availability.NoResults": "We hebben geen beschikbaarheid gevonden. Verander uw zoekopdracht en probeer opnieuw.",

        "availability.orderBy.Recommended": "Aanbevolen",
        "availability.orderBy.Price": "Prijs",
        "availability.orderBy.Category": "Categorie",
    }
}
