import React from 'react'
import Modal from 'react-responsive-modal'
import { DateRangePicker } from 'react-dates'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'react-dates/lib/css/_custom.css'

import './HotelSearchBox.css'



import HotelSearchInputPlace from './HotelSearchInputPlace'
import HotelSearchRoomsSelection    from './HotelSearchRoomsSelection'
import { resultTypeZone,  resultTypeHotel} from "./../../utility/constants"
import {getLanguageFromUrl} from "./../../utility/LanguageHelper"

import styles from './HotelSearchBox.module.css'
import styled from 'styled-components'
import moment  from "moment"
import {withTranslation} from 'react-i18next'
import  {urls}  from './../../utility/constants'


const PageSize = 20
const language = getLanguageFromUrl()

var Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    margin-bottom: 15px;
`

var DateContainer = styled.div`
    display: inline-block;
    width: 25%;

    @media (max-width: 992px){
        width: 49%;
    }

    @media (max-width: 768px){
        width: 100%;
    }
`

var ButtonSearch = styled.div`
    background: linear-gradient(to bottom, #ff474a 0%,#b71c1c 100%);
    color: #fff;
    padding: 6px 0;
    margin-top: 20px;
    letter-spacing: 1px;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: bold;
    border-radius: 4px;
`

var TitleLabel = styled.div`
    font-weight: bold;
    margin-bottom: 0px;
    font-size: 1.2em;
    text-align: center;
    border-bottom: 1px solid;
`

var DatesLabel = styled.div`
    display: flex;
    text-align: left;
    margin: 10px 0 3px 0;
    font-weight: bold;
`
var DateLabel = styled.div`
    flex: 1;
    font-size: 1em;
    margin-bottom: 0px;
    text-align: left;
    :last-child {
        margin-left: 10%;
    }
`

var HotelSearchRoomsSelectionStyled = styled(HotelSearchRoomsSelection)`
    margin: 20px 0 0 0;
`

var ErrorTitle = styled.div`
    font-size: 1.2em;
    text-align: left;
    background-color: #f00;
    color: #fff;
    padding: 9px 80px 8px 20px;
    width: 100%;
    margin: 0;
    letter-spacing: 1px;
`

var ErrorItem = styled.p`
    padding: 0 20px;
`

class HotelSearchBox extends React.Component{
    constructor(props){
        super(props)
        
        this.state = {
                titleText:      props.titleText || null,        //el título en caso de no necesitar selected item
                selectedItem:   props.selectedItem  || null,    // el item puede ser un hotel o un destino
                distributions:  props.distributions || null,    // cuantas habitaciones, personas y niños estàn seleccionadas
                subfilter:      props.subfilter || null,
                horizontal:     props.horizontal,
                focusedInput:   props.focusedInput  || null,    // para mantener el estado "focus" del input
                startDate :     props.startDate     || new moment().add(7, 'd'), // por defecto se usa una fecha de entrada de hoy mas 7 dias
                endDate :       props.endDate       || new moment().add(14, 'd'), // por defecto usamos una duraciòn de 7 dia (hoy + 14)
                errors:         []
        }

        if(this.props.horizontal == "true"){
            Container = styled.div`
                display: block;
                background-color: #43aec0;
                color: #fff;
                margin-bottom: 15px;
            `
            ButtonSearch = styled.div`
                background: #002c47;
                color: #fff;
                padding: 6px 0;
                margin-top: 20px;
                letter-spacing: 1px;
                cursor: pointer;
                text-align: center;
                text-transform: uppercase;
                font-size: 1em;
                font-weight: bold;
                border-radius: 0px;
                width: 20%;
                min-width: 100px;
                display: block;
                margin-left: auto;
                margin-right: auto;
            `
            ErrorTitle = styled.div`
                font-size: 1.2em;
                text-align: left;
                background-color: #002c47;
                color: #fff;
                padding: 9px 80px 8px 20px;
                width: 100%;
                margin: 0;
                letter-spacing: 1px;
            `
            DatesLabel = styled.div`
                display: flex;
                text-align: left;
                font-weight: bold;
            `
            DateLabel = styled.div`
                display: inline-block;
                font-size: 1em;
                margin-bottom: 0px;
                text-align: left;
                :last-child {
                    margin-left: 17%;
                }
            `
            /*DateLabel = styled.div`
                display: inline-block;
                font-size: 1em;
                margin-bottom: 0px;
                margin-right: 90px;
                text-align: left;
                :last-child {
                    margin-left: 0%;
                }
            `*/
            HotelSearchRoomsSelectionStyled = styled(HotelSearchRoomsSelection)`
                width: 33%;
                display: inline-block;
                float: left;
                padding-right: 1%;

                @media (max-width: 992px){
                    width: 49%;
                    margin-bottom: 10px;
                }

                @media (max-width: 768px){
                    width: 100%;
                    margin-bottom: 10px;
                }
            `
            TitleLabel = styled.div`
                font-weight: bold;
                margin-bottom: 0px;
                font-size: 1.2em;
                width: 100%;
            `
        }
        else{
            DateContainer = styled.div`
                width: 100%;
            `
        }

        this.redirectToSearch    = this.redirectToSearch.bind(this)                
    }
    
    // convierte los parametros de la busqueda en parametros para la una url y para una post
    // executa la post para gurdar las datos de la busqueda en el servidor, el servidor harà mas cosas antes de contestar
    // asì se hace en parallelo la redirect a la url que muestra los resultados
    redirectToSearch = () => {
        const distributions  = this.getRoomsDistributions()                
        const newErrors      = []

        if((!this.state.selectedItem || !this.state.selectedItem.name) && !this.state.titleText)
            newErrors.push("search_box.errors.select_place")

        const mTomorrow = moment().add(1, 'd') // VIC123Reservas el mismo día
        const mStartDate = moment(this.state.startDate)
        if(mStartDate.isValid() === false || mStartDate <= moment())
            newErrors.push("search_box.errors.wrong_start_date")

        const mEndDate = moment(this.state.endDate)
        if(mEndDate.isValid() === false || mEndDate < mStartDate)
            newErrors.push("search_box.errors.wrong_end_date")

        if(!distributions || distributions.length < 1)
            newErrors.push("search_box.errors.wrong_distributions")

        // si al array de errores no  està vacio, la funciòn no deja continuar
        if(newErrors.length > 0 ){
            this.setState({errors: newErrors})
            return
        }        

        // construye los datos para la POST que guarda los parametros de la busqueda
        const buildFormSearchData = (place, startDate, endDate, distributions) => {
            var zoneId = "";
            var zoneName = "";
            var hotelId = "";

            if ((typeof place.Type !== "undefined" && place.Type === resultTypeZone) || 
                (typeof place.type !== "undefined" && place.type === resultTypeZone)) {
                zoneId = (typeof place.Code !== "undefined" ? place.Code : (typeof place.code !== "undefined" ? place.code : ""));
                zoneName = (typeof place.Name !== "undefined" ? place.Name : (typeof place.name !== "undefined" ? place.name : ""));
            }
            if ((typeof place.Type !== "undefined" && place.Type === resultTypeHotel) || 
                (typeof place.type !== "undefined" && place.type === resultTypeHotel)) {
                hotelId = (typeof place.Code !== "undefined" ? place.Code : (typeof place.code !== "undefined" ? place.code : ""));
            }

            const data = new FormData()
            data.append('zoneId',            zoneId)  // si es un destino, la API se espera que zoneId tenga un valor
            data.append('zoneName',          zoneName)  
            data.append('hotelId',           hotelId) // si es un hotel, la API se espera que hotelId tenga un valor
            data.append('serviceCode',       "")
            data.append('initDate',          startDate.format("YYYY-MM-DD"))
            data.append('endDate',           endDate.format("YYYY-MM-DD"))
            data.append('jsonDistributions', JSON.stringify( distributions.map((item, index) => Object.assign({}, item, {id: index + 1}))))
            data.append('language',          this.props.language || language) 
            data.append('jsonFilters',       [])
            data.append('jsonSubFilters',    this.state.subfilter)
            data.append('horizontal',        this.state.horizontal)
            data.append('canaryResident',    false)  // TO DO: hay que añadirlo al frontend
            data.append('pageSize',          PageSize)
            data.append('pageNum',           1)
            data.append('order',             "Ranking")  

            return data
        }

        // contruye los datos para la redirect a la pagina de resultados
        const buildUrlSearchData = (place, startDate, endDate, distributions) => ({
                zoneId:         (typeof place.Type !== "undefined" && place.Type === resultTypeZone) || 
                                (typeof place.type !== "undefined" && place.type === resultTypeZone)  ? 
                                (typeof place.Code !== "undefined" ? place.Code : (typeof place.code !== "undefined" ? place.code : "")) : "", // como por la form anterior, si es un destino, la API se espera un zoneId
                zoneName:       (typeof place.Type !== "undefined" && place.Type === resultTypeZone) || 
                                (typeof place.type !== "undefined" && place.type === resultTypeZone)  ? 
                                (typeof place.Name !== "undefined" ? place.Name : (typeof place.name !== "undefined" ? place.name : "")) : '',
                hotelId:        (typeof place.Type !== "undefined" && place.Type === resultTypeHotel) || 
                                (typeof place.type !== "undefined" && place.type === resultTypeHotel) ? 
                                (typeof place.Code !== "undefined" ? place.Code : (typeof place.code !== "undefined" ? place.code : "")) : '', // como por la form anterior, si es un hotel, la API se espera un hotelId
                serviceCode:    "",                
                initDate:       startDate.format("YYYY-MM-DD"),
                endDate:        endDate.format("YYYY-MM-DD"),
                distributions:  distributions.map((item, index) => Object.assign({}, item, {id: index + 1})),  
                language:       this.props.language || language,
                filter:         {}   ,
                subfilter:         this.state.subfilter    ,
                horizontal:     this.state.horizontal,
                canaryResident: false, // TO DO: hay que añadirlo al frontend
                pageSize:       PageSize,
                pageNum:        1,
                order:          "Ranking"
        })


        const urlSearchData  = buildUrlSearchData(this.state.selectedItem, this.state.startDate, this.state.endDate, distributions)
        const formSearchData = buildFormSearchData(this.state.selectedItem, this.state.startDate, this.state.endDate, distributions)

        // post to save searchParams in session
        fetch(urls.saveSearchFilter,{
            mode: 'cors',
            method: 'POST',
            credentials: 'include',
            body:    formSearchData 
        }) // don't wait the response 
        
        //language === "es" ? "" :  VIC123 eliminé eso porque routing en net core es raro
        window.location.href = `${language === "es" ? "" : "/" + language}${urls.hotelResults}?params=${JSON.stringify(urlSearchData)}`
    }

    // en base al numero de habitaciones y personas seleccionadas se contruye el texto que resume ("4 adultos, 1 niño - 2 Habitaciones")
    buildDistributionSummaryText = () => {
        if(this.state.distributions === null) return ''

        const t = this.props.t

        let numAdults     = 0
        let numChildren   = 0
        let numRooms      = 0
        this.state
            .distributions
            .map(d => {
                numRooms++
                numAdults += parseInt(d.adults)
                numChildren += parseInt(d.children)
            })

        const adultTxt = `${numAdults} ${numAdults === 1 
                                ? t("search_box.room_selection.adult") 
                                : t("search_box.room_selection.adults")}`

        const childrenTxt = numChildren === 0 ? `` 
                                : numChildren === 1 ? `, 1 ${t("search_box.room_selection.child")}` 
                                :  `, ${numChildren} ${t("search_box.room_selection.children")}`
        const roomsTxt    = numRooms === 1 
                                ? ` - 1 ${t("search_box.room_selection.room")}` 
                                : ` - ${numRooms} ${t("search_box.room_selection.rooms")}`

        return adultTxt + childrenTxt + roomsTxt
    }

    

    render(){
        const t = this.props.t
        const numberOfMonth = window.innerWidth > 700 ? 2 : 1 // si la pantalla es demasiado pequeña mostramos solo un mese a lo vez
        
        let header, className;

        if(this.props.horizontal){
            className = <TitleLabel>{t("search_box.horizontal_label_place")}</TitleLabel>
        }

        if (!this.state.titleText){
            header = <HotelSearchInputPlace onSelected={selectedItem => this.setState({selectedItem})} 
                            selectedItem={this.state.selectedItem}
                            placeholder={t("search_box.label_place")}
                            showLabel={true}
                            horizontal={this.props.horizontal}
                        />;
        }
        else{
            className = <TitleLabel>{this.state.titleText}</TitleLabel>;
        }
        
        return (
            <>
                <Container className={this.props.className}>
                    {className}
                    {header}
                                        
                    <HotelSearchRoomsSelectionStyled 
                        roomsDistribution={this.state.distributions}
                        summaryText={this.buildDistributionSummaryText()}
                        getRoomsDistributions={getRoomsDistributions => {this.getRoomsDistributions = getRoomsDistributions}}
                        horizontal={this.props.horizontal} />
                    <DateContainer>
                        {this.props.showLabel && <div className={this.props.horizontal != "true" ? styles.label : styles.labelHorizontal}>{this.props.t("search_box.label_place")}</div>}
                        <DatesLabel>
                            <DateLabel>{t("search_box.label_pickup_date")}</DateLabel>
                            <DateLabel>{t("search_box.label_dropoff_date")}</DateLabel>
                        </DatesLabel>
                        <DateRangePicker
                            startDate={this.state.startDate}               
                            startDateId="start_date_id"                           
                            startDatePlaceholderText={t("search_box.label_pickup_date")}
                            numberOfMonths={numberOfMonth}
                            endDate={this.state.endDate}                   
                            endDateId="end_date_id"  
                            endDatePlaceholderText={t("search_box.label_dropoff_date")}          
                            focusedInput={this.state.focusedInput}
                            onDatesChange={({startDate, endDate}) => this.setState({startDate, endDate})
                                            }                             
                            onFocusChange={focusedInput => this.setState({ focusedInput })}   
                            verticalSpacing={10}                     
                        />
                    </DateContainer>

                    <ButtonSearch onClick={this.redirectToSearch}>{t("search_box.search_button_text")}</ButtonSearch>
                </Container>

                {/* si hay uno mas errores se muestra esta modal con los mensajes */ }
                <Modal 
                    open={this.state.errors.length > 0} 
                    onClose={() => this.setState({errors: []})} 
                    classNames={{ modal: styles.root, closeButton: styles.closeButton}}
                >
                    <ErrorTitle>{t("search_box.errors.errors_title")}</ErrorTitle>
                    {this.state.errors.map((err, index) => <ErrorItem key={index}>{this.props.t(err)}</ErrorItem>)}
                </Modal>
            </>
        )
    }

}

export default withTranslation()(HotelSearchBox)