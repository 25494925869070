import React, {useState}        from 'react'
import queryString              from 'query-string'
import {QueryRenderer, graphql} from 'react-relay'
import env                      from './../../Environment'
import styled                   from 'styled-components'
import styles                   from './HotelsFilters.module.css'
import { FontAwesomeIcon }      from '@fortawesome/react-fontawesome'
import InputRange               from 'react-input-range'
import {urls, resultTypeZone, resultTypeHotel}  from './../../utility/constants'
import HotelSearchBox           from './../HotelSearch/HotelSearchBox'
import {withTranslation}        from 'react-i18next'
import moment  from "moment"
import responsiveHelper        from '../../utility/responsiveHelper'

import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion'
import './custom-accordion.css';

import 'react-input-range/lib/css/index.css'

const FilterItem = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0.5em 0;
`
const FilterIcon = styled(FontAwesomeIcon)`
    color: #333;
    margin-right: 10px;
`
const FilterItemLabel = styled.div`
    align-items: center;
    text-align: left;
    flex: 1;
`

const InputHotelName = styled.input`
    font-size: 1.2em;
    margin: 5px 4%;
    width: 90%;
`

const CategoryStars = styled.div`
    flex: 1;
    opacity: ${props => props.selected ? 1 : 0.6};
    background-image: url('${props => props.stars !== "0" ? "/content/react/images/stars.png" : ""}' );
    width: 130px;
    height: 30px;
    background-repeat: no-repeat;
    background-position-y: ${props => props.stars === "1" ? 0 
                                    : props.stars === "2" ? -30 
                                    : props.stars === "3" ? -60 
                                    : props.stars === "4" ? -90 
                                    : props.stars === "5" ? -120 
                                    : props.stars === "6" ? -150 
                                    : 0}px;
`

const CategoryKeys = styled.div`
    flex: 1;
    opacity: ${props => props.selected ? 1 : 0.6};
    background-image: url('${props => props.keys !== "0" ? "/content/react/images/keys.png" : ""}' );
    width: 130px;
    height: 30px;
    background-repeat: no-repeat;
    background-position-y: ${props => props.keys === "1L" ? 0 
                                    : props.keys === "2L" ? -30 
                                    : props.keys === "3L" ? -60 
                                    : props.keys === "4L" ? -90 
                                    : props.keys === "5L" ? -120 
                                    : 0}px;
`

const CategoryText = styled.div`
    flex: 1;
    opacity: ${props => props.selected ? 1 : 0.6};
    width: 130px;
    height: 30px;
    display: flex;
    align-items: center;
`

const FilterPriceRange = styled.div`
    padding: 30px;
`

const FilterButton = styled.div`
    text-align: center;
    background-color: #5cb85c;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    padding: 0.5em;
    width: auto;
    margin: 0 20px;
`
const RemoveFilterButton = styled.div`
    text-align: center;
    background-color: transparent;
    text-transform: uppercase;
    color: #666;
    cursor: pointer;
    padding: 0.5em;
    width: auto;
    margin: 10px 20px;
`

const Counter = styled.div`
    background-color: #fffbe9;
    color: #333;
    width: 2em;
    padding: 3px 0;
    margin-right: 10px;
    text-align: center;
`

const query = graphql`
    query HotelsFiltersQuery($token: String!, $language: String!, $channelCode: String!, $channelKey: String!)
    {
        filters(token: $token, language: $language, channelCode: $channelCode, channelKey: $channelKey)
        {    
            errorMsg
            success
            filters{
                minPrice
                maxPrice
                categories{
                    code
                    count
                    group
                    name
                }
                deals{
                    code
                    count
                    group
                    name
                }    
                facilities{
                    code
                    count
                    group
                    name
                }
            }
        }
    }
`


const HotelFilter = props => {
    const {history, location, className, disabled, language, reloadForFilterModified, token, t, searchData, hotels, channelCode, channelKey, hideSearcher } = props
    
    const searchParams = JSON.parse(queryString.parse(window.location.search).params)
    const initialSelectedCategory = id =>   searchParams && 
                                            searchParams.filter && 
                                            searchParams.filter.categories && 
                                            searchParams.filter.categories.length > 0 
                                            ? searchParams.filter.categories.find(c => c === id) != null
                                            : false

    const initialSelectedDeal = code => searchParams && 
                                        searchParams.filter && 
                                        searchParams.filter.deals && 
                                        searchParams.filter.deals.length > 0 
                                        ? searchParams.filter.deals.find(c => c === code) != null
                                        : false

    const initialSelectedFacilities = fac => searchParams && 
                                             searchParams.filter && 
                                             searchParams.filter.facilities && 
                                             searchParams.filter.facilities.length > 0 
                                             ? searchParams.filter.facilities.find(f => f === `${fac.group}║${fac.code}`) != null
                                             : false

    const buildInitialMinMaxPrice = () => {
        const min  = (searchParams.filter.minPrice < 0 || searchParams.filter.minPrice > 1000000) ? 0       : searchParams.filter.minPrice
        const max  = (searchParams.filter.maxPrice < 0 || searchParams.filter.maxPrice > 1000000) ? 1000000 : searchParams.filter.maxPrice
        return ({min, max})
    }

    const [hotelName, setHotelName]                 = useState(searchParams && searchParams.filter && searchParams.filter.hotelName)
    const [minMaxPrice, setMinMaxPrice]             = useState(buildInitialMinMaxPrice())
    const [filterMinMaxPrice, setFilterMinMaxPrice] = useState(buildInitialMinMaxPrice())

    const [categories, setCategories] = useState(null)
    const [deals, setDeals]           = useState(null)
    const [facilities, setFacilities] = useState(null)

    const toggleSelected = (objArr, id) => 
        objArr.map(o => o.id !== id ? o : Object.assign({}, o, {selected: !o.selected}))


    const handleToggleCategory = id => setCategories(toggleSelected(categories, id))
    const handleToggleDeal = code => {
        const newDeals = deals.map(d => 
            (d.code === code)
                ? Object.assign({}, d, {selected: !d.selected})            
                : d
        )
        setDeals(newDeals)
    }

    const handleToggleFacility = fac => {
        const newfacilities = facilities.map(f => 
            (f.code === fac.code && f.group === fac.group)
                ? Object.assign({}, f, {selected: !f.selected})            
                : f
        )
        setFacilities(newfacilities)    
    }    

    const handleApplyFilter = () => {     
        const oldParams = JSON.parse(queryString.parse(window.location.search).params)
        const newParams = Object.assign({}, 
                                        oldParams, 
                                        {
                                            pageNum: 1,
                                            filter:{
                                                hotelName,
                                                categories:     categories.filter(cat => cat.selected).map(cat => '' + cat.id),
                                                deals:          deals.filter(deal => deal.selected).map(deal => '' + deal.code),
                                                facilities:     facilities.filter(fac => fac.selected).map(fac => `${fac.group}║${fac.code}`),
                                                minPrice:       minMaxPrice.min > filterMinMaxPrice.min ? minMaxPrice.min : null,
                                                maxPrice:       minMaxPrice.max < filterMinMaxPrice.max ? minMaxPrice.max : null
                                            }
                                        }) 

        const newUrl = `${urls.hotelResults}?params=${JSON.stringify(newParams)}`
        history.push(newUrl)
        reloadForFilterModified(newParams.filter) // push url is not syncro, I send params to be sure
    }

    const handleRemoveFilter = () => {     
        const oldParams = JSON.parse(queryString.parse(window.location.search).params)
        const newParams = Object.assign({}, 
                                        oldParams, 
                                        {
                                            pageNum: 1,
                                            filter:{}
                                        }) 

        const newUrl = `${urls.hotelResults}?params=${JSON.stringify(newParams)}`
        history.push(newUrl)
        reloadForFilterModified(newParams.filter) // push url is not syncro, I send params to be sure
    }

    const handleEnterOnHotelName = e => {
        if(e.key === "Enter"){
            handleApplyFilter()
            e.preventDefault()
        }
    }

    const classDisabled = disabled ? styles.disabled : ''


    const setFiltersData = data => {
        if(!data || !(data.filters)) return

        const filterValues = data.filters.filters

        const newFilterMinMaxPrice = {min: filterValues.minPrice, max: filterValues.maxPrice}

        const newCategoriesStars = []
        const newCategoriesKeys  = []
        const newCategoriesText  = []

        filterValues.categories
                    .map(c => {
                        if     (c.code.indexOf("L") === -1 && parseInt(c.code) > 0) newCategoriesStars.push(c)
                        else if(c.code.indexOf("L") !== -1)                         newCategoriesKeys.push(c)
                        else                                                        newCategoriesText.push(c)
                    })

        const newCategories = [...newCategoriesStars.sort((a, b) => parseInt(a.code) - parseInt(b.code)), 
                               ...newCategoriesKeys.sort((a, b) => parseInt(a.code.substring(0, a.code.length - 1 )) - parseInt(b.code.substring(0, b.code.length - 1 ))),
                               ...newCategoriesText.sort()]
                                    .map(c => ({
                                                label:      c.code, 
                                                id:         c.code, 
                                                count:      c.count, 
                                                selected:   initialSelectedCategory(c.code)
                                            }))

        const newDeals = filterValues.deals
                                    .map(d => ({
                                                    label:      d.name,          
                                                    code:       d.code, 
                                                    count:      d.count,
                                                    selected:   initialSelectedDeal(d.code)
                                              }))
                                    .sort((item1, item2) => item1.name - item2.name)

        const newFacilities = filterValues.facilities
                                        .map(f => ({
                                                        label: f.name,
                                                        group: f.group,
                                                        code: f.code,
                                                        count: f.count,
                                                        selected: initialSelectedFacilities(f)
                                                  }))
                                        .sort()
                                          
        setFilterMinMaxPrice(newFilterMinMaxPrice)
        setMinMaxPrice(getMinMaxPriceInput(newFilterMinMaxPrice))
        setCategories (newCategories)
        setDeals(newDeals)
        setFacilities(newFacilities)
        
    }

    const getMinMaxPriceInput = (filterMinMax) => {
        const min = searchParams.filter.minPrice > 0 ? Math.max(searchParams.filter.minPrice, filterMinMax.min) : filterMinMax.min
        const max = searchParams.filter.maxPrice > 0 ? Math.min(searchParams.filter.maxPrice, filterMinMax.max) : filterMinMax.max
        return {min, max}
    }

    const getSelectedItem = () => {
        const isZone = searchParams.zoneId && searchParams.zoneId.length > 0  
        const Code   =  isZone ? searchParams.zoneId : searchParams.hotelId
        const Name   = searchParams.zoneName
        const Type   = isZone ? resultTypeZone : resultTypeHotel

        return { Code, Name, Type }
    }

    const renderCategory = cat => {
        const isKey  =  cat.label.indexOf('L') > -1
        const isStar = !isKey && parseInt(cat.label) > 0
        const isText = !isKey && !isStar

        return isStar  ? <CategoryStars stars={cat.label} selected={cat.selected} />
              : isKey  ? <CategoryKeys keys={cat.label} selected={cat.selected} />
              : isText ? <CategoryText keys={cat.label} selected={cat.selected}>{t(`category.${cat.id}`)}</CategoryText>        
              : null
    }

    const renderPriceRange = data => {  
        if(!data.filters.filters) 
            return null

        const maxPrice = data.filters.filters.maxPrice < 0 || data.filters.filters.maxPrice > 1000000 ? 1000000 : data.filters.filters.maxPrice
        const minPrice = data.filters.filters.minPrice < 0 || data.filters.filters.minPrice > 1000000 ? 0       : data.filters.filters.minPrice

        const valueMinMax = {
            min: minMaxPrice.min < 0 || minMaxPrice.min > 1000000 ? 0       : minMaxPrice.min,
            max: minMaxPrice.max < 0 || minMaxPrice.max > 1000000 ? 1000000 : minMaxPrice.max
        }

        return (        
            <div className={styles.filterItem}>
                <div className={styles.filterItemHeader}>{t("filters.filter_by_price")}</div>
                <div className={styles.fitlerItemContent}>
                    <FilterPriceRange>
                        <InputRange
                            maxValue={maxPrice}
                            minValue={minPrice}
                            formatLabel={value => `${value} €`}
                            value={valueMinMax}
                            onChange={value => setMinMaxPrice(value)}
                        />                              
                    </FilterPriceRange>
                </div>
            </div>
        )
    }

    const renderFiltrosDefault = (data) => 
        <div className={styles.filters}>
            {minMaxPrice && categories && deals  && facilities && ( <>
                <div className={`${styles.filtersContent} ${classDisabled}`}>
                    <div className={styles.filterItem}>
                        <div className={styles.filterItemHeader}>{t("filters.main_title")}</div>
                        <div className={styles.fitlerItemContent}>                              
                            <InputHotelName type="text" value={hotelName} onChange={e =>setHotelName(e.target.value)} onKeyPress={handleEnterOnHotelName}/>
                        </div>
                    </div>

                    {categories.length > 0 &&
                        <div className={styles.filterItem}>
                            <div className={styles.filterItemHeader}>{t("filters.filter_by_category")}</div>
                            <div className={styles.fitlerItemContent}>
                                {categories.map(cat =>
                                    <FilterItem key={cat.id} onClick={e => handleToggleCategory(cat.id)}>
                                        <Counter>{cat.count}</Counter>                                        
                                        <FilterIcon icon={["far", cat.selected ? "check-square" : "square"] } /> 
                                        {renderCategory(cat)}                                                                                         
                                    </FilterItem>
                                )}
                            </div>
                        </div>
                    }

                    {deals.length > 0 &&
                        <div className={styles.filterItem}>
                            <div className={styles.filterItemHeader}>{t("filters.filter_by_deal")}</div>
                            <div className={styles.fitlerItemContent}>
                                {deals.map(deal => 
                                    <FilterItem key={deal.code} onClick={e => handleToggleDeal(deal.code)}>
                                        <Counter>{deal.count}</Counter>                                        
                                        <FilterIcon icon={["far", deal.selected ? "check-square" : "square"] } /> 
                                        <FilterItemLabel>{deal.label}</FilterItemLabel>                                            
                                    </FilterItem>
                                )}
                            </div>
                        </div>
                    }

                    {facilities.length > 0 && 
                        <div className={styles.filterItem}>
                            <div className={styles.filterItemHeader}>{t("filters.filter_by_facilities")}</div>
                            <div className={styles.fitlerItemContent}>
                                {facilities && facilities.map((facility, index) => 
                                    <FilterItem key={index} onClick={e => handleToggleFacility(facility)}>
                                        <Counter>{facility.count}</Counter>
                                        <FilterIcon icon={["far", facility.selected ? "check-square" : "square"] } /> 
                                        <FilterItemLabel>{facility.label}</FilterItemLabel>                                            
                                    </FilterItem>
                                )}
                            </div>
                        </div>
                    }

                    {renderPriceRange(data)}

                    <FilterButton onClick={handleApplyFilter}>{t("filters.button_filter_text")}</FilterButton>
                    <RemoveFilterButton onClick={handleRemoveFilter}>{t("filters.button_remove_filter_text")}</RemoveFilterButton>
                </div>
                <div className={`${styles.overlay} ${classDisabled}`}></div> 
            </>)}                   
        </div> 


    const renderFiltrosMobile = (data, searchExpanded) =>
        <Accordion allowZeroExpanded={true} preExpanded={[searchExpanded]}>
            <AccordionItem uuid={"1"}>
                <AccordionItemHeading>
                    <AccordionItemButton>{t("filters.filters")}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    {renderFiltrosDefault(data)}
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>    

    const render = (data) => {          
        if(!minMaxPrice || !categories || !deals || !facilities) setFiltersData(data)            
        
        const searchExpanded = responsiveHelper.isMobile() || responsiveHelper.isTablet()? "0" : "1"

        let filter = null;
        if((minMaxPrice || categories || deals || facilities) && (JSON.stringify(searchParams.filter) !== JSON.stringify({}) || hotels.length > 1))
            filter = responsiveHelper.isMobile() || responsiveHelper.isTablet() ? renderFiltrosMobile(data, searchExpanded) : renderFiltrosDefault(data, searchExpanded)

        return (
            <div className={className}>                                   
                {!hideSearcher &&
                  <Accordion allowZeroExpanded={true} preExpanded={[searchExpanded]}>
                      <AccordionItem uuid={"1"}>
                          <AccordionItemHeading>
                              <AccordionItemButton>{t("filters.change_search")}
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <HotelSearchBox 
                                  location={location} 
                                  history={history} 
                                  className={styles.searchBox} 
                                  selectedItem={getSelectedItem()}
                                  distributions={searchParams.distributions}
                                  focusedInput={searchParams.focusedInput}
                                  startDate={new moment(searchParams.initDate)}
                                  endDate={new moment(searchParams.endDate)}
                                  />
                          </AccordionItemPanel>
                      </AccordionItem>
                  </Accordion>                 
                }
                {filter}
            </div>
        )
    }

    return <QueryRenderer 
               environment={env.environment}
               query={query}
               variables={{ token, language, channelCode, channelKey }}
               render={({error, props}) => 
                            error || !props ? <div className={className}>                   
                                                    <div className={styles.filters} />
                                               </div>
                                            : render(props)                           
                      }
            />



}        

export default withTranslation()(HotelFilter)