import React                from 'react'
import queryString          from 'query-string'
import Modal                from 'react-responsive-modal'
import styled               from 'styled-components'
import styles               from './desktop-map.module.css'
import {withTranslation}    from 'react-i18next'
import MapContainer        from './desktop-map-container'
import DesktopMapFilters    from './desktop-map-filters'
import DesktopMapResults    from './desktop-map-results'
import MapHotelDetails      from './desktop-map-hotel-details'

const ButtonMap = styled.div`
    margin: 0 20px 20px 20px;
    background-color: #298ed7;
    color: #fff;
    padding: 10px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
`

class DesktopMap extends React.Component{
    constructor(){
        super()

        this.state = {
            showModalMap: false,
            showHotelDetails: false,
            hotels: [],
            selectedHotel: null
        }

        this.handleHotelsChange     = this.handleHotelsChange.bind(this)
        this.handleHotelSelected    = this.handleHotelSelected.bind(this)
        this.handleShowHotelDetails = this.handleShowHotelDetails.bind(this)        
    }

    handleHotelsChange(hotels){
        console.log("update hotels in desktop-map", hotels)
        this.setState({hotels})
    }

    handleHotelSelected(hotel){
        console.log("selected hotel ", hotel)
        this.setState({selectedHotel: hotel, showHotelDetails: hotel !== null })
    }

    handleShowHotelDetails(hotelName){
        console.log("marker clicked")
        const hotel = this.state.hotels.find(h => h.name === hotelName)
        this.handleHotelSelected(hotel)
    }

    render(){
        const {
            t, 
            initialLat, 
            initialLng,
            
        } = this.props

        const searchParams = JSON.parse(queryString.parse(window.location.search).params)

        return(
            <>
                <ButtonMap onClick={() => this.setState({showModalMap: true})}>{t("map.open-button")}</ButtonMap>
                {this.state.showModalMap &&
                <Modal
                    open={this.state.showModalMap}
                    onClose={() => this.setState({showModalMap: false})}                
                    classNames={{
                        modal: styles.root,
                        closeButton: styles.closeButton
                    }}
                >
                    <DesktopMapFilters 
                        hotels={this.state.hotels}
                        language={searchParams.language}
                        history={this.props.history}
                        location={this.props.location}
                        disabled={this.props.disable}
                        reloadForFilterModified={this.props.reloadForFilterModified}
                        token={this.props.token}
                        searchParams={searchParams}
                        channelCode={this.props.channelCode}
                        channelKey={this.props.channelKey}
                    />
                    <DesktopMapResults hotels={this.state.hotels} onHotelSelected={this.handleHotelSelected} selected={this.state.selectedHotel} /> 
                    {this.state.showHotelDetails 
                        ? <MapHotelDetails hotel={this.state.selectedHotel} onClose={this.handleHotelSelected} />
                        : <MapContainer initialLat={initialLat} initialLng={initialLng} onChangeHotels={this.handleHotelsChange} onShowHotelDetails={this.handleShowHotelDetails}/>
                    }
                    
                </Modal>}
            </>
        )
    }
}

export default withTranslation()(DesktopMap)