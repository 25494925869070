import styled from 'styled-components'

export const DirectPayment = styled.div`
    width: 200px;    
    text-align: center;
`

export const DirectPaymentText = styled.div`
    background-color: #298ed7;
    color: #fff;
    display: inline-block;
    border-radius: 20px;
    padding: 1px 10px;
    margin: 0 auto;
    cursor: help;
    font-size: 0.9em;
`

export const DirectPaymentTooltipText = styled.div`
    font-size: 18px;
    max-width: 300px; 
    text-align: justify;
    padding: 10px 10px; 
`

export const RoomsContainer = styled.div`
    overflow: auto;
`

export const RoomRow = styled.div`
    display: flex;
    margin: 10px 0;

    &:nth-child(even){
        background-color: #f5f5f5;
    }

    @media (max-width: 768px){
        flex-wrap: wrap;
        padding: 30px 10px;
    }
`

export const Provider = styled.img`
    height: 32px;
    margin-right: 20px;    
`

export const RoomName = styled.div`
    flex: 2;
    display: flex;
    justify-content: left;
    align-items: center;
    
    @media (max-width: 768px){
        flex: 75%;
    }
`
export const RoomPrice = styled.div`
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: center;   
    
    @media (max-width: 768px){
        flex: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2em;
        font-weight: bold;
    } 
`
export const RoomSelect = styled.div`
    width: 200px;
    cursor: pointer;
    background-color: #5cb85c;
    color: #fff;
    padding: 5px 10px;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 768px){
        flex: 45%;
    }
`