/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ResultHotel_hotel$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SearchResults_availability$ref: FragmentReference;
export type SearchResults_availability = {|
  +currentPage: number,
  +order: number,
  +pageSize: number,
  +totalResults: number,
  +token: string,
  +realDistributionNumber: number,
  +facilitiesFilter: ?$ReadOnlyArray<?{|
    +name: string,
    +code: number,
    +group: number,
  |}>,
  +hotels: ?$ReadOnlyArray<?{|
    +$fragmentRefs: ResultHotel_hotel$ref
  |}>,
  +$refType: SearchResults_availability$ref,
|};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "SearchResults_availability",
  "type": "AvailabilityResults",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "currentPage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "order",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "pageSize",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalResults",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "token",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "realDistributionNumber",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "facilitiesFilter",
      "storageKey": null,
      "args": null,
      "concreteType": "FacilityFilter",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "code",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "group",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "hotels",
      "storageKey": null,
      "args": null,
      "concreteType": "AvalabilityResultsHotel",
      "plural": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ResultHotel_hotel",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '88c2869c9efccfcff49b66a52c7eaf07';
module.exports = node;
