import React, { useState, useEffect } from 'react'
import styles              from './ModifySelectedRooms.module.css'
import styled              from 'styled-components'
import Modal               from 'react-responsive-modal'
import ModifySelectionSteps from './ModifySelectionSteps'
import ReactTooltip         from 'react-tooltip'
import {withTranslation}    from 'react-i18next'

import {
    DirectPayment,
    DirectPaymentText,
    DirectPaymentTooltipText,
    RoomsContainer,
    RoomRow,
    Provider,
    RoomName,
    RoomPrice,
    RoomSelect
} from './ModifySelectedRooms.style'

class ModifySelectionRoom extends React.Component{
    constructor(props){
        super()

        this.state = {
            activeStepIndex: 1,
            roomsSelected: [],
            providerSelected: null,
            isDirectPayment: false
        }

        this.handleAllRoomSelected = this.handleAllRoomSelected.bind(this)
    }
   
    handleAllRoomSelected(selectedRooms){        
        this.setState({
            activeStepIndex: 1,
            roomsSelected: [],
            providerSelected: null,
            isDirectPayment: false
        }, () => {
            this.props.onClose(selectedRooms)
        })                
    }

    render(){
        const {
            t, 
            distributions, 
            isModalOpen, 
            onClose, 
            selectText, 
            roomsSelected, 
            newRoom, 
            providerSelected, 
            activeStepIndex
        } = this.props

        const roomsIdAndHowManySelected = {}

        if(roomsSelected)
            roomsSelected.map(r => {
                return roomsIdAndHowManySelected[r.room.id] 
                                        ? roomsIdAndHowManySelected[r.room.id] = roomsIdAndHowManySelected[r.room.id] + 1
                                        : roomsIdAndHowManySelected[r.room.id] = 1
            })
    


        const steps   = distributions.map(d => d.name)
        let stepRooms = distributions[this.state.activeStepIndex-1].rooms

        if(this.state.providerSelected !== null)
            stepRooms = stepRooms.filter(r => r.providerName === this.state.providerSelected)

        if(this.state.roomsSelected.length > 0){
            const selectedIsDirectPayment = this.state.roomsSelected[0].room.directPayment

            stepRooms = stepRooms.filter(r => r.directPayment  === selectedIsDirectPayment)
        }
            

           
        stepRooms = stepRooms.filter(r => !roomsIdAndHowManySelected[r.id] || roomsIdAndHowManySelected[r.id] < r.numRoomsAvailable ) // skip if rooms already selected is less or equal of room availability


        // const stepRooms = providerSelected == null 
        //                         ? distributions[this.state.activeStepIndex-1].rooms
        //                         : distributions[this.state.activeStepIndex-1].rooms
        //                                                           .filter(r => r.providerName === this.state.providerSelected)
        //                                                           .filter(r => !filterByDirectPayment ? true : r.directPayment  === filterDirectPaymentValue)
        //                                                           .filter(r => { 
        //                                                               return !roomsIdAndHowManySelected[r.id] ||
        //                                                                       roomsIdAndHowManySelected[r.id] < r.numRoomsAvailable }) // skip if rooms already selected is less or equal of room availability
                                                                        
 
        const me = this                                                                      
        return (
            <Modal open={isModalOpen} onClose={() => onClose(null)} classNames={{modal: styles.root, closeButton: styles.closeButton}}>
                <ModifySelectionSteps steps={steps} activeStepIndex={this.state.activeStepIndex}/>
                        
                <RoomsContainer>
                    {stepRooms.map((room, index) =>
                        <RoomRow key={index}>

                            <Provider src={`/content/react/images/${room.providerName.toLowerCase()}.png`} />
                            <RoomName>{room.name}</RoomName>
                            <RoomPrice>{room.price} €</RoomPrice>
                            {room.directPayment && 
                            <DirectPayment>
                                <DirectPaymentText data-tip="custom theme" data-for="direct-payment">Pago en hotel</DirectPaymentText>
                                <ReactTooltip id="direct-payment" effect="solid" place="left" type="info">
                                    <DirectPaymentTooltipText>                    
                                        {t("direct_payment_Tooltip_text")}
                                    </DirectPaymentTooltipText>
                                </ReactTooltip>            
                            </DirectPayment>}
                            <RoomSelect onClick={() => {
                                                        const nextStepIndex = me.state.activeStepIndex + 1    

                                                        const distributionId    = distributions[me.state.activeStepIndex-1].distributionId

                                                        const newRoomsSelected = me.state
                                                                                   .roomsSelected
                                                                                   .filter(r => r.distributionId !== distributionId)

                                                        newRoomsSelected.push({ distributionId, room})

                                                        const newState = {}
                                                        Object.assign(newState, me.state)

                                                        newState.roomsSelected      = newRoomsSelected
                                                        newState.providerSelected   = room.providerName
                                                        newState.isDirectPayment    = room.directPayment
                                                        
                                                        if(nextStepIndex > distributions.length) {   
                                                            me.handleAllRoomSelected(newRoomsSelected)  // onClose(newRoomsSelected)
                                                            newState.activeStepIndex  = 1      
                                                            newState.providerSelected = null
                                                        } else{
                                                            newState.activeStepIndex  = nextStepIndex    
                                                            me.setState(newState)
                                                        } 

                                                        

                                            }}>{selectText}</RoomSelect>                    
                        </RoomRow>                 
                    )}
                </RoomsContainer>                        
            </Modal>
        )
    }
}

/**
 * *****************************************************************************************
 * ! OLD FUNCTIONAL VERSION
 */


// const ModifySelectionRoom = ({distributions, isModalOpen, onClose, selectText}) => {
//     const [activeStepIndex,  useActiveStepIndex]   = useState(1)
//     const [roomsSelected,    useRoomsSelected]     = useState([])
//     const [providerSelected, useProviderSelected]  = useState(null)
    
//     useEffect(() => {
//         useActiveStepIndex(1)
//         useRoomsSelected([])  // re-init when open the modale
//         useProviderSelected(null)
//     }, [isModalOpen]) 

//     const roomsIdAndHowManySelected = {}

//     if(roomsSelected)
//         roomsSelected.map(r => {
//             return roomsIdAndHowManySelected[r.room.id] 
//                                     ? roomsIdAndHowManySelected[r.room.id] = roomsIdAndHowManySelected[r.room.id] + 1
//                                     : roomsIdAndHowManySelected[r.room.id] = 1
//         })

//     const steps     = distributions.map(d => d.name)
//     const stepRooms = providerSelected == null 
//                             ? distributions[activeStepIndex-1].rooms
//                             : distributions[activeStepIndex-1].rooms
//                                                               .filter(r => r.providerName === providerSelected)
//                                                               .filter(r => { 
//                                                                   return !roomsIdAndHowManySelected[r.id] ||
//                                                                           roomsIdAndHowManySelected[r.id] < r.numRoomsAvailable }) // skip if rooms already selected is less or equal of room availability
                                                                    
 
  
//     return (
//         <Modal open={isModalOpen} onClose={() => onClose(null)} classNames={{modal: styles.root, closeButton: styles.closeButton}}>
//             <ModifySelectionSteps steps={steps} activeStepIndex={activeStepIndex}/>
                        
//             <RoomsContainer>
//                 {stepRooms.map((room, index) =>
//                     <RoomRow key={index}>

//                         <Provider src={`/content/react/images/${room.providerName.toLowerCase()}.png`} />
//                         <RoomName>{room.name}</RoomName>
//                         <RoomPrice>{room.price} €</RoomPrice>
//                         {room.directPayment && 
//                          <DirectPayment>
//                              <DirectPaymentText data-tip="custom theme" data-for="direct-payment">Pago en hotel</DirectPaymentText>
//                              <ReactTooltip id="direct-payment" effect="solid" place="left" type="info">
//                                  <DirectPaymentTooltipText>                    
//                                      {t("direct_payment_Tooltip_text")}
//                                  </DirectPaymentTooltipText>
//                              </ReactTooltip>            
//                          </DirectPayment>}
//                         <RoomSelect onClick={() => {
//                                                     const nextStepIndex = activeStepIndex + 1    

//                                                     const distributionId   = distributions[activeStepIndex-1].distributionId
//                                                     const newRoomsSelected = roomsSelected &&roomsSelected.filter(r => r.distributionId !== distributionId)
//                                                     newRoomsSelected.push({ distributionId, room})

//                                                     useRoomsSelected(newRoomsSelected)
//                                                     useProviderSelected(room.providerName)

//                                                     if(nextStepIndex > distributions.length) {            
//                                                         onClose(newRoomsSelected)
//                                                         useActiveStepIndex(1)   
//                                                         useProviderSelected(null)
//                                                     }
//                                                     else useActiveStepIndex(nextStepIndex)   // move to next step

//                                         }}>{selectText}</RoomSelect>                    
//                     </RoomRow>                 
//                 )}
//             </RoomsContainer>                        
//         </Modal>

//     )
// }

 export default withTranslation()(ModifySelectionRoom)