import React from "react"
import Map from "./map"

export default class MapContainer extends React.Component{

    constructor(props){
        super(props)

        this.state = {
            width: 0,
            height: 0
        }
 
        this.handleUpdateSize = this.handleUpdateSize.bind(this)
    }

    componentDidMount(){
        window.addEventListener("resize", this.handleUpdateSize)
        this.handleUpdateSize()
    }

    componentWillUnmount(){
        window.removeEventListener("resize", this.handleUpdateSize)
    }

    handleUpdateSize(){
        this.setState({
            width: this.mapContainer.clientWidth,
            heigth: this.mapContainer.clientHeight
        })
    }

    render(){
        return (
            <div id="map-container" style={{flex:  10}} ref={elem => this.mapContainer = elem}>
                <Map 
                    width={this.state.width} 
                    heigh={this.state.height}
                    initialLat={this.props.initialLat}                         
                    initialLng={this.props.initialLng} 
                    onChangeHotels={this.props.onChangeHotels}
                    showHotelDetails={this.props.onShowHotelDetails}
                />
            </div>
        )
    }
}