import React from 'react'
import styled from 'styled-components'
import {withTransaltion, withTranslation} from 'react-i18next'
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome'
import responsiveHelper        from '../../utility/responsiveHelper'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 100px;
    justify-content: center;

    @media (max-width: 768px){
        padding: 20px 5%;
        border-top: 2px dotted #ddd;
    }
`

const Name = styled.div`
    flex: 1;
    font-size: 1.6em;
    letter-spacing: 1px;
    margin-bottom: 20px;
    margin-top: 20px;

    @media (max-width: 768px){
        font-size: 1.2em;
    }
`

const Address = styled.div`
    flex: 1;
    margin-bottom: 10px;
    font-size: 1.2em;

    @media (max-width: 768px){
        font-size: 1em;
    }
`

const Phone = styled.div`
    font-size: 1.2em;
    margin-bottom: 10px;

    @media (max-width: 768px){
        font-size: 1em;
    }
`

const ImportantInformations = styled.div`
    font-size: 1.2em;
    background-color: beige;
    padding: 10px 20px;
    margin-top: 40px;

    @media (max-width: 768px){
        font-size: 1em;
    }
`

const Description = styled.div`
    flex: 5;
    margin: 40px 0 24px 0;
    line-height: 1.4em;
    text-align: justify;
`

const PhoneIcon = styled(FontAwesomeIcon)`
    margin-right: 15px;
    opacity: 0.4;

    @media (max-width: 768px){
        display: none;
    }
`

const AddressIcon = styled(FontAwesomeIcon)`
    margin-right: 20px;
    opacity: 0.4;
    
    @media (max-width: 768px){
        display: none;
    }
`

const ClockIcon = styled(FontAwesomeIcon)`
    margin-right: 15px;
    opacity: 0.4;

    
    @media (max-width: 768px){
        display: none;
    }
`

const CheckInOut = styled.div`
    display: flex;
    font-size: 1.2em;

    
    @media (max-width: 768px){
        font-size: 1em;
        width: 100%;
        display: inline-block;
    }
`
const CheckInOutRow = styled.div`
    display: inline-block;
    width: 100%;
`

const CheckInOutItemLabel = styled.div`
    margin-right: 10px;

    @media (max-width: 768px){
        display: inline-block;
        width: 50%;
    }
`

const FacilitiesTitle = styled.div`
    font-size: 1.2em;
    text-transform: uppercase;
    margin-bottom: 10px;
    border-top: 1px solid #eee;
    padding-top: 20px;
`

const Facilities = styled.div`

`

const FacilityGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`

const FacilityGroupName = styled.div`
    font-size: 1.2em;
`

const FacilitiesByGroup = styled.div`
`

const Facility = styled.div`
    width: 50%;
    padding: 10px 0px;
    float: left;
    display: flex;

    @media (max-width: 768px){
        width: 100%;
    }
`

const FacilityCheckIcon = styled(FontAwesomeIcon)`
    margin-right: 10px;
    opacity: 0.4;
    color: green;
    vertical-align: bottom;
`


const HotelDetailsDescription = ({t, name, address, description, phone, ii, checkInOut, facilities}) => {

    const renderFacilities = () => {
        if(!facilities || facilities.length === 0) return

        // las facilities llegan todas mezcladas, aquì se agrupan por "group"
        const facilitiesByGroup = facilities && 
                                  facilities.reduce((rv, x) => {
                                                                  (rv[x["group"]] = rv[x["group"]] || []).push(x)
                                                                  return rv
                                                              }, {})

        const groups = []
        for(let i in facilitiesByGroup)
            groups.push({name: facilitiesByGroup[i][0].groupName, facilities: facilitiesByGroup[i].map(f => f.name)})
        
        const allFacilities = []
        groups.map(g => {
            const groupFacilities = g.facilities.filter(fac => fac && fac.length > 0)
            if(groupFacilities.length > 0)
                allFacilities.push(groupFacilities)
        })

        return  (allFacilities.length > 0 &&
                 <Facilities>
                    <FacilitiesTitle>{t("hotel_details.FacilitiesTitle")}</FacilitiesTitle>
                    {/* {groups.map((g, index) => renderFacilitiesGroup(g, index))} */}
                    {allFacilities.map((fac, key2) => 
                            <Facility key={key2}>    
                                <FacilityCheckIcon icon="check" /> {fac.join(", ")}
                            </Facility> 
                    )}
                 </Facilities>                                                                               
                )
    }

    const renderCheckInOutDefault = (checkInOut) =>
        checkInOut && 
        <CheckInOut>
            <ClockIcon icon="clock" />
            <CheckInOutItemLabel>{t("hotel_details.CheckIn")}</CheckInOutItemLabel>  {checkInOut.checkinFrom} - {checkInOut.checkinTo}
            <div style={{width: "30px", height: "1px"}}> </div>
            <CheckInOutItemLabel>{t("hotel_details.CheckOut")}</CheckInOutItemLabel> {checkInOut.checkoutFrom} - {checkInOut.checkoutTo}               
        </CheckInOut>
        

    const renderCheckInOutMobile = (checkInOut) =>
        checkInOut && 
        <CheckInOut>
            <CheckInOutRow>
                <CheckInOutItemLabel>{t("hotel_details.CheckIn")}</CheckInOutItemLabel>{`${checkInOut.checkinFrom} - ${checkInOut.checkinTo}`}  
            </CheckInOutRow>
            <CheckInOutRow>
                <CheckInOutItemLabel>{t("hotel_details.CheckOut")}</CheckInOutItemLabel>{`${checkInOut.checkoutFrom} - ${checkInOut.checkoutTo}`}                
            </CheckInOutRow>
        </CheckInOut>
        

    return (
        <Container>
            <Name>{name}</Name>
            <Address><AddressIcon icon="map-marker-alt" />{address} </Address>
            <Phone><PhoneIcon icon="phone" />{phone}</Phone>
            {responsiveHelper.isMobile() ? renderCheckInOutMobile(checkInOut) : renderCheckInOutDefault(checkInOut) }            
            <ImportantInformations>{ii}</ImportantInformations>
            <Description>{description}</Description>

           {renderFacilities()}

        </Container>
    )
}


export default withTranslation()(HotelDetailsDescription)