import React, { useState } from "react"
import styles from "./SelectPackages.module.css"
import styled from "styled-components"
import Modal from "react-responsive-modal"
import { withTranslation } from "react-i18next"

const PackagesContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const Instructions = styled.div`
	font-size: 1.4em;
	margin: 10px 0;
	text-align: center;
	margin: 0 0 2em 0;

	@media (max-width: 768px) {
		margin-top: 20px;
	}
`

const Package = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 2px dotted #ddd;

	&:last-child {
		border-bottom: none;
	}

	@media (max-width: 768px) {
		flex-direction: column;
	}
`

const Name = styled.div`
	flex: 1;
	text-align: left;

	@media (max-width: 768px) {
		font-size: 1.2em;
		font-weight: bold;
		margin-bottom: 10px;
		text-transform: uppercase;
	}
`

const Description = styled.div`
	flex: 2;
	text-align: justify;
`

const Price = styled.div`
	flex: 1;
	font-size: 1.2em;
	text-align: right;
	padding-right: 2em;

	@media (max-width: 768px) {
		padding-right: 0;
		margin-bottom: 20px;
		font-weight: bold;
	}
`

const SelectPackage = styled.div`
	cursor: pointer;
	color: #fff;
	padding: 5px;
	width: 150px;
	text-align: center;
	background-color: ${props => (props.selected ? "#4d924d" : "#5cb85c")};
`

const ConfirmButton = styled.div`
	cursor: pointer;
	color: #fff;
	padding: 5px;
	width: 150px;
	font-size: 1.4em;
	background-color: #4d924d;
	margin: 20px auto 0 auto;
	text-align: center;
`

const SelectPackageComp = ({
	t,
	isModalOpen,
	packages,
	distributions,
	onClose
}) => {
	const [selectedPackages, setSelectedPackages] = useState(null)
	const [forceReRender, setForceReRender] = useState(0)

	const packagesId = []
	for (let i in packages)
		if (packagesId.filter(p => p === packages[i].packageId).length < 1)
			packagesId.push(packages[i].packageId)

	const distributionForPackages = packagesId.map(packageId => {
		const distributionsId = packages
			.filter(p => p.packageId === packageId)
			.map(p => p.distributionId)

		const distributionsForPackage = distributions.filter(
			d =>
				distributionsId.filter(di => di === d.distributionId).length > 0
		)

		const pack = packages.filter(p => p.packageId === packageId)[0] // the package name is repeated for every distributions

		return {
			packageId: pack.packageId,
			packageName: pack.name,
			packageDescription: pack.description,
			packagePrice: pack.price,
			distributions: distributionsForPackage
		}
	})

	const handleToggleSelectPackage = packageId => {
		const newSelectedPackages =
			selectedPackages === null
				? packagesId.map(id => ({ packageId: id, selected: false }))
				: selectedPackages

		const selectionToToggle = newSelectedPackages.find(
			p => p.packageId === packageId
		)
		selectionToToggle.selected = !selectionToToggle.selected

		setSelectedPackages(newSelectedPackages)
		setForceReRender(forceReRender + 1) // to force a render every time selectedPackages, at the moment there is something with his Hook that doesn't work correctly...
	}

	return (
		<Modal
			open={isModalOpen}
			onClose={() => onClose(null)}
			classNames={{ modal: styles.root, closeButton: styles.closeButton }}
			forceReRender={forceReRender}
		>
			<PackagesContainer>
				<Instructions>{t("package.Add")}: </Instructions>
				{packagesId &&
					packagesId.map((pId, i1) => {
						const pDistr =
							distributionForPackages.filter(
								d => d.packageId === pId
							)[0] || []
						const isSelected =
							true &&
							selectedPackages &&
							selectedPackages.find(p => p.packageId === pId)
								.selected
						return (
							<Package key={i1}>
								<Name>{pDistr.packageName}</Name>
								<Description>
									{pDistr.packageDescription}
								</Description>
								<Price>{`${pDistr.packagePrice} €`}</Price>
								<SelectPackage
									selected={isSelected}
									onClick={() =>
										handleToggleSelectPackage(pId)
									}
								>
									{isSelected
										? t("package.Selected")
										: t("package.Select")}
								</SelectPackage>
							</Package>
						)
					})}

				<ConfirmButton onClick={() => onClose(selectedPackages || [])}>
					{t("package.Book")}
				</ConfirmButton>
			</PackagesContainer>
		</Modal>
	)
}

export default withTranslation()(SelectPackageComp)
