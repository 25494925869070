import React        from 'react'
import styles       from './ReviewRate.module.css'

// se pone una clase con un background-position diferente en base cuantas estrellas quieres mostrar 

export default ({value, label, scale, className}) =>   
    value < 1 
        ? null 
        : <div className={className}>
            {label && <div className={styles.label}>{label}</div>}
            <div className={styles[`stars-${value}`]} />
        </div>
 
        

