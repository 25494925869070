/**
 * @flow
 * @relayHash 0eee7bae5275f3d46b1b4ca3b54e0db3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HotelIdsByFilterQueryVariables = {|
  filter: string,
  language: string,
|};
export type HotelIdsByFilterQueryResponse = {|
  +hotelIdsByFilter: ?string
|};
export type HotelIdsByFilterQuery = {|
  variables: HotelIdsByFilterQueryVariables,
  response: HotelIdsByFilterQueryResponse,
|};
*/


/*
query HotelIdsByFilterQuery(
  $filter: String!
  $language: String!
) {
  hotelIdsByFilter(filter: $filter, language: $language)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "language",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "hotelIdsByFilter",
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "language",
        "variableName": "language",
        "type": "String!"
      }
    ],
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "HotelIdsByFilterQuery",
    "type": "CanariasQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "HotelIdsByFilterQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "HotelIdsByFilterQuery",
    "id": null,
    "text": "query HotelIdsByFilterQuery(\n  $filter: String!\n  $language: String!\n) {\n  hotelIdsByFilter(filter: $filter, language: $language)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '57393ba89e182553b63bcf1e3dec3d50';
module.exports = node;
