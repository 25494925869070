import React, { useState } from "react"
import styled from "styled-components"
import queryString from "query-string"
import { createFragmentContainer, graphql } from "react-relay"
import HotelsFilters from "./../HotelsFilters/HotelsFilters"
import ResultHotel from "./ResultHotel"
import HotelBlock from "./../../utility/HotelBlock"
import ResultPaginator from "./ResultPaginator"
import ResultOrder from "./ResultOrder"
import NoHotelResults from "./SearchResultsNoResults"
import SendResultsByEmail from '../../utility/SendResultsByEmail'
import { urls } from "./../../utility/constants"
import { Element, animateScroll as scroll } from "react-scroll"

import DesktopMap from "../SearchMap/desktop-map"

import "react-image-lightbox/style.css"

const Container = styled.div`
	display: flex;
	background-color: #eee;

	@media (max-width: 1378px) {
		flex-direction: column;
		align-items: center;
	}
`
const LeftColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const Filters = styled(HotelsFilters)`
	width: 300px;
	@media (max-width: 1378px) {
		width: 100%;
		max-width: 600px;
		margin-bottom: 40px;
	}
`
const SendEmailResults = styled(SendResultsByEmail)`
    width: 300px;
    margin-bottom: 20px;
	@media (max-width: 1378px) {
		width: 100%;
		max-width: 600px;
		margin-bottom: 40px;
	}
`

const HotelsAndOrders = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	@media (max-width: 1378px) {
		max-width: 100%;
	}
`

const Hotels = styled.div`
	flex: 1;
`
const Hotel = styled(ResultHotel)`
	margin: 0px 20px 60px 20px;
	box-shadow: 0 0 10px #999;
	background-color: #fff;

	@media (max-width: 768px) {
		margin: 0px 0px 60px 0px;
		overflow: hidden;
	}
`

const SearchResults = ({
	className,
	searchParams,
	availability,
	filterDisabled,
	history,
	location,
	reloadForFilterModified,
	language,
	channelCode,
	channelKey
}) => {
	let hotels = []
	let facilitiesFilter = {}
	let totalResults = 0
	let token = ""

	if (true && availability) {
		hotels = availability.hotels
		facilitiesFilter = availability.facilitiesFilter
		totalResults = availability.totalResults
		token = availability.token
	}

	const getHhotelBlockedUrl = params =>
		`{urls.hotelBlocked}?params=${JSON.stringify(params)}`

	const [doHotelBlock, setDoHotelBlock] = useState(false)
	const [hotelBlockDetails, setHotelBlockDetails] = useState(null)
	const [hotelBlockOtherData, setHotelBlockOtherData] = useState(null)
	const [scrolledUp, setScrolledUp] = useState(false)

	if (!scrolledUp) {
		setScrolledUp(true)
		scroll.scrollTo("scrollTopElement")
	}

	const onSelectRooms = (selections, otherData) => {
		for (let i in selections.rooms) {
			selections.rooms[i].initDate = searchParams.initDate
			selections.rooms[i].endDate = searchParams.endDate
		}

		for (let i in selections.packages) {
			selections.packages[i].initDate = searchParams.initDate
			selections.packages[i].endDate = searchParams.endDate
		}

		selections.distributions = searchParams.distributions.map(d => ({
			id: d.id,
			adults: d.adults,
			childrenAges: d.childrenAges
		}))

		setHotelBlockDetails(selections)
		setHotelBlockOtherData(otherData)
		setDoHotelBlock(true)
	}

	const handleHotelBlockReponse = response => {
		setDoHotelBlock(false)

		const params = {
			param1: "value1",
			param2: "value2"
		}

		history.push(getHhotelBlockedUrl(params))
	}

	const handleChangePage = pageNumber => {
		const oldParams = JSON.parse(
			queryString.parse(window.location.search).params
		)
		const newParams = Object.assign({}, oldParams, { pageNum: pageNumber })

		const newUrl = `${language === "es" ? "" : "/" + language}${
			urls.hotelResults
		}?params=${JSON.stringify(newParams)}`
		history.push(newUrl)
		reloadForFilterModified(
			null,
			hotels,
			facilitiesFilter,
			pageNumber,
			token
		)
	}

	const handleOrderBy = orderBy => {
		const oldParams = JSON.parse(
			queryString.parse(window.location.search).params
		)
		const newParams = Object.assign({}, oldParams, { order: orderBy })
		const newUrl = `${language === "es" ? "" : "/" + language}${
			urls.hotelResults
		}?params=${JSON.stringify(newParams)}`
		history.push(newUrl)
		reloadForFilterModified(
			null,
			hotels,
			facilitiesFilter,
			null,
			token,
			orderBy
		)
	}

	const handleReloadForFilterModified = newFilterParams =>
		reloadForFilterModified(
			newFilterParams,
			hotels,
			facilitiesFilter,
			null,
			token
		)

	const renderResultOrder = () =>
		totalResults ? <ResultOrder onChangeOrderBy={handleOrderBy} /> : null

	const renderHotelsResults = () =>
		!totalResults ? (
			<NoHotelResults />
		) : (
			<HotelsAndOrders>
				{renderResultOrder()}
				<Hotels>
					<DesktopMap 
						initialLat={28.0792941} 
						initialLng={-16.6798716}
						hotels={hotels}
						language={language}
						disabled={filterDisabled}
						history={history}
						location={location}
						reloadForFilterModified={handleReloadForFilterModified}
						token={token}
						searchParams={searchParams}
						channelCode={channelCode}
						channelKey={channelKey}
					/>

					<Element name="scrollTopElement" />
					{hotels &&
						hotels.map((hotel, index) => (
							<Hotel
								key={index}
								hotel={hotel}
								onSelectRooms={onSelectRooms}
								realDistributionNumber={
									availability.realDistributionNumber
								}
							/>
						))}
					<ResultPaginator
						pageSize={searchParams.pageSize}
						totalResults={totalResults}
						onChangePage={handleChangePage}
					/>
				</Hotels>
			</HotelsAndOrders>
		)

	const renderFilters = () => {
		const filterParams = JSON.parse(
			queryString.parse(window.location.search).params
		).filter
		if(JSON.stringify(filterParams) === JSON.stringify({}) && totalResults <= 1){
			return (
				<Filters
					hotels={hotels}
					language={language}
					disabled={filterDisabled}
					history={history}
					location={location}
					reloadForFilterModified={handleReloadForFilterModified}
					token={token}
					searchParams={searchParams}
					channelCode={channelCode}
					channelKey={channelKey}
				/>
			)
		}

		return (
			<Filters
				hotels={hotels}
				language={language}
				disabled={filterDisabled}
				history={history}
				location={location}
				reloadForFilterModified={handleReloadForFilterModified}
				token={token}
				searchParams={searchParams}
				channelCode={channelCode}
				channelKey={channelKey}
			/>
		)
	}

	return ( // si hay un hotel block en curso muestra el componente correspondiente, sino los resultados normal
		<Container className={className}>
			{doHotelBlock ? (
				<HotelBlock
					details={hotelBlockDetails}
					otherData={hotelBlockOtherData}
					onResponse={handleHotelBlockReponse}
					channelCode={channelCode}
					channelKey={channelKey}
					language={language}
				/>
			) : (
				<>
                    <LeftColumnContainer>
                        <SendEmailResults />
                        {renderFilters()}
                    </LeftColumnContainer>
					{renderHotelsResults()}
				</>
			)}
		</Container>
	)
}

// exporta el Fragment de la query "availability", declara los datos que usa en este componente mas otro Fragment (ResultHotel_hotel)
export default createFragmentContainer(
	SearchResults,
	graphql`
		fragment SearchResults_availability on AvailabilityResults {
			currentPage
			order
			pageSize
			totalResults
			token
			realDistributionNumber
			facilitiesFilter {
				name
				code
				group
			}
			hotels {
				...ResultHotel_hotel
			}
		}
	`
)
