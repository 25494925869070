import React, { useState }                  from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import Distribution                         from './ResultDistribution'
import ModifySelectedRooms                  from '../ModifySelection/ModifySelectedRooms'
import SelectPackages                       from './../Packages/SelectPackages'
import {FontAwesomeIcon }                   from "@fortawesome/react-fontawesome"
import styled                               from 'styled-components'
import responsiveHelper                     from '../../utility/responsiveHelper'
import {withTranslation}                    from 'react-i18next'

import {Deal, DealResume, DealName, DescriptionSpace, DealPrice, Distributions, ButtonModify, DistributionsDetails} 
    from './ResultDeal.style'
import styles from './ResultDeal.module.css'

import ResultDealShowLoading from './ResultDealShowLoading'
import ResultDealButtonSelect from './ResultDealButtonSelect'

require("react-bootstrap/ModalHeader")

const RoomTypeTitle = styled.div`
    font-weight: bold;
    color: rgb(51, 51, 51);
    text-transform: uppercase;
    letter-spacing: 1px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
`
const DealHeaderLeft = styled.div`
    display: flex;
    flex: 1;
    align-items: center;

    @media (max-width: 1378px){
        width: 66%;
        flex-wrap: wrap;
    }

    @media (max-width: 768px){
        width: 100%;
        display: flex;
        flex-direction: column;
    }
`



const TotalPriceText = styled.div`
    display: flex;   
    flex: 1; 
    padding-left: 20px;
`

function ResultDeal({deal, className, onSelectRooms, packages, showLoading, t, realDistributionNumber}){
    const {distributions, defaultSelectionTotalPrice, code, name} = deal

    const roundPrice = price => (Math.round(price * 100) / 100).toFixed(2)
    
    const buildDefaultSelectedRooms = () =>
        distributions.map(distribution => {
            
            return {
                distributionId: distribution.distributionId,
                room:           distribution.rooms.find(room => room.id === distribution.defaultSelectionRoom.roomId) 
            }
        })    

    const [isModalSelectionOpen,                setIsModalSelectionOpen]             = useState(false)
    const [isModalSelectedPackagesOpen,         setIsModalSelectedPackagesOpen]      = useState(false)
    const [customerRoomsSelectionTotalPrice,    setCustomerRoomsSelectionTotalPrice] = useState(roundPrice(defaultSelectionTotalPrice))
    const [selectedRooms,                       setCustomerRoomsSelection]           = useState(buildDefaultSelectedRooms())
    const [roomsPackages,                       setRoomsPackages]                    = useState([])
    
    const getSelectedRoom = (distributionId, roomList) => {
        var rooms = roomList || selectedRooms;
        for(let i in rooms)
            if(rooms[i].distributionId === distributionId)
                return rooms[i].room
    }


    const sendSelectedRoomsAndPackage = (selectedPackages = []) => {

        const getPackages = distributionId =>  selectedPackages.filter(p => p.selected)
                                                               .map(p => ({
                                                                           packageId:          p.packageId,
                                                                           distributionId:     distributionId,
                                                                       }))

        const packages =  selectedPackages === null 
                                ? []                                            
                                : selectedRooms.map(s => getPackages(s.distributionId))
                                               .flat(2)

        const blockHotelParams = {
            rooms: selectedRooms.map(s => ({
                        id:             s.room.roomId,
                        code:           s.room.code,
                        dealCode:       code,
                        price:          s.room.price,
                        isPVP:          s.room.isPVP,
                        directPayment:  s.room.directPayment,
                        canaryResident: s.room.canaryResident,
                        distributionId: s.distributionId,
                        roomName:       s.room.name,
                        dealName:       deal.name,
                        roomAdditionalInfo:     s.room.roomAdditionalInfo,
                        rawCancellationCosts: s.room.rawCancellationCosts // add text 
            })),
            packages
        }

        onSelectRooms(blockHotelParams)      
    }

    const showModifyButton = distributions.filter(d => d.rooms.length > 1).length  > 0
    const changeRoomText = distributions.length == 1 ? t("resultDeal.changeRoomTitle") : t("resultDeal.changeRoomsTitle")


    const renderDealResumeDesktop = () => 
        <DealResume>
            <DealHeaderLeft>
                <DealName>{name}</DealName>    
                <DescriptionSpace>
                    <RoomTypeTitle> {t("resultDeal.roomTypeTitle")} </RoomTypeTitle>
                    {  !showLoading && showModifyButton &&   
                        <>                        
                            <ButtonModify onClick={e => setIsModalSelectionOpen(true)}>{changeRoomText}</ButtonModify>
                            {/* I'll have to see how this down here works and use it to show the information */}
                            <ModifySelectedRooms 
                                isModalOpen={isModalSelectionOpen} 
                                onClose={newRoomsSelection => {            
                                            setIsModalSelectionOpen(false)
                                    
                                            if(!newRoomsSelection) return
                                            
                                            let defaultSelectionTotalPrice = 0
                                            for(let i in newRoomsSelection) defaultSelectionTotalPrice += newRoomsSelection[i].room.price    
                                    
                                            setCustomerRoomsSelectionTotalPrice(roundPrice(defaultSelectionTotalPrice))
                                            setCustomerRoomsSelection(newRoomsSelection)            
                                        }} 
                                distributions={distributions} 
                                selectText={t("deal_selection.button_select_text")} />
                        </>
                    }
                </DescriptionSpace>
                <DealPrice>
                    {showLoading    ? <FontAwesomeIcon icon="sync" className={styles.iconSync} />
                                    : `${customerRoomsSelectionTotalPrice} €`
                    }
                    
                </DealPrice>
            </DealHeaderLeft>
            <ResultDealShowLoading showLoading={showLoading}>
                <ResultDealButtonSelect /* VIC123 this is the select button to book a room */
                    selectedRooms={selectedRooms}
                    packages={packages}
                    sendSelectedRoomsAndPackage={sendSelectedRoomsAndPackage}
                    onCustomClick={(roomPackages) => {
                        setRoomsPackages(roomPackages)
                        setIsModalSelectedPackagesOpen(true) 
                    }}
                    text={t("deal.button_select_text")}
                />
                
                <SelectPackages 
                    onClose={selectedPackages => {
                                                    setIsModalSelectedPackagesOpen(false)        
                                                    selectedPackages && sendSelectedRoomsAndPackage(selectedPackages)
                                                }} 
                    packages={roomsPackages} 
                    distributions={distributions} 
                    isModalOpen={isModalSelectedPackagesOpen}
                />
            </ResultDealShowLoading>                
        </DealResume>

    
    const renderDealResumeTablet = () => 
        <DealResume>
            <DealHeaderLeft>
                <DealName>{name}</DealName>    
                <DescriptionSpace>
                    {  !showLoading && showModifyButton &&   // show button only if there are more options
                        <>                        
                            <ButtonModify onClick={e => setIsModalSelectionOpen(true)}>{changeRoomText}</ButtonModify>
                            <ModifySelectedRooms 
                                isModalOpen={isModalSelectionOpen} 
                                onClose={newRoomsSelection => {            
                                            setIsModalSelectionOpen(false)
                                    
                                            if(!newRoomsSelection) return
                                            
                                            let defaultSelectionTotalPrice = 0
                                            for(let i in newRoomsSelection) defaultSelectionTotalPrice += newRoomsSelection[i].room.price    
                                    
                                            setCustomerRoomsSelectionTotalPrice(roundPrice(defaultSelectionTotalPrice))
                                            setCustomerRoomsSelection(newRoomsSelection)            
                                        }} 
                                distributions={distributions} 
                                selectText={t("deal_selection.button_select_text")} />
                        </>
                    }
                </DescriptionSpace>                    
            </DealHeaderLeft>                
        </DealResume>


    const renderDealResumeMobile = () => 
        <DealResume>
            <DealHeaderLeft>
                <DealName>{name}</DealName>    
                <DescriptionSpace>
                    {  !showLoading && showModifyButton &&   // show button only if there are more options
                        <>                        
                            <ButtonModify onClick={e => setIsModalSelectionOpen(true)}>{changeRoomText}</ButtonModify>
                            <ModifySelectedRooms 
                                isModalOpen={isModalSelectionOpen} 
                                onClose={newRoomsSelection => {            
                                            setIsModalSelectionOpen(false)
                                    
                                            if(!newRoomsSelection) return
                                            
                                            let defaultSelectionTotalPrice = 0
                                            for(let i in newRoomsSelection) defaultSelectionTotalPrice += newRoomsSelection[i].room.price    
                                    
                                            setCustomerRoomsSelectionTotalPrice(roundPrice(defaultSelectionTotalPrice))
                                            setCustomerRoomsSelection(newRoomsSelection)            
                                        }} 
                                distributions={distributions} 
                                selectText={t("deal_selection.button_select_text")} />
                        </>
                    }
                </DescriptionSpace>                    
            </DealHeaderLeft>                
        </DealResume>


    const renderDealResumeBottomDesktop = () => 
        <>
        </>

    const renderDealResumeBottomTablet = () => 
        <>
            <DealPrice>
                {showLoading    ? <FontAwesomeIcon icon="sync" className={styles.iconSync} />
                                : <><TotalPriceText>{t("resultDeal.totalPriceText")}</TotalPriceText>{customerRoomsSelectionTotalPrice} €</>
                }
                
            </DealPrice>

            <ResultDealShowLoading showLoading={showLoading}>
                <ResultDealButtonSelect 
                    selectedRooms={selectedRooms}
                    packages={packages}
                    sendSelectedRoomsAndPackage={sendSelectedRoomsAndPackage}
                    onCustomClick={(roomPackages) => {
                        setRoomsPackages(roomPackages)
                        setIsModalSelectedPackagesOpen(true) 
                    }}
                    text={t("deal.button_select_text")}
                />
                
                <SelectPackages 
                    onClose={selectedPackages => {
                                                    setIsModalSelectedPackagesOpen(false)        
                                                    selectedPackages && sendSelectedRoomsAndPackage(selectedPackages)
                                                }} 
                    packages={roomsPackages} 
                    distributions={distributions} 
                    isModalOpen={isModalSelectedPackagesOpen}
                />
            </ResultDealShowLoading> 
        </>

    const renderDealResumeBottomMobile = () => 
        <>
            <DealPrice>
                {showLoading    ? <FontAwesomeIcon icon="sync" className={styles.iconSync} />
                                : <><TotalPriceText>{t("resultDeal.totalPriceText")}</TotalPriceText>{customerRoomsSelectionTotalPrice} €</>
                }                
            </DealPrice>

            <ResultDealShowLoading showLoading={showLoading}>
                <ResultDealButtonSelect 
                    selectedRooms={selectedRooms}
                    packages={packages}
                    sendSelectedRoomsAndPackage={sendSelectedRoomsAndPackage}
                    onCustomClick={(roomPackages) => {
                        setRoomsPackages(roomPackages)
                        setIsModalSelectedPackagesOpen(true) 
                    }}
                    text={t("deal.button_select_text")}
                />
                
                <SelectPackages 
                    onClose={selectedPackages => {
                                                    setIsModalSelectedPackagesOpen(false)        
                                                    selectedPackages && sendSelectedRoomsAndPackage(selectedPackages)
                                                }} 
                    packages={roomsPackages} 
                    distributions={distributions} 
                    isModalOpen={isModalSelectedPackagesOpen}
                />
            </ResultDealShowLoading>         
        </>

    return(        
        <Deal className={className}> 
            { responsiveHelper.isDesktop() && renderDealResumeDesktop() }
            { responsiveHelper.isTablet()  && renderDealResumeTablet()  }
            { responsiveHelper.isMobile()  && renderDealResumeMobile()  }
            
            <DistributionsDetails>
                <Distributions>
                    {distributions && distributions.map((distribution, index) => {
                            const distributioName = realDistributionNumber > distributions.length 
                                                            ? t("distributions.up_to_x_person").replace("__num__", distribution.rooms[0].maxOccupancy)
                                                            : distribution.name
                            
                            var selectedRoom = getSelectedRoom(distribution.distributionId);
                            if(distribution.rooms.filter(r => r.id == selectedRoom.id).length === 0){
                                //Al filtrar por regimen, cuando hay 1 hab por regimen (creo...), selectedRoom a veces queda vinculada a otro regimen
                                //en este caso, la reconstruimos desde aqui
                                selectedRoom = getSelectedRoom(distribution.distributionId, buildDefaultSelectedRooms());
                            }
                            return <Distribution distributioName={distributioName} distribution={distribution} key={index} selectedRoom={selectedRoom} showLoading={showLoading} />                    
                        }
                        
                    )}            
                </Distributions>
                               
            </DistributionsDetails> 
            
            { responsiveHelper.isDesktop() && renderDealResumeBottomDesktop() }
            { responsiveHelper.isTablet()  && renderDealResumeBottomTablet()  }
            { responsiveHelper.isMobile()  && renderDealResumeBottomMobile()  }
                             
        </Deal>
    )
}

const ResultDealWT = withTranslation()(ResultDeal)

export default createFragmentContainer(ResultDealWT, graphql`
    fragment ResultDeal_deal on AvailabilityResultHotelDeal{
        id
        code
        name
        defaultSelectionTotalPrice
        distributions{                
            id
            distributionId
            name
            rooms{
                id
                roomId
                code
                name              
                price
                providerName
                bookingLink
                canaryResident
                numRoomsAvailable
                roomCount
                cancellationCosts{
                            cost
                            date
                            time
                } 
                rawCancellationCosts{
                    amount
                    percent
                    date
                }
                directPayment
                roomAdditionalInfo
                maxOccupancy
                isPVP 
            }
            defaultSelectionRoom{
                id
                roomId
                roomName
                roomPrice
            }
        }        
    }
`)