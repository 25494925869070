import React from 'react'
import styles from './desktop-map-filters.module.css' 

import HotelsFilters from "./../HotelsFilters/HotelsFilters"

class DesktopMapFilters extends React.Component{

    render(){
        return(
            <div className={styles.filtersContainer}>
                <HotelsFilters 
                    hideSearcher={true}
                    hotels={this.props.hotels}
                    language={this.props.language}
                    history={this.props.history}
                    location={this.props.location}
                    token={this.props.token}
                    searchParams={this.props.searchParams}
                    channelCode={this.props.channelCode}
                    channelKey={this.props.channelKey}
                    reloadForFilterModified={this.props.reloadForFilterModified}                
                    disabled={this.props.disabled}                
                />
            </div>
        )
    }
}
 

export default DesktopMapFilters;