
class SearchUtility{

    getParamsFromUrl(url){
        const urlParts = url && url.split('?')
        const params   = urlParts && urlParts[1] && urlParts[1].split('&')
        
        return params || []
    }
    
    getParamFromUrl(paramName, url){
        const param  = this.getParamsFromUrl(url).find(param => param && param.startsWith(`${paramName}=`))
        const paramValueName = param && param.split('=')
        return (paramValueName && paramValueName[1] ) || ''
    }
}

export default new SearchUtility()