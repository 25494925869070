import React                from 'react'
import styled, {keyframes}  from 'styled-components'
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome'
import responsiveHelper     from '../../utility/responsiveHelper'

const red = '#b71c1c';

const StepsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 1200px;

    @media (max-width: 768px){
        width: 95%;
        text-align: center;
        font-size: 1.2em;
        padding: 5px 0 15px 0;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
`

const Steps = styled.div`
    display: flex;
    margin-top: 30px;  
    position: relative;
`

const Step = styled.div`
    flex: 1;
    padding: 10px 0; 
    z-index: 10; 
    font-weight: ${props => props.active ? 'bold' : 'normal'};
    font-size    ${props => props.active ? '1.4em' : '1.2em'};    
    color:       ${props => props.active ? '#333' : '#eee'};
`

const kfProgressBar_0_3 = keyframes`
    0%{
        width: 20%;
    }
    100%{
        width: 20%;
    }
`

const kfProgressBar_1_3 = keyframes`
    0%{
        width: 20%;
    }
    100%{
        width: 40%;
    }
`
const kfProgressBar_2_3 = keyframes`
    0%{
        width: 40%;
    }
    100%{
        width: 60%;
    }
`

const ProgressBar = styled.div`
    position: absolute;
    z-index: 0;
    width: 20%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    background-color: ${red};
    animation: ${props => props.keyframe} 0.5s ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
`

const RightArrow = styled.div`
    width: 0px;
    height: 0px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid ${red};
    position: absolute;
    top: 0;
    right: -30px;
`

const CheckIcon = styled(FontAwesomeIcon)`
    margin-right: 10px;
`

export default ({steps, activeStepIndex}) => {

    const keyframe = (steps.length === 3 && activeStepIndex === 1) ? kfProgressBar_0_3
                   : (steps.length === 3 && activeStepIndex === 2) ? kfProgressBar_1_3
                   : (steps.length === 3 && activeStepIndex === 3) ? kfProgressBar_2_3
                   : ''

    const renderDefault = () => 
        <StepsContainer>
                <Steps>
                    <Step key={'first'} className={'first'}></Step>
                    {steps.map((step, index) => 
                        <Step key={index} active={(index + 1) === activeStepIndex}>                        
                            {(index+1) < activeStepIndex ? <CheckIcon icon="check" /> : ''}
                            {step}
                        </Step>
                    )}            
                    <Step key={'last'} className={'last'}></Step>
                    <ProgressBar max={steps.length + 2} keyframe={keyframe}>
                        <RightArrow />
                    </ProgressBar>
                </Steps>
            </StepsContainer>

    const renderMobile = () => 
        <StepsContainer>
               {steps[activeStepIndex]}
            </StepsContainer>
            
    return responsiveHelper.isMobile() ? renderMobile() : renderDefault()
}


