import React, { Component } from 'react'
import Category from '../Widget/Category'
import styles from './desktop-map-results.module.css' 

class DesktopMapResults extends Component {
    handleHotelSelected(hotel){
        this.props.onHotelSelected(hotel)
    }

    render() {
        const { hotels, selected } = this.props
        console.log("hotels ", hotels)
        const selectedId = selected ? selected.id : ""

        return (
            <div className={styles.resultsContainer}>
                {hotels && hotels.sort(h => h.minPrice).map(hotel => 
                    <div key={hotel.id} className={`${styles.hotel} ${selectedId === hotel.id ? styles.hotelSelected : ""}`} onClick={() => this.handleHotelSelected(hotel)}>
                        <div className={styles.hotelPrice}>
                            {hotel.minPrice} €
                        </div>
                        <div className={styles.nameAndCategory}>                            
                            <Category value={4} type={'stars'} className={styles.category}/>
                            <div className={styles.hotelName}>
                                {hotel.name}
                            </div> 
                        </div>
                    </div>
                )}
            </div>
        )
    }
}


export default DesktopMapResults 