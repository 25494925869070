import i18n                 from "i18next"
import {getLanguageFromUrl} from "./../utility/LanguageHelper"
import { initReactI18next } from "react-i18next"
import moment               from "moment"
import 'moment/locale/es'
import 'moment/locale/en-gb'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import 'moment/locale/pt'
import 'moment/locale/ru'


import es from './es'
import en from './en'
import de from './de'
import fr from './fr'
import it from './it'
import nl from './nl'
import pt from './pt'
import ru from './ru'   

const resources  = { en, es, de, fr, it, nl, pt, ru }
const language   = getLanguageFromUrl()

moment.locale(language)

i18n.use(initReactI18next)
    .init({
        resources,
        lng: language,
        keySeparator: false,
        interpolation:{ 
            escapeValue: false
        }
    })

export default i18n    