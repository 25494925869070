import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items:center;
    justify-content: center;
    width: 200px;

    @media (max-width: 1378px){
        width: auto;
    }
`

export default ({showLoading, children}) => !showLoading && <Container>{children}</Container>