/**
 * @flow
 * @relayHash ead82a4a59966bea459a6668f32ce730
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FacilityType = "HOTEL" | "ROOM" | "%future added value";
export type HotelDetailsQueryVariables = {|
  hotelId: string,
  language: string,
|};
export type HotelDetailsQueryResponse = {|
  +hotelDetails: ?{|
    +address: string,
    +category: number,
    +chain: string,
    +city: string,
    +country: string,
    +description: string,
    +importantInformation: string,
    +name: string,
    +photos: ?$ReadOnlyArray<?string>,
    +rankingCanarias: number,
    +rateCanarias: number,
    +rateTripAdvisor: number,
    +theme: string,
    +phone: string,
    +latitude: string,
    +longitude: string,
    +facilities: ?$ReadOnlyArray<?{|
      +code: number,
      +group: number,
      +groupName: string,
      +isPayment: boolean,
      +name: string,
      +type: ?FacilityType,
      +value: string,
    |}>,
    +checkinOut: ?{|
      +checkinFrom: string,
      +checkinTo: string,
      +checkoutFrom: string,
      +checkoutTo: string,
    |},
    +canariasComments: ?$ReadOnlyArray<?{|
      +generalRating: number,
      +rateCleaning: number,
      +rateDreamQuality: number,
      +rateDrinkingSelection: number,
      +rateFood: number,
      +rateLocation: number,
      +rateQualityPriceRelation: number,
      +rateRooms: number,
      +rateService: number,
      +reviewContent: string,
      +reviewDate: string,
      +title: string,
      +tripType: number,
      +user: ?{|
        +name: string,
        +place: string,
      |},
    |}>,
    +tripAdivsorComments: ?{|
      +numReviews: number,
      +rating: number,
      +reviewRating1: number,
      +reviewRating2: number,
      +reviewRating3: number,
      +reviewRating4: number,
      +reviewRating5: number,
      +awards: ?$ReadOnlyArray<?{|
        +award_type: string,
        +category: string,
        +image: string,
        +name: string,
        +year: string,
      |}>,
      +breakdown: ?$ReadOnlyArray<?{|
        +name: string,
        +value: number,
      |}>,
      +comments: ?$ReadOnlyArray<?{|
        +date: string,
        +helpfulVotes: number,
        +publishedDate: string,
        +rating: number,
        +ratingImageUrl: string,
        +text: string,
        +title: string,
        +tripType: string,
        +userLocation: string,
        +username: string,
        +url: string,
      |}>,
      +subrating: ?$ReadOnlyArray<?{|
        +name: string,
        +value: number,
      |}>,
    |},
  |}
|};
export type HotelDetailsQuery = {|
  variables: HotelDetailsQueryVariables,
  response: HotelDetailsQueryResponse,
|};
*/


/*
query HotelDetailsQuery(
  $hotelId: String!
  $language: String!
) {
  hotelDetails(hotelId: $hotelId, language: $language) {
    address
    category
    chain
    city
    country
    description
    importantInformation
    name
    photos
    rankingCanarias
    rateCanarias
    rateTripAdvisor
    theme
    phone
    latitude
    longitude
    facilities {
      code
      group
      groupName
      isPayment
      name
      type
      value
    }
    checkinOut {
      checkinFrom
      checkinTo
      checkoutFrom
      checkoutTo
    }
    canariasComments {
      generalRating
      rateCleaning
      rateDreamQuality
      rateDrinkingSelection
      rateFood
      rateLocation
      rateQualityPriceRelation
      rateRooms
      rateService
      reviewContent
      reviewDate
      title
      tripType
      user {
        name
        place
      }
    }
    tripAdivsorComments {
      numReviews
      rating
      reviewRating1
      reviewRating2
      reviewRating3
      reviewRating4
      reviewRating5
      awards {
        award_type
        category
        image
        name
        year
      }
      breakdown {
        name
        value
      }
      comments {
        date
        helpfulVotes
        publishedDate
        rating
        ratingImageUrl
        text
        title
        tripType
        userLocation
        username
        url
      }
      subrating {
        name
        value
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "hotelId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "language",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "category",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tripType",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating",
  "args": null,
  "storageKey": null
},
v7 = [
  (v1/*: any*/),
  (v3/*: any*/)
],
v8 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hotelDetails",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "hotelId",
        "variableName": "hotelId",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "language",
        "variableName": "language",
        "type": "String!"
      }
    ],
    "concreteType": "HotelDetails",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rateCanarias",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "address",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "chain",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "city",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "country",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "importantInformation",
        "args": null,
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "photos",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rankingCanarias",
        "args": null,
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rateTripAdvisor",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "theme",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "phone",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "latitude",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "longitude",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "facilities",
        "storageKey": null,
        "args": null,
        "concreteType": "Facility",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "group",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "groupName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isPayment",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
          },
          (v3/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "checkinOut",
        "storageKey": null,
        "args": null,
        "concreteType": "CheckinOut",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "checkinFrom",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "checkinTo",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "checkoutFrom",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "checkoutTo",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "canariasComments",
        "storageKey": null,
        "args": null,
        "concreteType": "CanariasComment",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rateRooms",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "generalRating",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rateDreamQuality",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rateDrinkingSelection",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rateFood",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rateLocation",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rateQualityPriceRelation",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rateCleaning",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rateService",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reviewContent",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reviewDate",
            "args": null,
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "CanariasCommentUser",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "place",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "tripAdivsorComments",
        "storageKey": null,
        "args": null,
        "concreteType": "TripAdvisorComments",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reviewRating4",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "numReviews",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reviewRating1",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reviewRating2",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reviewRating3",
            "args": null,
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reviewRating5",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "awards",
            "storageKey": null,
            "args": null,
            "concreteType": "TripAdvisorAwards",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "award_type",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "image",
                "args": null,
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "year",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "breakdown",
            "storageKey": null,
            "args": null,
            "concreteType": "TripAdvisorBreakdown",
            "plural": true,
            "selections": (v7/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "comments",
            "storageKey": null,
            "args": null,
            "concreteType": "TripAdvisorComment",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "text",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "date",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "publishedDate",
                "args": null,
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "ratingImageUrl",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "helpfulVotes",
                "args": null,
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "userLocation",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "username",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "subrating",
            "storageKey": null,
            "args": null,
            "concreteType": "TripAdvisorReviewSubrating",
            "plural": true,
            "selections": (v7/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "HotelDetailsQuery",
    "type": "CanariasQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v8/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "HotelDetailsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v8/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "HotelDetailsQuery",
    "id": null,
    "text": "query HotelDetailsQuery(\n  $hotelId: String!\n  $language: String!\n) {\n  hotelDetails(hotelId: $hotelId, language: $language) {\n    address\n    category\n    chain\n    city\n    country\n    description\n    importantInformation\n    name\n    photos\n    rankingCanarias\n    rateCanarias\n    rateTripAdvisor\n    theme\n    phone\n    latitude\n    longitude\n    facilities {\n      code\n      group\n      groupName\n      isPayment\n      name\n      type\n      value\n    }\n    checkinOut {\n      checkinFrom\n      checkinTo\n      checkoutFrom\n      checkoutTo\n    }\n    canariasComments {\n      generalRating\n      rateCleaning\n      rateDreamQuality\n      rateDrinkingSelection\n      rateFood\n      rateLocation\n      rateQualityPriceRelation\n      rateRooms\n      rateService\n      reviewContent\n      reviewDate\n      title\n      tripType\n      user {\n        name\n        place\n      }\n    }\n    tripAdivsorComments {\n      numReviews\n      rating\n      reviewRating1\n      reviewRating2\n      reviewRating3\n      reviewRating4\n      reviewRating5\n      awards {\n        award_type\n        category\n        image\n        name\n        year\n      }\n      breakdown {\n        name\n        value\n      }\n      comments {\n        date\n        helpfulVotes\n        publishedDate\n        rating\n        ratingImageUrl\n        text\n        title\n        tripType\n        userLocation\n        username\n        url\n      }\n      subrating {\n        name\n        value\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '23cc5b8c2ced7ef9624dee375b54cff6';
module.exports = node;
