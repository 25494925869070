export const resultTypeZone  = "Destinos"
export const resultTypeHotel = "Hoteles"

export const urls = {
    hotelResults: "/hotels-results",
    hotelBlocked: "/hotel-blocked",
    saveSearchFilter: "/ReactReservation/save-search-filter",
    sendResultByEmail: "/ReactReservation/send-results-by-email",
    hotelDetails: hotelUrl => `/hotel/${hotelUrl}`,
    searchByCoordinates:    "/api/availability-by-coordinates",
    getHotelsByCoordinates: "/api/hotels-by-coordinates",
    availabiltyByHotelsId: "/api/availability-by-hotels-id"
}

export const googleMapUrl = "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDGsFaj339wkwoHbhoaoZcO-0kvFxjmuU8"

