import React                                from 'react'
import {FontAwesomeIcon }                   from "@fortawesome/react-fontawesome"
import styles                               from './AvailabilityHotel.module.css'
import styled                               from 'styled-components'
import Category                             from './../Widget/Category'
import 'rc-tooltip/assets/bootstrap_white.css'

import {Hotel, HotelImage, HotelDetails, HotelInfo, HotelName, HotelAddress, HotelFacilities, HotelTop, 
    HotelHeader, HotelDescription} 
    from './../SearchResults/ResultHotel.style'

const HotelImageStyled = styled(HotelImage)`
    @media (max-width: 768px){
        margin-top: 0;
    }
`


const LoadingPrices = styled.div`
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
    color: #999;
`

export default props => {
        const {hotel, className} = props
        const defaultPhoto = (hotel.photos && hotel.photos[0]) || "/hotel-image-not-available.jpg"
        const {description, facilities, name, category, address} = hotel

        return (
            <Hotel className={className}>
                <HotelDetails>                    
                    <HotelImageStyled src={defaultPhoto} />
                    <HotelInfo>
                        <HotelTop>
                            <HotelHeader>                                    
                                <Category value={category}/>  
                                <HotelName href={'#'} target="_blank">{name}</HotelName>                                    
                            </HotelHeader>  
                            <HotelAddress>{address}</HotelAddress>
                            <HotelFacilities facilities={facilities} />                                                                   
                        </HotelTop>                    
                        <HotelDescription>{description}</HotelDescription>
                    </HotelInfo>                    
                </HotelDetails>
                <LoadingPrices>
                <FontAwesomeIcon icon="sync" className={styles.iconSync} />
                </LoadingPrices>
            </Hotel>  
        )
}