/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ResultDeal_deal$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ResultHotel_hotel$ref: FragmentReference;
export type ResultHotel_hotel = {|
  +id: string,
  +name: string,
  +minPrice: any,
  +category: string,
  +ranking: number,
  +description: string,
  +address: string,
  +url: string,
  +photos: ?$ReadOnlyArray<?string>,
  +latitude: string,
  +longitude: string,
  +phone: string,
  +code: string,
  +categoryName: string,
  +facilities: ?$ReadOnlyArray<?{|
    +id: string,
    +className: string,
    +label: string,
  |}>,
  +deals: ?$ReadOnlyArray<?{|
    +$fragmentRefs: ResultDeal_deal$ref
  |}>,
  +packages: ?$ReadOnlyArray<?{|
    +id: string,
    +packageId: string,
    +name: string,
    +distributionId: string,
    +description: string,
    +latitude: number,
    +longitude: number,
    +price: any,
  |}>,
  +$refType: ResultHotel_hotel$ref,
|};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "latitude",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "longitude",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ResultHotel_hotel",
  "type": "AvalabilityResultsHotel",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "photos",
      "args": null,
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "minPrice",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "category",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ranking",
      "args": null,
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "address",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "url",
      "args": null,
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "phone",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "categoryName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "facilities",
      "storageKey": null,
      "args": null,
      "concreteType": "AvailabilityHotelInfoFacility",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "className",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "label",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "deals",
      "storageKey": null,
      "args": null,
      "concreteType": "AvailabilityResultHotelDeal",
      "plural": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ResultDeal_deal",
          "args": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "packages",
      "storageKey": null,
      "args": null,
      "concreteType": "AvailabilityResultHotelPackage",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "packageId",
          "args": null,
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "distributionId",
          "args": null,
          "storageKey": null
        },
        (v1/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "price",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '713b82114445f304d72ea3972982b027';
module.exports = node;
