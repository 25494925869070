import React from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"

const NoResults = styled.div`
	font-size: 1.5em;
	padding: 2em;
	color: #004085;
	background-color: #cce5ff;
	border-color: #b8daff;
	display: block;
	height: 5em;
	margin: 2em auto;
	box-shadow: 0 0 10px #999;
`

const NoHotelResults = ({ t }) => (
	<NoResults>{t("availability.NoResults")}</NoResults>
)

export default withTranslation()(NoHotelResults)
