import React        from 'react'
import styled       from 'styled-components'
import queryString  from 'query-string'

const Pages = styled.div`
    display: flex;
    justify-content: center;
`

const Page = styled.div`
    padding: 15px 0;
    background-color: ${props => props.active ? "#eee" : "#298ed7"};
    color: ${props => props.active ? "inherit" : "#fff"};
    cursor: ${props => props.active ? "inherit" : "pointer"};
    margin: 0 0.5em;
    width: 3em;  
    border-radius: 50%; 
    display: flex;
    align-items: center;
    justify-content: center;     
`

const Arrow = styled(Page)`
    background-color: transparent;
    font-size: 2em;
    font-weight: bold;
    color: inherit;
    font-family: monospace
    padding: 0;
`

export default ({pageSize, totalResults, onChangePage}) => {
    const searchParams      = JSON.parse(queryString.parse(window.location.search).params)
    const actualPage        = searchParams.pageNum
    const numberOfPages     = Math.ceil(totalResults / pageSize)
    const visiblePageDiff   = 3
    const firstPage         = (actualPage - visiblePageDiff) > 1 ? (actualPage - visiblePageDiff) : 1
    const lastPage          = (actualPage + visiblePageDiff) >= numberOfPages            ? (numberOfPages)
                            : (actualPage + visiblePageDiff) < (visiblePageDiff * 2 + 1) ? (actualPage + visiblePageDiff) 
                            : (actualPage + visiblePageDiff) < numberOfPages             ? (actualPage + visiblePageDiff)
                            :                                                              (numberOfPages)
    const visiblePages      = lastPage - firstPage + 1
    const pagesArray        = Array.from(Array(visiblePages).keys()).map(i => firstPage + i)

    const handleChangePage = page => onChangePage(page)

    const renderLeftPage = () => 
        actualPage > 1 
            ? <Arrow key={`first`} onClick={e => handleChangePage(1)} i>{`<<`}</Arrow>
            : null

    const renderRightPage = () => 
        actualPage 
            ? <Arrow key={`last`} onClick={e => handleChangePage(numberOfPages)}>{`>>`}</Arrow>
            : null

    const renderCenterPages = () => 
        !pagesArray || pagesArray.length < 1 
            ? null
            : pagesArray.map(i => <Page 
                                    active={i === actualPage} 
                                    onClick={e => {if(i!== actualPage)handleChangePage(i)}} 
                                    key={i}
                                  >
                                    {i}
                                  </Page>)

    return ( 
        <Pages>
            {renderLeftPage()}            
            {renderCenterPages()}
            {renderRightPage()}
        </Pages>
    )
}