import React                                from 'react'
//import { createFragmentContainer, graphql}  from 'react-relay'
import HotelFacility                        from './HotelFacility'


class HotelFacilities extends React.Component{

    render(){
        return(
            <div className={this.props.className}>
                {this.props.facilities && 
                this.props.facilities.map(facility => 
                    <HotelFacility facility={facility} key={facility.id} />
                )}
            </div>
    )}
}


export default HotelFacilities

