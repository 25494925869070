export default {
    translation: {
        "send_by_email.message_sent_1":                     "Fehler beim Senden der E-Mail",
        "send_by_email.message_sent_0":                     "E-Mail gesendet",
        "send_by_email.email-placeholder":                  "Deine E-Mail",
        "send_by_email.message-placeholder":                "schreibe eine nachricht",
        "send_by_email.title":                              "Senden Sie per E-Mail",
        "send_by_email.button":                             "Senden",        

        "map.open-button":                                  "Siehe Karte",



        "hotel_blocking_error":                             "Fehler beim Erstellen der Reservierung mit diesem Hotel",
        "hotel_blocking_error_button":                      "Buchen Sie Other Hotel",

        "filters.filters":                                  "Filter",
        "resultDeal.totalPriceText":                        "Gesamtpreis",

        "resultDeal.roomTypeTitle":                         "Art des Zimmers",
        "resultDeal.changeRoomTitle":                       "zimmer wechseln",
        "resultDeal.changeRoomsTitle":                      "zimmer wechseln",
        "resultDistribution.roomsQuantity":                 "(__num__ weitere Räume)",
        

        "hotel_blocking.message":                           "Wir erstellen Ihre Reservierung...",

        "hotel_details_not_available":                      "Details nicht verfügbar",
        "distributions.up_to_x_person":                     "Bis zu __num__ personen",

        "hotel_details.description":                        "Beschreibung",
        "hotel_details.map":                                "Karte",
        "hotel_details.reviews":                            "Bewertungen",
        "hotel_details.CheckIn":                            "Ankunft",
        "hotel_details.CheckOut":                           "Verlassen",        
        "hotel_details.FacilitiesTitle":                    "Ausrüstung",        
        "canariasComments.title":                           "Bewertungen Canarias.com",        
        "canariasComments.rateCleaning":                    "Reinigung",
        "canariasComments.rateDreamQuality":                "Schlafqualität",
        "canariasComments.rateDrinkingSelection":           "Auswahl an Getränken",
        "canariasComments.rateFood":                        "Essen",
        "canariasComments.rateLocation":                    "Ort",
        "canariasComments.rateQualityPriceRelation":        "Wert für Geld",
        "canariasComments.rateRooms":                       "Schlafzimmer",
        "canariasComments.rateService":                     "Bedienung",
        "canariasComments.resumeTitle":                     "Ergebnis Canarias.com",
        "canariasComments.numberOfComments":                "Basiert auf __num__ Rezensionen",        
        "canariasComments.fromUser":                        "von",        
        "canariasComments.fromPlace":                       "von",    
        "canariasComments.trip_type_1":                     "Geschäftsreise",                                
        "canariasComments.trip_type_2":                     "Reisen Sie zu zweit",
        "canariasComments.trip_type_3":                     "Familienreisen",
        "canariasComments.trip_type_4":                     "Reisen von Freunden",
        "canariasComments.trip_type_5":                     "Solitärausflug",

        "TripadvisorComments.title":                        "Bewertungen TripAdvisor",        
        "TripadvisorComments.resumeTitle":                  "Bewertungen TripAdvisor",                
        "TripadvisorComments.readMore":                     "Weiterlesen",        
        "TripadvisorComments.NumerOfComments":              "Basiert auf __num__ Rezensionen",        

        "TripadvisorComments.rate_value":                   "Wert für Geld",        
        "TripadvisorComments.rate_cleanliness":             "Reinigung",        
        "TripadvisorComments.rate_sleep":                   "Schlafqualität",                
        "TripadvisorComments.rate_service":                 "Bedienung",        
        "TripadvisorComments.rate_location":                "Ort",        
        "TripadvisorComments.rate_room":                    "Schlafzimmer",        
        
        "TripadvisorComments.TripType":                     "Art der Reise",              
        "TripadvisorComments.NULL":                         "n.d.", 
        "TripadvisorComments.business":                     "Für das Geschäft", 
        "TripadvisorComments.couples":                      "Zu zweit", 
        "TripadvisorComments.family":                       "In der Familie", 
        "TripadvisorComments.friends":                      "Mit Freunden", 
        "TripadvisorComments.solo":                         "Einsam", 


        "RoomNotesModalInfo":                               "Info",
        "search_box.horizontal_label_place":                "Use our Hotel finder to find your perfect hotel",
        "search_box.label_place":                           "Schreiben Sie ein Hotel, Stadt ..",
        "search_box.place_placeholder":                     "Geben Sie Ihr Hotel oder Reiseziel ein",        
        "search_box.label_pickup_date":                     "Eintrittsdatum",
        "search_box.label_dropoff_date":                    "Abreisedatum",
        "search_box.label_room_and_people":                 "Räume und Personen",
        "search_box.search_button_text":                    "Suche",
        "search_box.room_selection.confirm_button_text":    "Bestätige",
        "search_box.room_selection.adult":                  "Erwachsener",
        "search_box.room_selection.adults":                 "Erwachsene",        
        "search_box.room_selection.child":                  "Kind",
        "search_box.room_selection.children":               "Kinder",        
        "search_box.room_selection.childrenAges":           "Alter der Kinder",            
        "search_box.room_selection.room":                   "Zimmer",   
        "search_box.room_selection.rooms":                  "Zimmer",          
        "search_box.place":                                 "Reiseziele",  
        "search_box.hotels":                                "Hotels",  
        "search_box.errors.errors_title":                   "Folgende Fehler sind aufgetreten:", 
        "search_box.errors.select_place":                   "Wählen Sie einen Ort oder ein Hotel", 
        "search_box.errors.wrong_start_date":               "Das Eingabedatum ist ungültig", 
        "search_box.errors.wrong_end_date":                 "Das Abreisedatum ist nicht gültig", 
        "search_box.errors.wrong_distributions":            "Die Verteilung ist ungültig",             
        "filters.change_search":                            "Suche ändern",        
        "filters.main_title":                               "Filtern nach Namen",
        "filters.filter_by_category":                       "Filtern nach Kategorie",
        "filters.filter_by_deal":                           "Filtern nach Regime",
        "filters.filter_by_facilities":                     "Nach Diensten filtern",
        "filters.filter_by_price":                          "Filtern nach Preis",
        "filters.button_filter_text":                       "Filter",
        "filters.button_remove_filter_text":                "Filter aufheben",
        "deal.button_select_text":                          "Auswählen",
        "deal.button_modify_text":                          "Ändern",    
        "deal_selection.button_select_text":                "Auswählen",

        "category.0":		"Nicht verfügbar",
        "category.0L":		"Nicht verfügbar",
        "category.1":		"1",
        "category.10":		"Luxus",
        "category.11":		"Angebot",
        "category.13":		"13",
        "category.1L":		"1L",
        "category.2":		"2",
        "category.20":		"3L",
        "category.23":		"3",
        "category.26":		"26",
        "category.28":		"28",
        "category.29":		"29",
        "category.2L":		"2L",
        "category.3":		"3",
        "category.30":		"30",
        "category.31":		"31",
        "category.32":		"32",
        "category.34":		"34",
        "category.36":		"36",
        "category.3L":		"3L",
        "category.4":		"4",
        "category.40":		"40",
        "category.4L":		"4L",
        "category.5":		"5",
        "category.5L":		"5L",
        "category.8":		"Erstens",
        "category.93":		"93",
        "category.A":		"A",
        "category.AB":		"Unterschlupf",
        "category.AGRO":	"Agroturismo ",
        "category.Ak":		"Touristenwohnung",
        "category.AP":		"Aparthotel",
        "category.AR":		"Ländliche Wohnung",
        "category.AT":		"Wohnung",
        "category.BA":		"Spa",
        "category.BB":		"BB",
        "category.BO":		"Boutique",
        "category.BU":		"Bungalow",
        "category.CA":		"Schloss",
        "category.CAM":		"CAM",
        "category.CB":		"Kabine",
        "category.CC":		"Höhlenhäuser",
        "category.CE":		"Spezielle Kategorie",
        "category.CH":		"Gästehaus",
        "category.CM":		"Ländlicher Komplex",
        "category.CO":		"Cortijo",
        "category.CP":		"Camping",
        "category.CR":		"Landhaus",
        "category.CS":		"Landhaus mit SPA",
        "category.CT":		"Zentrum für ländlichen Tourismus",
        "category.Cz":		"Cz",
        "category.FO":		"Gasthaus",
        "category.H1":		"Herberge *",
        "category.H2":		"Herberge **",
        "category.HO":		"Herberge",
        "category.HP":		"Herberge",
        "category.HR":		"Landhotel",
        "category.HS":		"Hotel Mit SPA",
        "category.HT":		"Hotel",
        "category.IN":		"Nicht verfügbar",
        "category.LO":		"Lodge",
        "category.MH":		"Mini Hotel",
        "category.MO":		"Kloster",
        "category.MT":		"Motel",
        "category.PA":		"Parador",
        "category.PE":		"Rente",
        "category.PENDI":	"Pendi",
        "category.PO":		"Pousada",
        "category.PR":		"Posada Real",
        "category.RA":		"RA",
        "category.RE":		"Wohnimmobilien",
        "category.RS":		"Landhotel der gehobenen Kategorie",
        "category.RT":		"Frühling",
        "category.ST":		"Standard",
        "category.TU":		"Tourist",
        "category.TUS":		"Superior Tourist",
        "category.VI":		"Kleinstadt",
        "category.VV":		"Ferienhäuser",

        "package.Add":      "Eine Erfahrung hinzufügen",
        "package.Selected": "AUSGEWÄHLT",
        "package.Select":   "SELECT",
        "package.Book":     "Buch",

        "availability.NoResults": "Suche erfolglos. Versuchen Sie es erneut.",

        "availability.orderBy.Recommended": "Empfohlen",
        "availability.orderBy.Price": "Preis",
        "availability.orderBy.Category": "Kategorie",
    }
}
