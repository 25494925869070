import React        from 'react'
import styled       from 'styled-components'
import queryString  from 'query-string'
import {withTranslation} from 'react-i18next'

const OrderWrapper = styled.div`
    display: flex;
    justify-content: center;  
    padding: 0 20px 20px 20px;

    @media(max-width: 700px){
        flex-direction: column;
    }
`

const OrderItem = styled.div`
    text-transform: uppercase;
    padding: 10px;
    background-color: ${props => props.active ? "#ddd" : "#298ed7"};
    color: ${props => props.active ? "inherit" : "#fff"};
    cursor: ${props => props.active ? "inherit" : "pointer"};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: ${props => props.active ? "bold" : "inherit"};
    flex: 1; 
    box-shadow:  ${props => props.active ? "inset 0 0 10px #aaa" : "none"};

    &:first-child {
        border-right: 3px solid #ededed;
    }

    &:last-child {
        border-left: 3px solid #ededed;
    }

    @media(max-width: 700px){
        border-right: none !important;
        border-left: none !important;
        margin: 5px 0;
    }
`


const ResultOrder = ({t, onChangeOrderBy}) => {
    const searchParams = JSON.parse(queryString.parse(window.location.search).params)
    const actualOrder  = searchParams.order
    
    const handleChangeOrderBy = orderBy => onChangeOrderBy(orderBy)

    return (
        <OrderWrapper>
            <OrderItem active={actualOrder === "Ranking"} onClick={e => actualOrder === "Ranking" ? null : handleChangeOrderBy("Ranking")}>
                {t('availability.orderBy.Recommended')}
            </OrderItem>
            <OrderItem active={actualOrder === "Price"} onClick={e => actualOrder === "Price" ? null :  handleChangeOrderBy("Price")}>
                {t('availability.orderBy.Price')}
            </OrderItem>
            <OrderItem active={actualOrder === "Category_Asc"} onClick={e => actualOrder === "Category_Asc" ? null :  handleChangeOrderBy("Category_Asc")}>
                {t('availability.orderBy.Category')}
            </OrderItem>
        </OrderWrapper>
    )        
}

export default withTranslation()(ResultOrder)