import React, { useState } from "react"
import { QueryRenderer, graphql } from "react-relay"
import env from "./../Environment"
import { withTranslation } from "react-i18next"
import styled from "styled-components"

import styles from "./HotelBlock.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Element, animateScroll as scroll } from "react-scroll"

//const saveSelectedHotelUrl = '/ReactReservation/add-room-to-session'
const saveSelectedHotelUrl = "/ReactReservation/add-room-to-session"
const confirmAndPayUrl = "/confirmar-y-pagar"

const query = graphql`
	query HotelBlockQuery(
		$details: InputHotelBlockRequestType!
		$channelCode: String!
		$channelKey: String!
	) {
		hotelBlock(
			details: $details
			channelCode: $channelCode
			channelKey: $channelKey
		) {
			hotels {
				id
				sessionId
				roomsCancellationsCosts {
					cancellationsCosts {
						amount
						percent
						date
					}
					roomId
				}
			}
		}
	}
`

const ErrorContainer = styled.div`
	display: flex;
	padding: 20px;
	font-size: 1.2em;
	align-items: center;
	width: 100%;
	justify-content: center;
`

const ErrorButton = styled.div`
	margin-left: 20px;
	cursor: pointer;
	background-color: rgb(92, 184, 92);
	color: #fff;
	padding: 3px 10px;
	border-radius: 3px;
`

const HotelBlock = ({
	t,
	details,
	otherData,
	onResponse,
	channelCode,
	channelKey,
	language
}) => {
	const [scrolledUp, setScrolledUp] = useState(false)

	if (!scrolledUp) {
		setScrolledUp(true)
		scroll.scrollTo("scrollTopElement")
	}

	const handleHotelBlocked = data => {
		if (
			!data.props.hotelBlock ||
			!data.props.hotelBlock.hotels ||
			data.props.hotelBlock.hotels.length < 1 ||
			details.rooms.length < 1
		)
			return renderError()

		for (let i in details.rooms)
			details.rooms[i].sessionId =
				data.props.hotelBlock.hotels[i].sessionId

		for (let i in details.rooms) {
			try {
				const hbCancellationCosts =
					data.props.hotelBlock.hotels[i].roomsCancellationsCosts
				const roomCancellationCosts = hbCancellationCosts.find(
					cc => cc.roomId === details.rooms[i].id
				)
				details.rooms[i].rawCancellationCosts =
					roomCancellationCosts.cancellationsCosts
			} catch (err) {
				console.log(err)
			}
		}

		const buildHotelData = () => {
			const data = new FormData()
			data.append(
				"jsonDistributions",
				JSON.stringify(details.distributions)
			)
			data.append("jsonPackages", JSON.stringify(details.packages))
			data.append("jsonRooms", JSON.stringify(details.rooms))
			data.append("jsonOtherData", JSON.stringify(otherData))
			return data
		}

		const sentData = buildHotelData()

		console.log("details----------------")
		console.log(details)

		fetch(saveSelectedHotelUrl, {
			mode: "cors",
			method: "POST",
			credentials: "include",
			body: sentData
		})
			.then(response => {
				//window.open(confirmAndPayUrl)
				window.location.href = language === "es" ? confirmAndPayUrl : "/" + language + confirmAndPayUrl
			})
			.catch(error => {
				// the header 'Access-Control-Allow-Origin' is present in the response but it says no
				console.log(error)
			})

		return renderBlockingHotel()
	}

	const renderBlockingHotel = () => (
		<div className={styles.loadingMessageContainer}>
			<Element name="scrollTopElement" />
			<FontAwesomeIcon icon="sync" className={styles.iconSync} />
			{t("hotel_blocking.message")}
		</div>
	)

	const renderError = () => (
		<ErrorContainer>
			{t("hotel_blocking_error")}
			<ErrorButton onClick={() => window.location.reload()}>
				{t("hotel_blocking_error_button")}
			</ErrorButton>
		</ErrorContainer>
	)

	const queryVariable = JSON.parse(JSON.stringify(details)) // deep copy
	for (let i in queryVariable.rooms) {
		// no more need it & don´t want to add to server side graphql input data struct
		delete queryVariable.rooms[i].roomName
		delete queryVariable.rooms[i].dealName
		delete queryVariable.rooms[i].rawCancellationCosts
	}

	return (
		<QueryRenderer
			environment={env.environment}
			query={query}
			variables={{ details: queryVariable, channelCode, channelKey }}
			render={data =>
				data.error ? (
					<div>{alert("error")}</div>
				) : !data.props ? (
					renderBlockingHotel()
				) : (
					handleHotelBlocked(data)
				)
			}
		/>
	)
}

export default withTranslation()(HotelBlock)
