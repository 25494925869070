export default {
    translation: {
        "send_by_email.message_sent_1":                     "Erro ao enviar email",
        "send_by_email.message_sent_0":                     "Email enviado",
        "send_by_email.email-placeholder":                  "Seu email",
        "send_by_email.message-placeholder":                "escreva uma mensagem",
        "send_by_email.title":                              "Enviar por email",
        "send_by_email.button":                             "Enviar",        

        "map.open-button":                                  "Ver Mapa",

        "hotel_blocking_error":                             "Erro ao criar a reserva com este hotel",
        "hotel_blocking_error_button":                      "Reservar outro hotel",

        "filters.filters":                                  "Filtrar",
        "resultDeal.totalPriceText":                        "Preço total",

        "resultDeal.roomTypeTitle":                         "tipo de habitação",
        "resultDeal.changeRoomTitle":                       "mudar de quarto",
        "resultDeal.changeRoomsTitle":                      "mudar de quarto",
        "resultDistribution.roomsQuantity":                 "(Mais __num__ quartos)",


        "hotel_blocking.message":                           "Estamos criando sua reserva...",

        "hotel_details_not_available":                      "Detalhes não disponíveis",        
        "distributions.up_to_x_person":                     "Até __num__ pessoas",

        "hotel_details.description":                        "Descrição",
        "hotel_details.map":                                "Mapa",
        "hotel_details.reviews":                            "Rever",
        "hotel_details.CheckIn":                            "Chegada",
        "hotel_details.CheckOut":                           "Sair",        
        "hotel_details.FacilitiesTitle":                    "Equipamento",        
        "canariasComments.title":                           "Avaliações Canarias.com",        
        "canariasComments.rateCleaning":                    "Limpeza",
        "canariasComments.rateDreamQuality":                "Qualidade do sono",
        "canariasComments.rateDrinkingSelection":           "Seleção de bebidas",
        "canariasComments.rateFood":                        "Comida",
        "canariasComments.rateLocation":                    "Localização",
        "canariasComments.rateQualityPriceRelation":        "Valor pelo dinheiro",
        "canariasComments.rateRooms":                       "Quartos de dormir",
        "canariasComments.rateService":                     "Serviço",
        "canariasComments.resumeTitle":                     "Pontuações Canarias.com",
        "canariasComments.numberOfComments":                "Com base em __num__ resenhas",        
        "canariasComments.fromUser":                        "por",        
        "canariasComments.fromPlace":                       "de",    
        "canariasComments.trip_type_1":                     "Viagem de negócios",                                
        "canariasComments.trip_type_2":                     "Viajar em casal",
        "canariasComments.trip_type_3":                     "Viagens em família",
        "canariasComments.trip_type_4":                     "Viagem de amigos",
        "canariasComments.trip_type_5":                     "Viagem de paciência",

        "TripadvisorComments.title":                        "Comentários TripAdvisor",        
        "TripadvisorComments.resumeTitle":                  "Pontuações TripAdvisor",                
        "TripadvisorComments.readMore":                     "Consulte Mais informação",        
        "TripadvisorComments.NumerOfComments":              "Com base em __num__ resenhas",        

        "TripadvisorComments.rate_value":                   "Valor pelo dinheiro",        
        "TripadvisorComments.rate_cleanliness":             "Limpeza",        
        "TripadvisorComments.rate_sleep":                   "Qualidade do sono",                
        "TripadvisorComments.rate_service":                 "Serviço",        
        "TripadvisorComments.rate_location":                "Localização",        
        "TripadvisorComments.rate_room":                    "Quartos de dormir",        
        
        "TripadvisorComments.TripType":                     "Tipo de viagem",              
        "TripadvisorComments.NULL":                         "n.d.", 
        "TripadvisorComments.business":                     "Para negócios", 
        "TripadvisorComments.couples":                      "Em casal", 
        "TripadvisorComments.family":                       "Em família", 
        "TripadvisorComments.friends":                      "Com amigos", 
        "TripadvisorComments.solo":                         "Solitário", 

        "RoomNotesModalInfo":                               "Info",
        "search_box.horizontal_label_place":                "Use our Hotel finder to find your perfect hotel",
        "search_box.label_place":                           "Digite um hotel, cidade ...",
        "search_box.place_placeholder":                     "Digite seu hotel ou destino",
        "search_box.label_pickup_date":                     "Data de entrada",
        "search_box.label_dropoff_date":                    "Departure date",
        "search_box.label_room_and_people":                 "No. de quartos e pessoas",
        "search_box.search_button_text":                    "Procurar",
        "search_box.room_selection.confirm_button_text":    "Confirme",
        "search_box.room_selection.adult":                  "Adulto",        
        "search_box.room_selection.adults":                 "Adultos",
        "search_box.room_selection.child":                  "Criança",        
        "search_box.room_selection.children":               "Crianças",
        "search_box.room_selection.childrenAges":           "Crianças com idades",
        "search_box.room_selection.room":                   "Sala",   
        "search_box.room_selection.rooms":                  "Quartos",          
        "search_box.place":                                 "Lugar",  
        "search_box.hotels":                                "Hotéis",  
        "search_box.errors.errors_title":                   "Existem os seguintes erros:", 
        "search_box.errors.select_place":                   "Selecione um lugar de um hotel", 
        "search_box.errors.wrong_start_date":               "A data de retirada não é válida", 
        "search_box.errors.wrong_end_date":                 "A data de entrega não é válida", 
        "search_box.errors.wrong_distributions":            "A distribuição não é válida",
        "filters.main_title":                               "Filtrar por nome",
        "filters.filter_by_category":                       "Filtrar por Categoria",
        "filters.filter_by_deal":                           "Filtrar por transação",
        "filters.filter_by_facilities":                     "Filtrar por Instalações",
        "filters.filter_by_price":                          "Filtrar por Preço",
        "filters.change_search":                            "Modificar Pesquisa",                                        
        "filters.button_filter_text":                       "Filtro",
        "filters.button_remove_filter_text":                "Remover filtros",
        "deal.button_select_text":                          "Selecione",
        "deal.button_modify_text":                          "Modificar",    
        "deal_selection.button_select_text":                "Selecione",

        "category.0":		"Não disponível",
        "category.0L":		"Não disponível",
        "category.1":		"1",
        "category.10":		"Luxo",
        "category.11":		"Oferta",
        "category.13":		"13",
        "category.1L":		"1L",
        "category.2":		"2",
        "category.20":		"3L",
        "category.23":		"3",
        "category.26":		"26",
        "category.28":		"28",
        "category.29":		"29",
        "category.2L":		"2L",
        "category.3":		"3",
        "category.30":		"30",
        "category.31":		"31",
        "category.32":		"32",
        "category.34":		"34",
        "category.36":		"36",
        "category.3L":		"3L",
        "category.4":		"4",
        "category.40":		"40",
        "category.4L":		"4L",
        "category.5":		"5",
        "category.5L":		"5L",
        "category.8":		"Primeiro",
        "category.93":		"93",
        "category.A":		"A",
        "category.AB":		"Abrigo",
        "category.AGRO":	"Agroturismo",
        "category.Ak":		"Apartamento Uso Turístico",
        "category.AP":		"Aparthotel",
        "category.AR":		"Apartamento Rural",
        "category.AT":		"Apartamento",
        "category.BA":		"Spa",
        "category.BB":		"BB",
        "category.BO":		"Boutique",
        "category.BU":		"Bungalow",
        "category.CA":		"Castle",
        "category.CAM":		"CAM",
        "category.CB":		"Cabine",
        "category.CC":		"Casas da caverna",
        "category.CE":		"Categoria especial",
        "category.CH":		"Casa de Hóspedes",
        "category.CM":		"Complexo Rural",
        "category.CO":		"Cortijo",
        "category.CP":		"Camping",
        "category.CR":		"Casa Rural",
        "category.CS":		"Casa Rural com SPA",
        "category.CT":		"Centro de Turismo Rural",
        "category.Cz":		"Cz",
        "category.FO":		"Fonda",
        "category.H1":		"Hostal *",
        "category.H2":		"Hostal **",
        "category.HO":		"Hostal",
        "category.HP":		"Hospedería",
        "category.HR":		"Hotel Rural",
        "category.HS":		"Hotel Con SPA",
        "category.HT":		"Hotel",
        "category.IN":		"Não disponível",
        "category.LO":		"Lodge",
        "category.MH":		"Mini Hotel",
        "category.MO":		"Monasterio",
        "category.MT":		"Motel",
        "category.PA":		"Parador",
        "category.PE":		"Pensão",
        "category.PENDI":	"Pendi",
        "category.PO":		"Pousada",
        "category.PR":		"Posada Real",
        "category.RA":		"RA",
        "category.RE":		"Residencial",
        "category.RS":		"Categoria Superior Hotel Rural",
        "category.RT":		"Resort",
        "category.ST":		"Standard",
        "category.TU":		"Turista",
        "category.TUS":		"Turista Superior",
        "category.VI":		"Villa",
        "category.VV":		"Casas de Férias",

        "package.Add":      "Adicione uma experiência",
        "package.Selected": "SELECIONADO",
        "package.Select":   "SELECIONAR",
        "package.Book":     "Livro",

        "availability.NoResults": "Não encontramos disponibilidade. Tente de novo, modificando a sua pesquisa.",

        "availability.orderBy.Recommended": "Recomendado",
        "availability.orderBy.Price": "Preço",
        "availability.orderBy.Category": "Categoria",
    }
}
