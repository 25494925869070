/**
 * @flow
 * @relayHash 10972b232ab7f7aeb67901699658ab2f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HotelsFiltersQueryVariables = {|
  token: string,
  language: string,
  channelCode: string,
  channelKey: string,
|};
export type HotelsFiltersQueryResponse = {|
  +filters: ?{|
    +errorMsg: ?string,
    +success: ?boolean,
    +filters: ?{|
      +minPrice: number,
      +maxPrice: number,
      +categories: ?$ReadOnlyArray<?{|
        +code: ?string,
        +count: ?number,
        +group: ?string,
        +name: ?string,
      |}>,
      +deals: ?$ReadOnlyArray<?{|
        +code: ?string,
        +count: ?number,
        +group: ?string,
        +name: ?string,
      |}>,
      +facilities: ?$ReadOnlyArray<?{|
        +code: ?string,
        +count: ?number,
        +group: ?string,
        +name: ?string,
      |}>,
    |},
  |}
|};
export type HotelsFiltersQuery = {|
  variables: HotelsFiltersQueryVariables,
  response: HotelsFiltersQueryResponse,
|};
*/


/*
query HotelsFiltersQuery(
  $token: String!
  $language: String!
  $channelCode: String!
  $channelKey: String!
) {
  filters(token: $token, language: $language, channelCode: $channelCode, channelKey: $channelKey) {
    errorMsg
    success
    filters {
      minPrice
      maxPrice
      categories {
        code
        count
        group
        name
      }
      deals {
        code
        count
        group
        name
      }
      facilities {
        code
        count
        group
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "token",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "language",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "channelCode",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "channelKey",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "code",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "count",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "group",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
],
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "filters",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "channelCode",
        "variableName": "channelCode",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "channelKey",
        "variableName": "channelKey",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "language",
        "variableName": "language",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token",
        "type": "String!"
      }
    ],
    "concreteType": "FiltersResponse",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "errorMsg",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "filters",
        "storageKey": null,
        "args": null,
        "concreteType": "Filters",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "minPrice",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "maxPrice",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "categories",
            "storageKey": null,
            "args": null,
            "concreteType": "FilterCountItem",
            "plural": true,
            "selections": (v1/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "deals",
            "storageKey": null,
            "args": null,
            "concreteType": "FilterCountItem",
            "plural": true,
            "selections": (v1/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "facilities",
            "storageKey": null,
            "args": null,
            "concreteType": "FilterCountItem",
            "plural": true,
            "selections": (v1/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "HotelsFiltersQuery",
    "type": "CanariasQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "HotelsFiltersQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "HotelsFiltersQuery",
    "id": null,
    "text": "query HotelsFiltersQuery(\n  $token: String!\n  $language: String!\n  $channelCode: String!\n  $channelKey: String!\n) {\n  filters(token: $token, language: $language, channelCode: $channelCode, channelKey: $channelKey) {\n    errorMsg\n    success\n    filters {\n      minPrice\n      maxPrice\n      categories {\n        code\n        count\n        group\n        name\n      }\n      deals {\n        code\n        count\n        group\n        name\n      }\n      facilities {\n        code\n        count\n        group\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '224c01822b13d7e3c7914b755efc0489';
module.exports = node;
