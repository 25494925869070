import React from 'react'
import styled from 'styled-components'
import {withTranslation} from 'react-i18next'

const generalRateImage = value => `https://no.tripadvisor.com/img/cdsi/img2/ratings/traveler/${Number(value).toFixed(1)}-28964-5.svg`
const rateImage        = value => `https://static.tacdn.com/img2/ratings/traveler/ss${Number(value).toFixed(1)}.gif`
const commentRateImage = value => `https://www.tripadvisor.es/img/cdsi/img2/ratings/traveler/s${Number(value).toFixed(1)}-28964-5.svg`


const Container = styled.div`
    padding: 20px 100px;   

    @media (max-width: 768px){
        padding: 20px 0; 
    }
`

const Comment = styled.div`
    display: flex;
    :last-child{
        margin-bottom: 30px;
    }

    @media (max-width: 768px){
        flex-direction: column;
    }
`

const CommentLeft = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 768px){
        margin-bottom: 20px;
    }
`

const User = styled.div`
    margin-bottom: 5px;
    color: #555;
`

const CommentDate = styled.div`
    margin-bottom: 5px;
    color: #555;
`

const CommentRate = styled.img`
    margin-bottom: 5px;
`
const CommentTrip = styled.div`
    font-style: italic;
`

const CommentRight = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
`

const CommentTitle = styled.div`
    text-transform: uppercase;
    color: #006699;
    font-weight: bold;
    margin-bottom: 5px;
`

const CommentText = styled.div`
    text-align: justify;
    margin-bottom: 5px;
`

const CommentSeparator = styled.div`
    width: 100%;
    margin: 20px 0;
    border-bottom: 2px dotted #eee;
`

const ReadMore = styled.a`
    text-decoration: none;
    color: #777;
    font-style: italic;
`

const Resume = styled.div`
    display: flex;
    margin-top: 20px;    
    border-bottom: 2px dotted #ccc;
    padding-bottom: 30px;
    margin-bottom: 30px;    

    @media (max-width: 768px){
        flex-direction: column;
    }
` 

const ResumeLeft = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;    
` 
const ResumeRight = styled.div`
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    :after {
        content: '';
        flex: auto;
    }

    @media (max-width: 768px){
        margin-top: 20px;
    }
` 

const ResumeTitle = styled.div`
    font-size: 1.2em;
    margin-bottom: 10px;
` 
const ResumeRate = styled.img`
    margin-bottom: 5px;
    width: 120px;
`

const NumReviews = styled.div`

`

const Awards = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;     
    border-top: 2px dotted #ccc;
    padding-top: 30px;
    margin-top: 30px;     
`

const Award = styled.div`
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const AwardImage = styled.img`
    max-width: 200px;
    max-height: 150px;
`
const AwardName = styled.div`
    margin-bottom: 10px;
    font-weight: bold;
`
const Breakdowns = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-top: 2px dotted #ccc;
    border-bottom: 2px dotted #ccc;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;  
    align-items: center;   
`

const BreakdownTitle = styled.div`
    width: auto;
    text-transform: uppercase;
`


const Breakdown = styled.div`
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const BreakdownValue = styled.div`
    background-color: #2d95ea;
    color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`


const Subrating = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    align-items: flex-start;

    @media (max-width: 768px){
        width: 100%;
        flex-direction: row;
        align-items: center;
    }
`


const SubratingValue = styled.img`
    @media (max-width: 768px){
        margin-top: 3px;
    }
`

const SubratingLabel = styled.div`
    @media (max-width: 768px){        
        width: 80%;
        max-width: 200px;
    }
`

const HotelCommentsTripAdvisor = ({t, comments}) => {

    const renderResume = () => {
        return (
            <>
                <Resume>
                    <ResumeLeft>
                        <ResumeTitle>{t("TripadvisorComments.resumeTitle")}</ResumeTitle>
                        <ResumeRate src={generalRateImage(comments && comments.rating)} />
                        { comments && <NumReviews>{t("TripadvisorComments.NumerOfComments").replace("__num__", comments.numReviews)}</NumReviews>}
                    </ResumeLeft>
                    <ResumeRight>
                        {comments && comments.subrating &&
                        comments.subrating.map((subr, index) => 
                                <Subrating key={index}>                                    
                                        <SubratingLabel>{t(`TripadvisorComments.${subr.name}`)}</SubratingLabel>
                                        <SubratingValue src={rateImage(subr.value)} />
                                </Subrating>)
                        }                    
                    </ResumeRight>
                </Resume>
                {/* <Breakdowns>
                    {comments && comments.breakdown &&
                        <>
                            <BreakdownTitle>{t("TripadvisorComments.TripType")}: </BreakdownTitle>
                            {comments.breakdown.map((br, index) => 
                                <Breakdown key={index}> 
                                    <BreakdownValue>{br.value}</BreakdownValue>
                                    {t(`TripadvisorComments.${br.name}`)}
                                </Breakdown>)}
                        </>
                    }
                </Breakdowns> */}
            </>
        )
    }
    


    const renderAwards = () => {
        return (
            <Awards>
                {comments && comments.awards &&
                comments.awards.map((aw, index) => 
                    <Award key={index}>
                            <AwardName>{aw.name} {aw.year}</AwardName>
                            <AwardImage src={aw.image} />                            
                    </Award>)
                }
            </Awards>
        )   
    }            

   

    const renderComment = (comment, key) => {
        return (
            <>
                <Comment key={key}>
                    <CommentLeft>
                        <User>{comment.username} - {comment.userLocation}</User>
                        <CommentDate>{comment.publishedDate}</CommentDate>
                        <CommentRate src={commentRateImage(comment.rating)} />
                        <CommentTrip>{t(`TripadvisorComments.${comment.tripType}`)} - {comment.date}</CommentTrip>
                    </CommentLeft>
                    <CommentRight>
                        <CommentTitle>{comment.title}</CommentTitle>
                        <CommentText>{comment.text}</CommentText>
                        <ReadMore target="_blank" href={comment.url}>{t("TripadvisorComments.readMore")}</ReadMore>
                    </CommentRight>                
                </Comment>
                {key < (comments.comments.length - 1) && <CommentSeparator />}
            </>
        )
    }

    
    return comments && (
        <Container>
            {renderResume()}
            {comments && 
             comments.comments && 
             comments.comments.map((comment,index) => renderComment(comment, index))
            }
            {renderAwards()}                        
        </Container>
    )
}


export default withTranslation()(HotelCommentsTripAdvisor)