export default {
    translation: {
        "send_by_email.message_sent_1":                     "Erreur d'envoi du courrier électronique",
        "send_by_email.message_sent_0":                     "Email envoyé",
        "send_by_email.email-placeholder":                  "Votre email",
        "send_by_email.message-placeholder":                "écrire un message",
        "send_by_email.title":                              "Envoyer par mail",
        "send_by_email.button":                             "Envoyer",        

        "map.open-button":                                  "Voir la carte",

        "hotel_blocking_error":                             "Erreur lors de la création de la réservation avec cet hôtel",
        "hotel_blocking_error_button":                      "Réserver un autre hôtel",

        "filters.filters":                                  "Filtrer",
        "resultDeal.totalPriceText":                        "Prix total",

        "resultDeal.roomTypeTitle":                         "type de chambre",
        "resultDeal.changeRoomTitle":                       "change de chambre",
        "resultDeal.changeRoomsTitle":                      "change de chambre",
        "resultDistribution.roomsQuantity":                 "(__num__ chambres plus)",


        "hotel_blocking.message":                           "Nous créons votre réservation...",

        "hotel_details_not_available":                      "Détails non disponibles",        
        "distributions.up_to_x_person":                     "Jusqu'à __num__ personnes",

        "hotel_details.description":                        "La description",
        "hotel_details.map":                                "Carte",
        "hotel_details.reviews":                            "Avis",
        "hotel_details.CheckIn":                            "Arrivée",
        "hotel_details.CheckOut":                           "Quitter",        
        "hotel_details.FacilitiesTitle":                    "Équipement",        
        "canariasComments.title":                           "Avis Canarias.com",        
        "canariasComments.rateCleaning":                    "Nettoyage",
        "canariasComments.rateDreamQuality":                "Qualité du sommeil",
        "canariasComments.rateDrinkingSelection":           "Sélection de boissons",
        "canariasComments.rateFood":                        "Aliments",
        "canariasComments.rateLocation":                    "Emplacement",
        "canariasComments.rateQualityPriceRelation":        "Le rapport qualité prix",
        "canariasComments.rateRooms":                       "Chambres à coucher",
        "canariasComments.rateService":                     "Un service",
        "canariasComments.resumeTitle":                     "Partitions Canarias.com",
        "canariasComments.numberOfComments":                "Basé sur __num__ avis",        
        "canariasComments.fromUser":                        "de",        
        "canariasComments.fromPlace":                       "de",    
        "canariasComments.trip_type_1":                     "Voyage d'affaires",                                
        "canariasComments.trip_type_2":                     "Voyage en couple",
        "canariasComments.trip_type_3":                     "Voyage en famille",
        "canariasComments.trip_type_4":                     "Voyage d'amis",
        "canariasComments.trip_type_5":                     "Voyage de solitaire",

        "TripadvisorComments.title":                        "Commentaires TripAdvisor",        
        "TripadvisorComments.resumeTitle":                  "Scores TripAdvisor",                
        "TripadvisorComments.readMore":                     "Lire la suite",        
        "TripadvisorComments.NumerOfComments":              "Basé sur __num__ avis",        

        "TripadvisorComments.rate_value":                   "Le rapport qualité prix",        
        "TripadvisorComments.rate_cleanliness":             "Nettoyage",        
        "TripadvisorComments.rate_sleep":                   "Qualité du sommeil",                
        "TripadvisorComments.rate_service":                 "Un service",        
        "TripadvisorComments.rate_location":                "Emplacement",        
        "TripadvisorComments.rate_room":                    "Chambres à coucher",        
        
        "TripadvisorComments.TripType":                     "Type de voyage",              
        "TripadvisorComments.NULL":                         "n.d.", 
        "TripadvisorComments.business":                     "Pour le business", 
        "TripadvisorComments.couples":                      "En couple", 
        "TripadvisorComments.family":                       "Dans la famille", 
        "TripadvisorComments.friends":                      "With friends", 
        "TripadvisorComments.solo":                         "Solitaire", 


        "RoomNotesModalInfo":                               "Info",
        "search_box.horizontal_label_place":                "Use our Hotel finder to find your perfect hotel",
        "search_box.label_place":                           "Tapez un hôtel, une ville...",
        "search_box.place_placeholder":                     "Tapez votre hôtel ou votre destination",
        "search_box.label_pickup_date":                     "Date d'entrée",
        "search_box.label_dropoff_date":                    "Date de départ",
        "search_box.label_room_and_people":                 "Chambres et personnes",
        "search_box.search_button_text":                    "Chercher",
        "search_box.room_selection.confirm_button_text":    "Confirmer",
        "search_box.room_selection.adult":                  "Adulte",        
        "search_box.room_selection.adults":                 "Adultes",
        "search_box.room_selection.child":                  "Enfant",        
        "search_box.room_selection.children":               "Les enfants",
        "search_box.room_selection.childrenAges":           "Âges des enfants",
        "search_box.room_selection.room":                   "Pièce",   
        "search_box.room_selection.rooms":                  "Pièces",          
        "search_box.place":                                 "Endroit",  
        "search_box.hotels":                                "Hôtels",  
        "search_box.errors.errors_title":                   "Il y a les erreurs suivantes:", 
        "search_box.errors.select_place":                   "Sélectionnez un lieu ou un hôtel", 
        "search_box.errors.wrong_start_date":               "La date de prise en charge n'est pas valide", 
        "search_box.errors.wrong_end_date":                 "La date de restitution n'est pas valide", 
        "search_box.errors.wrong_distributions":            "La distribution n'est pas valide",
        "filters.change_search":                            "Modifier la Recherche",                
        "filters.main_title":                               "Filtrer par nom",
        "filters.filter_by_category":                       "Filtrer par catégorie",
        "filters.filter_by_deal":                           "Filtrer par Deal",
        "filters.filter_by_facilities":                     "Filtrer par installations",
        "filters.filter_by_price":                          "Filtrer par prix",
        "filters.button_filter_text":                       "Filtre",
        "filters.button_remove_filter_text":                "Quitter les filtres",
        "deal.button_select_text":                          "Sélectionner",
        "deal.button_modify_text":                          "Modifier",    
        "deal_selection.button_select_text":                "Sélectionner",
        "category.0":		"Non disponible",
        "category.0L":		"Non disponible",
        "category.1":		"1",
        "category.10":		"De luxe",
        "category.11":		"Offre",
        "category.13":		"13",
        "category.1L":		"1L",
        "category.2":		"2",
        "category.20":		"3L",
        "category.23":		"3",
        "category.26":		"26",
        "category.28":		"28",
        "category.29":		"29",
        "category.2L":		"2L",
        "category.3":		"3",
        "category.30":		"30",
        "category.31":		"31",
        "category.32":		"32",
        "category.34":		"34",
        "category.36":		"36",
        "category.3L":		"3L",
        "category.4":		"4",
        "category.40":		"40",
        "category.4L":		"4L",
        "category.5":		"5",
        "category.5L":		"5L",
        "category.8":		"Premier",
        "category.93":		"93",
        "category.A":		"A",
        "category.AB":		"Refuge",
        "category.AGRO":	"Agrotourisme",
        "category.Ak":		"Appartement touristique",
        "category.AP":		"Aparthotel",
        "category.AR":		"Appartement rural",
        "category.AT":		"Appartement",
        "category.BA":		"Spa",
        "category.BB":		"BB",
        "category.BO":		"Boutique",
        "category.BU":		"Bungalow",
        "category.CA":		"Château",
        "category.CAM":		"CAM",
        "category.CB":		"Cabine",
        "category.CC":		"Maisons Troglodytes",
        "category.CE":		"Catégorie spéciale",
        "category.CH":		"Maison d'hôtes",
        "category.CM":		"Complexe rural",
        "category.CO":		"Cortijo",
        "category.CP":		"Camping",
        "category.CR":		"Maison rurale",
        "category.CS":		"Gîte rural avec SPA",
        "category.CT":		"Centre de tourisme rural",
        "category.Cz":		"Cz",
        "category.FO":		"Fonda",
        "category.H1":		"Hostal *",
        "category.H2":		"Hostal **",
        "category.HO":		"Hostal",
        "category.HP":		"Hospedería",
        "category.HR":		"Hôtel rural",
        "category.HS":		"Hôtel avec SPA",
        "category.HT":		"Hôtel",
        "category.IN":		"Non disponible",
        "category.LO":		"Lodge",
        "category.MH":		"Mini Hôtel",
        "category.MO":		"Monastère",
        "category.MT":		"Motel",
        "category.PA":		"Parador",
        "category.PE":		"Pension",
        "category.PENDI":	"Pendi",
        "category.PO":		"Pousada",
        "category.PR":		"Posada Real",
        "category.RA":		"RA",
        "category.RE":		"Résidentiel",
        "category.RS":		"Catégorie Hôtel Rural Supérieur",
        "category.RT":		"Resort",
        "category.ST":		"Standard",
        "category.TU":		"Touriste",
        "category.TUS":		"Touriste Supérieur",
        "category.VI":		"Villa",
        "category.VV":		"Maisons de vacances",

        "package.Add":      "Ajouter une expérience",
        "package.Selected": "SÉLECTIONNÉ",
        "package.Select":   "SELECT",
        "package.Book":     "Livre",

        "availability.NoResults": "Nous n'avons pas trouvé de disponibilité. Retentez à nouveau, en modifiant votre recherche.",

        "availability.orderBy.Recommended": "Recommandé",
        "availability.orderBy.Price": "Prix",
        "availability.orderBy.Category": "Catégorie",
    }
}