export const defaultLanguage = "es"
export const othersLanguages = ["en", "de", "fr", "it", "nl", "pt", "ru"] // without default language (ES)

export const getLanguageFromUrl = () => {
    const path = window.location.pathname

    for(let l in othersLanguages){
        if(path.startsWith(`/${othersLanguages[l]}/`) || path === `/${othersLanguages[l]}`)
            return othersLanguages[l]
    }

    return defaultLanguage    
}
