const joinratesHotelBlock = 'https://hotelsapi.canarias.com/api/hotelblock'

class BookingHelper{
   

    groupBookingUrls = urls => {
      const roomParamName = 'nr_rooms_'

      const getUrlWithoutRooms = url =>{
          const urlParts              = url.split('?')
          const parameters            = urlParts[1].split('&')
          const parametersWithoutRoom = parameters.filter(p => p.indexOf(roomParamName) === -1)

          return `${urlParts[0]}?` + parametersWithoutRoom.join('&')
      }

      const roomsId = urls.map(url => {
                                        const  urlParts   = url.split('?')
                                        const  parameters = urlParts[1].split('&')
                                        return parameters.filter(p => p.indexOf(roomParamName) === 0)
                                      }).flatMap(i => i)

      const uniqueRoomsId = []
      for(let i in roomsId){
          const existingRoomId = uniqueRoomsId.find(r => roomsId[i].indexOf(r.id) >= 0)
          if(existingRoomId) existingRoomId.quantity = existingRoomId.quantity + 1
          else uniqueRoomsId.push({
                                      id:       roomsId[i].split('=')[0], 
                                      quantity: 1
                                  })
      }

      const urlWithoutRooms = getUrlWithoutRooms(urls[0])
      const roomsUrlParts   = uniqueRoomsId.map(r => `&${r.id}=${r.quantity}`)
      const roomsUrlPart    = roomsUrlParts.join('')

      return `${urlWithoutRooms}${roomsUrlPart}`
    }

    isBookingHotel = hotelId => hotelId && hotelId.startsWith('B-')

    isJoinratesHotel = hotelId => hotelId && !hotelId.startsWith('B-')

    buildBookingHotelUrl = selections => {
        const roomIds = selections.reduce((ids, selection) => {
                                                const existingId = ids.filter(i => i.roomId === selection.roomId)
                                                existingId.length === 1 ? existingId[0].value++ : ids.push({roomId: selection.roomId, value: 1})
                                                return ids
                                            }, [])    

        const firstUrl       = selections[0].bookingLink
        const start_rn_rooms = firstUrl.indexOf('&nr_rooms_')
        const end_nr_rooms   = firstUrl.indexOf('&', start_rn_rooms + 10)
                
        const baseUrl = end_nr_rooms < 1 ? firstUrl.substring(0, start_rn_rooms)
                            : firstUrl.substring(0, start_rn_rooms) + firstUrl.substring(end_nr_rooms)

        return roomIds.reduce((url, id) => `${url}&nr_rooms_${id.roomId}=${id.value}`, baseUrl)      
    }

    doJoinratesHotelBlock = (selections, callback) => {
        const params = {
            "rooms": [
              {
                "id": "string",
                "hotelId": "string",
                "initDate": "2019-03-11T12:21:49.002Z",
                "endDate": "2019-03-11T12:21:49.002Z",
                "code": "string",
                "dealCode": "string",
                "price": 0,
                "isPVP": true,
                "directPayment": true,
                "canaryResident": true,
                "distributionId": 0
              }
            ],
            "packages": [
              {
                "packageId": 0,
                "distributionId": 0,
                "initDate": "2019-03-11T12:21:49.002Z",
                "endDate": "2019-03-11T12:21:49.002Z"
              }
            ],
            "distributions": [
              {
                "id": 0,
                "adults": 0,
                "childrenAges": [
                  0
                ]
              }
            ],
            "channelCode": "string",
            "channelKey": "string"
          }





        fetch(joinratesHotelBlock,{
            methos: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
        .then(response => response.json())
        .then(json => callback(json))
    }
}


export default new BookingHelper()