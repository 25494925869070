import React    from 'react'
import styles   from './HotelFacility.module.css'
import Tooltip  from 'rc-tooltip'

import 'rc-tooltip/assets/bootstrap_white.css'
import './hotel-facilities.css'

export default props => 
    <Tooltip 
        placement="bottom" 
        overlay={<div className={styles.facilityTooltip}>{props.facility.label}</div>} 
        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
    >
        <div key={props.facility.id} className={` hotel-icons ${props.facility.className} ${styles.hotelFacility}`}></div>
    </Tooltip>