import React, {useState}        from 'react'
import styled               from 'styled-components'
import Modal                from 'react-responsive-modal'
import {withTranslation}    from 'react-i18next'

import "react-tabs/style/react-tabs.css"
import HotelDetails from './HotelDetails'

import styles from './HotelDetails.module.css'

const HotelName = styled.div`
    color: #fff;
    font-size: 1.4em;
    letter-spacing: 1px;
    cursor: pointer;
    text-transform: uppercase;

    @media (max-width: 768px){
        font-size: 1em;
        text-align: center;
    }
`

// mostramos los detalles del hotel en una Modal sin cambiar la pàgina y dejando los resultados en segundo plano

const HotelDetailsPopup = ({t, hotelId, text}) => {
    const [isOpen, setIsOpen]  = useState(false)

    return (
        <>
            <HotelName onClick={() => setIsOpen(true)}>{text}</HotelName>
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                classNames={{
                    modal: styles.root, 
                    closeButton: styles.closeButton
                }}
            >
                <HotelDetails hotelId={hotelId} />
            </Modal>       
        </>
    )
   
}

export default withTranslation()(HotelDetailsPopup)