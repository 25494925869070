/**
 * @flow
 * @relayHash 9fe08d0053a9f6f511206e49a17b283c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TempHotelResultsQueryVariables = {|
  zoneCode: string,
  hotelId: string,
  language: string,
  pageSize?: ?number,
  pageNum?: ?number,
  order?: ?number,
|};
export type TempHotelResultsQueryResponse = {|
  +hotelsByZone: ?{|
    +name: string,
    +hotels: ?$ReadOnlyArray<?{|
      +_id: string,
      +name: string,
      +address: string,
      +category: number,
      +description: string,
      +photos: $ReadOnlyArray<string>,
      +rankingCanarias: number,
      +rateCanarias: number,
      +rateTripAdvisor: number,
    |}>,
  |}
|};
export type TempHotelResultsQuery = {|
  variables: TempHotelResultsQueryVariables,
  response: TempHotelResultsQueryResponse,
|};
*/


/*
query TempHotelResultsQuery(
  $zoneCode: String!
  $hotelId: String!
  $language: String!
  $pageSize: Int
  $pageNum: Int
  $order: Int
) {
  hotelsByZone(zoneCode: $zoneCode, hotelId: $hotelId, language: $language, pageSize: $pageSize, pageNum: $pageNum, order: $order) {
    name
    hotels {
      _id
      name
      address
      category
      description
      photos
      rankingCanarias
      rateCanarias
      rateTripAdvisor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "zoneCode",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "hotelId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "language",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "pageSize",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "pageNum",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "order",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hotelsByZone",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "hotelId",
        "variableName": "hotelId",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "language",
        "variableName": "language",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "pageNum",
        "variableName": "pageNum",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "pageSize",
        "variableName": "pageSize",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "zoneCode",
        "variableName": "zoneCode",
        "type": "String!"
      }
    ],
    "concreteType": "HotelsByZone",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "hotels",
        "storageKey": null,
        "args": null,
        "concreteType": "HotelsByZoneItem",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "_id",
            "args": null,
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "address",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "category",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "photos",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rankingCanarias",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rateCanarias",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rateTripAdvisor",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TempHotelResultsQuery",
    "type": "CanariasQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "TempHotelResultsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "TempHotelResultsQuery",
    "id": null,
    "text": "query TempHotelResultsQuery(\n  $zoneCode: String!\n  $hotelId: String!\n  $language: String!\n  $pageSize: Int\n  $pageNum: Int\n  $order: Int\n) {\n  hotelsByZone(zoneCode: $zoneCode, hotelId: $hotelId, language: $language, pageSize: $pageSize, pageNum: $pageNum, order: $order) {\n    name\n    hotels {\n      _id\n      name\n      address\n      category\n      description\n      photos\n      rankingCanarias\n      rateCanarias\n      rateTripAdvisor\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc92597d30036243acf98a2c0d222610';
module.exports = node;
