import {Environment, Network, RecordSource, Store, } from 'relay-runtime'


//const __RELAY_API_ENDPOINT__ = 'http://localhost:5000/graphql'
const __RELAY_API_ENDPOINT__ = process.env.RELAY_API_ENDPOINT//'https://hotelsdataapi.canarias.com/graphql'
//console.log(process.env);

function fetchQuery(operation, variables)
{
    return fetch(__RELAY_API_ENDPOINT__, {
        method: 'POST',
        headers: {
		  'content-type': 'application/json'
		},
        body: JSON.stringify({ 
                                query: operation.text, 
                                variables 
                             }),
    }).then(response => { return response.json() })
}

const environment = new Environment({ network: Network.create(fetchQuery), store: new Store(new RecordSource())})

export default {environment, __RELAY_API_ENDPOINT__, fetchQuery}