/**
 * @flow
 * @relayHash 35bff130907f23dd30945d0a4c1547fd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TempHotelByCodesResultsQueryVariables = {|
  hotelId: string,
  language: string,
  pageSize?: ?number,
  pageNum?: ?number,
  order?: ?number,
|};
export type TempHotelByCodesResultsQueryResponse = {|
  +hotelsByCodes: ?$ReadOnlyArray<?{|
    +_id: string,
    +name: string,
    +address: string,
    +category: number,
    +description: string,
    +photos: $ReadOnlyArray<string>,
    +rankingCanarias: number,
    +rateCanarias: number,
    +rateTripAdvisor: number,
  |}>
|};
export type TempHotelByCodesResultsQuery = {|
  variables: TempHotelByCodesResultsQueryVariables,
  response: TempHotelByCodesResultsQueryResponse,
|};
*/


/*
query TempHotelByCodesResultsQuery(
  $hotelId: String!
  $language: String!
  $pageSize: Int
  $pageNum: Int
  $order: Int
) {
  hotelsByCodes(hotelId: $hotelId, language: $language, pageSize: $pageSize, pageNum: $pageNum, order: $order) {
    _id
    name
    address
    category
    description
    photos
    rankingCanarias
    rateCanarias
    rateTripAdvisor
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "hotelId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "language",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "pageSize",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "pageNum",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "order",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "hotelsByCodes",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "hotelId",
        "variableName": "hotelId",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "language",
        "variableName": "language",
        "type": "String!"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "pageNum",
        "variableName": "pageNum",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "pageSize",
        "variableName": "pageSize",
        "type": "Int"
      }
    ],
    "concreteType": "HotelsByZoneItem",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "_id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "address",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "category",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "description",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "photos",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rankingCanarias",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rateCanarias",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rateTripAdvisor",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "TempHotelByCodesResultsQuery",
    "type": "CanariasQuery",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "TempHotelByCodesResultsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "query",
    "name": "TempHotelByCodesResultsQuery",
    "id": null,
    "text": "query TempHotelByCodesResultsQuery(\n  $hotelId: String!\n  $language: String!\n  $pageSize: Int\n  $pageNum: Int\n  $order: Int\n) {\n  hotelsByCodes(hotelId: $hotelId, language: $language, pageSize: $pageSize, pageNum: $pageNum, order: $order) {\n    _id\n    name\n    address\n    category\n    description\n    photos\n    rankingCanarias\n    rateCanarias\n    rateTripAdvisor\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '347fc3051139e9b2582338acd2802bda';
module.exports = node;
