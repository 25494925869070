import React          from 'react'
import ReactDOM       from 'react-dom'
import BookingProcess from './components/BookingProcess'


const bpDom        = document.getElementById('booking-process')
const searchParams = {
    titleText         : bpDom.getAttribute('data-title-text'),
    zoneId            : bpDom.getAttribute('data-zone-id'),
    zoneName          : bpDom.getAttribute('data-zone-name'),
    hotelId           : bpDom.getAttribute('data-hotel-id'),
    initDate          : bpDom.getAttribute('data-init-date'),
    endDate           : bpDom.getAttribute('data-end-date'),
    dateFormat        : bpDom.getAttribute('data-date-format'),
    distributionsJson : bpDom.getAttribute('data-distributions-json'),
    subfilter         : bpDom.getAttribute('data-subfilter'),
    horizontal        : bpDom.hasAttributes('data-horizontal') ? bpDom.getAttribute('data-horizontal') : "false",
    channelCode       : bpDom.getAttribute('data-channel-code'),
    channelKey        : bpDom.getAttribute('data-channel-key'),
}

ReactDOM.render(<BookingProcess searchParams={searchParams} />,  bpDom)
