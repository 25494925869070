import React, { useState } from "react"
import { createFragmentContainer, graphql } from "react-relay"
import Lightbox from "react-image-lightbox"
import Category from "./../Widget/Category"
import HotelDeal from "./ResultDeal"
import HotelDetailsPopup from "./../HotelDetails/HotelDetailsPopup"
import responsiveHelper from "../../utility/responsiveHelper"

import {
	Hotel,
	HotelImage,
	HotelHiddenImage,
	HotelDetails,
	HotelInfo,
	HotelName,
	HotelAddress,
	HotelFacilities,
	HotelTop,
	HotelHeader,
	HotelDescription,
	HotelDeals
} from "./ResultHotel.style"

import { urls } from "./../../utility/constants"

const ResultHotel = ({
	className,
	onSelectRooms,
	hotel,
	showLoading,
	onLoaded,
	realDistributionNumber
}) => {
	const [photoIndex, setPhotoIndex] = useState(0)
	const [photoLightboxIsOpen, setPhotoLightboxIsOpen] = useState(false)

	const {
		category,
		categoryName,
		url,
		name,
		address,
		facilities,
		description,
		deals,
		packages,
		photos,
		id
	} = hotel

	const defaultPhoto =
		(photos && photos[0]) ||
		"/content/react/images/hotel-image-not-available.jpg"
	//const defaultPhoto = (photos && photos[0]) || "http://images.xtravelsystem.com/slide/files/public/950/0/1/9/Images/img_1550873713777.jpg"

	const handleOnSelectRooms = (selection, otherData = {}) => {
		for (let i in selection.rooms) selection.rooms[i].hotelId = id

		otherData.hotelLatitude = hotel.latitude
		otherData.hotelLongitude = hotel.longitude
		otherData.hotelPhone = hotel.phone
		otherData.hotelCode = hotel.code
		otherData.hotelCategoryName = hotel.categoryName
		otherData.hotelAddress = hotel.address
		otherData.hotelPhone = hotel.phone
		otherData.hotelCode = hotel.code
		otherData.hotelId = hotel.id
		otherData.hotelName = hotel.name
		otherData.hotelCategoryName = hotel.categoryName
		otherData.hotelImage = defaultPhoto

		onSelectRooms(selection, otherData)
	}

	const renderImagesLightbox = () => {
		const changePhotoIndex = diff =>
			setPhotoIndex((photoIndex + photos.length + diff) % photos.length)

		return (
			photoLightboxIsOpen &&
			photos &&
			photos.length > 1 && (
				<Lightbox
					mainSrc={photos[photoIndex]}
					nextSrc={photos[(photoIndex + 1) % photos.length]}
					prevSrc={
						photos[(photoIndex + photos.length - 1) % photos.length]
					}
					onCloseRequest={() => setPhotoLightboxIsOpen(false)}
					onMovePrevRequest={() => changePhotoIndex(-1)}
					onMoveNextRequest={() => changePhotoIndex(1)}
				/>
			)
		)
	}

	const renderHotelDektop = () => (
		<Hotel className={className}>
			<HotelDetails>
				<HotelImage
					src={defaultPhoto}
					onClick={() => setPhotoLightboxIsOpen(true)}
				/>
				<HotelHiddenImage src={defaultPhoto} onLoad={onLoaded} />
				<HotelInfo>
					<HotelTop>
						<HotelHeader>
							<Category value={category} type={categoryName} />
							<HotelDetailsPopup
								hotelId={hotel.id}
								text={hotel.name}
							/>
						</HotelHeader>
						<HotelAddress>{address}</HotelAddress>
						<HotelFacilities facilities={facilities} />
					</HotelTop>
					<HotelDescription>{description}</HotelDescription>
				</HotelInfo>
				{renderImagesLightbox()}
			</HotelDetails>
			<HotelDeals>
				{deals.map((deal, index) => (
					<HotelDeal //VIC123 Show notes
						deal={deal}
						key={index}
						onSelectRooms={handleOnSelectRooms}
						packages={packages}
						showLoading={showLoading}
						realDistributionNumber={realDistributionNumber}
					/>
				))}
			</HotelDeals>
		</Hotel>
	)

	const renderHoteltablet = () => (
		<Hotel className={className}>
			<HotelDetails>
				<HotelImage
					src={defaultPhoto}
					onClick={() => setPhotoLightboxIsOpen(true)}
				/>
				<HotelHiddenImage src={defaultPhoto} onLoad={onLoaded} />
				<HotelInfo>
					<HotelTop>
						<HotelHeader>
							<Category value={category} type={categoryName} />
							<HotelDetailsPopup
								hotelId={hotel.id}
								text={hotel.name}
							/>
						</HotelHeader>
						<HotelAddress>{address}</HotelAddress>
						<HotelFacilities facilities={facilities} />
					</HotelTop>
					<HotelDescription>{description}</HotelDescription>
				</HotelInfo>
				{renderImagesLightbox()}
			</HotelDetails>
			<HotelDeals>
				{deals.map((deal, index) => (
					<HotelDeal
						deal={deal}
						key={index}
						onSelectRooms={handleOnSelectRooms}
						packages={packages}
						showLoading={showLoading}
						realDistributionNumber={realDistributionNumber}
					/>
				))}
			</HotelDeals>
		</Hotel>
	)

	const renderHotelMobile = () => (
		<Hotel className={className}>
			<HotelDetails>
				<HotelHeader>
					<HotelDetailsPopup hotelId={hotel.id} text={hotel.name} />
					<Category value={category} type={categoryName} />
				</HotelHeader>
				<HotelImage
					src={defaultPhoto}
					onClick={() => setPhotoLightboxIsOpen(true)}
				/>
				<HotelHiddenImage src={defaultPhoto} onLoad={onLoaded} />
				<HotelInfo>
					<HotelTop>
						<HotelAddress>{address}</HotelAddress>
						<HotelFacilities facilities={facilities} />
					</HotelTop>
					<HotelDescription>{description}</HotelDescription>
				</HotelInfo>
				{renderImagesLightbox()}
			</HotelDetails>
			<HotelDeals>
				{deals.map((deal, index) => (
					<HotelDeal
						deal={deal}
						key={index}
						onSelectRooms={handleOnSelectRooms}
						packages={packages}
						showLoading={showLoading}
						realDistributionNumber={realDistributionNumber}
					/>
				))}
			</HotelDeals>
		</Hotel>
	)

	return (
		<>
			{responsiveHelper.isDesktop() && renderHotelDektop()}
			{responsiveHelper.isTablet() && renderHoteltablet()}
			{responsiveHelper.isMobile() && renderHotelMobile()}
		</>
	)
}

export default createFragmentContainer(
	ResultHotel,
	graphql`
		fragment ResultHotel_hotel on AvalabilityResultsHotel {
			id
			name
			minPrice
			category
			ranking
			description
			address
			url
			photos
			latitude
			longitude
			phone
			code
			categoryName
			facilities {
				id
				className
				label
			}
			deals {
				...ResultDeal_deal
			}
			packages {
				id
				packageId
				name
				distributionId
				description
				latitude
				longitude
				price
			}
		}
	`
)
