import React, {useState}    from 'react'
import queryString          from 'query-string'
import styled               from 'styled-components'
import {withTranslation}    from 'react-i18next'
import styles               from "./SendResultsByEmail.module.css"
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome'
import SearchUtility        from '../utility/SearchUtility'

import  {urls}  from './../utility/constants'

import {
    Accordion, 
    AccordionItem, 
    AccordionItemHeading, 
    AccordionItemButton, 
    AccordionItemPanel
} from 'react-accessible-accordion'

const Container = styled.div`
    padding: 1em;
    margin: 1em;
`

const Email = styled.input`
    font-size: 1.2em;
    margin-bottom: 20px;
    padding: 3px 10px;
`
const UserMessage = styled.textarea`
    font-size: 1.2em;
    margin-bottom: 20px;
    padding: 3px 10px;
`

const SendButton = styled.div`
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    padding: 5px;
    text-align: center;
    background-color: #dc0000;
    border-radius: 20px;
`
const AccordionContent = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
`

const SendingMessage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    font-size: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
`


const AccordionItemPanelStyled = styled(AccordionItemPanel)`
    position: relative;
    margin-top: -20px;
`

const MessageSent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    letter-spacing: 1px;
    text-transform: uppercase;
`

const SendResultByEmail = ({t, className}) => {

    const [email, setEmail]                         = useState('')
    const [message, setMessage]                     = useState('')
    const [sendingMessage, setSendingMessage]       = useState(false)
    const [messageSent, setMessageSent]             = useState(false)
    const [messageSentResult, setMessageSentResult] = useState(false)

    const handleSendEmail = () => {
        setSendingMessage(true)

        const data      = new FormData()
        const urlParams = getParamsFormUrl()

        data.append('email',        email)
        data.append('message',      message)
        data.append('url',          decodeURI(window.location.href).replace("http://localhost:3000", "https://www.canarias.com"))
        data.append('location',     urlParams.zoneName)
        data.append('startDate',    urlParams.initDate)
        data.append('endDate',      urlParams.endDate)
        data.append('rooms',        buildDistributionSummaryText(urlParams.distributions))
        data.append('language',     urlParams.language)

        const postUrl = window.location.href.indexOf("localhost") > -1 
                            ? "http://localhost:58999/ReactReservation/send-results-by-email"
                            : urls.sendResultByEmail

        fetch(postUrl,
              {            
                  mode:     'cors',
                  method:   'POST',
                  body:     data 
              }).then(res => res.json())
              .then(json => {
                  setSendingMessage(false)
                  setMessageSent(true)
                  setMessageSentResult(json.errorCode)
              }).catch(err => {
                  setSendingMessage(false)
                  setMessageSent(true)
                  setMessageSentResult("generic")
              })  
    }


    const buildDistributionSummaryText = distributions => {
        
        let numAdults     = 0
        let numChildren   = 0
        let numRooms      = 0

        distributions.map(d => {
                numRooms++
                numAdults += parseInt(d.adults)
                numChildren += parseInt(d.children)
            })

        const adultTxt = `${numAdults} ${numAdults === 1 
                                ? t("search_box.room_selection.adult") 
                                : t("search_box.room_selection.adults")}`

        const childrenTxt = numChildren === 0 ? `` 
                                : numChildren === 1 ? `, 1 ${t("search_box.room_selection.child")}` 
                                :  `, ${numChildren} ${t("search_box.room_selection.children")}`
        const roomsTxt    = numRooms === 1 
                                ? ` - 1 ${t("search_box.room_selection.room")}` 
                                : ` - ${numRooms} ${t("search_box.room_selection.rooms")}`

        return adultTxt + childrenTxt + roomsTxt
    }

    const getParamsFormUrl = () => JSON.parse(decodeURI(window.location.href).split('?')[1].split('params=')[1])

    return (
        <div className={className}>
            <Accordion allowZeroExpanded={true} >
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            {t("send_by_email.title")}
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanelStyled>
                        <AccordionContent>
                            <Email 
                                type="text" 
                                placeholder={t("send_by_email.email-placeholder")} 
                                onChange={e => setEmail(e.target.value)}                             
                            />
                            <UserMessage 
                                rows="3" 
                                placeholder={t("send_by_email.message-placeholder")} 
                                onChange={e => setMessage(e.target.value)} />
                            <SendButton onClick={handleSendEmail}>
                                {t("send_by_email.button")}
                            </SendButton>                            
                        </AccordionContent>
                        {sendingMessage &&
                            <SendingMessage>
                                <FontAwesomeIcon icon="sync" className={styles.iconSync} />
                            </SendingMessage>
                        }  
                        {messageSent &&
                            <MessageSent>
                                {t("send_by_email.message_sent_" + messageSentResult)}
                            </MessageSent>
                        }                      
                    </AccordionItemPanelStyled>
                </AccordionItem>
            </Accordion>
        </div>
    )
}
    

export default withTranslation()(SendResultByEmail)