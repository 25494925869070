import React                                from 'react'
import styles                               from './AvailabilityResult.module.css'
import AvailabilityHotel                    from './AvailabilityHotel';
import HotelsFilters                        from './../HotelsFilters/HotelsFilters'
import responsiveHelper                     from './../../utility/responsiveHelper'

export default ({hotelsByZone, facilitiesFilter, filterDisabled, className, hotelsByCodes}) =>  {
    const hotels = hotelsByZone !== null ? hotelsByZone.hotels : hotelsByCodes !== null ? hotelsByCodes.hotelsByCodes : []



    return (
        <div className={`${className ? className : ''} ${styles.main}`}>
            { !responsiveHelper.isMobile() &&
                <HotelsFilters 
                        className={styles.filters} 
                        hotels={hotels} 
                        facilitiesFilter={facilitiesFilter}
                        disabled={filterDisabled}
                />
            }
            <div className={styles.hotels}>
                {hotels.map((hotel, index) => <AvailabilityHotel className={styles.hotel} key={index} hotel={hotel} initialHowManyRoomsShow={1} />)}
            </div>
        </div>
    )
}