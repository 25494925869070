import React from 'react'
import styled from 'styled-components'
import {googleMapUrl} from "./../../utility/constants"

import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;

    @media (max-width: 768px){
        padding: 0;
    }
`

export default compose(
    withProps({
      googleMapURL:     googleMapUrl,
      loadingElement:   <div style={{ height: `100%`, width: `100%` }} />,
      containerElement: <div style={{ height: `100%`, width: `100%` }} />,
      mapElement:       <div style={{ height: `100%`, width: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
  )(props => {
    console.log("props")
    console.log(props)
    console.log(`Latitude ${props.latitude} Longitude ${props.longitude}`)
    return(
      <Container>
        <GoogleMap defaultZoom={12} defaultCenter={{ lat: props.latitude, lng: props.longitude }}>
          <Marker position={{ lat: props.latitude, lng: props.longitude }} />
        </GoogleMap>
      </Container>
    )
  }
)

