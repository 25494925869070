import React 			from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import "./../translations/i18n"
import {urls, resultTypeZone, resultTypeHotel} from './../utility/constants'
import moment from 'moment'

import './booking-process.css'

import HotelDetails  from './HotelDetails'

import HotelSearchBox 		from './HotelSearch/HotelSearchBox'
import SearchingWrapper 	from './SearchingWrapper/SearchingWrapper'
import ErrorBoundary 		from './ErrorBoundary'

import styled from 'styled-components'



// Tosos los iconos de Font Awesome deben importarse individualmente y agregarse a la library,
// luego pueden usarse en los componentes.
// Asì se puede incluir solo lo que se necesita y no todo el package de Font Awasome
import { library }  from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle, faCheckCircle, faInfoCircle, faCheck, faSearch, faSync, faUsers, faPhone, 
		 faMapMarkerAlt, faClock}	from '@fortawesome/free-solid-svg-icons';
import { faSquare as farSquare, faCheckSquare as farCheckSquare}  from '@fortawesome/free-regular-svg-icons';
library.add(faTimesCircle)
library.add(faCheckCircle)
library.add(faCheck)
library.add(faInfoCircle)
library.add(faUsers)
library.add(faSearch)
library.add(faSync)
library.add(farSquare)
library.add(farCheckSquare)
library.add(faPhone)
library.add(faMapMarkerAlt)
library.add(faClock)



const BookingProcess = styled.div`
	width: 100%;	
`

export default ({searchParams}) => {

	// START -- parametros que se pueden pasar por afuera, por crear una busqueda por defecto en base a la pagina donde se pone el buscador
	const titleText = searchParams.titleText
	const isZone = searchParams.zoneId && searchParams.zoneId.length > 0  
	const Code   =  isZone ? searchParams.zoneId : searchParams.hotelId
	const Name   = searchParams.zoneName
	const Type   = isZone ? resultTypeZone : resultTypeHotel
	const channelCode = searchParams.channelCode
	const channelKey  = searchParams.channelKey

	const selectedItem  = { Code, Name, Type }
	const distributions = searchParams.distributionsJson && searchParams.distributionsJson.length > 1 ? JSON.parse(searchParams.distributionsJson) : null
	const subfilter 	= searchParams.subfilter && searchParams.subfilter.length > 1 ? JSON.parse(searchParams.subfilter) : null
	const horizontal 	= searchParams.horizontal !== undefined ? searchParams.horizontal : false
	const startDate 	= searchParams.initDate && new moment(searchParams.initDate, searchParams.dateFormat)
	const endDate 		= searchParams.endDate && new moment(searchParams.endDate, searchParams.dateFormat)
	// END ---

	return (
		<>	
			<ErrorBoundary>	
				<BookingProcess>
					<Router>	  {/* en base  a la url se muestran los resultados, los detalles de un hotel o el buscador */}
						<Switch>
							<Route 
								path={`/:language?${urls.hotelResults}`} 
								render={({location, history}) => <SearchingWrapper 
																	location={location} 
																	history={history} 
																	channelCode={channelCode} 
																	channelKey={channelKey}
																/> 			
								} 
							/>
							<Route 
								path={`/:language?${urls.hotelDetails}`} 
								render={({location, history}) => <HotelDetails     
																	location={location} 
																	history={history} 
																/> 
										} 
							/>
							<Route 
								path="/" 
								render={({location, history}) => 
									<HotelSearchBox   
										titleText={titleText}
										subfilter={subfilter}
										location={location} 
										history={history} 
										selectedItem={selectedItem}   
										distributions={distributions}
										startDate={startDate}
										endDate={endDate}
										horizontal={horizontal}											
									/> 
								} />	
						</Switch>
					</Router>		
				</BookingProcess>
			</ErrorBoundary>
		</>
	)
}