import React        from 'react'
import queryString  from 'query-string'
import {
    searchForCoordinates,
    getHotelsByCoordinates,
    searchByHotelsId
} from "../../utility/SearchMapHelper"

import {
    Map, 
    Marker, 
    InfoWindow,
    GoogleApiWrapper
} from 'google-maps-react'

import {FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./map.module.css"

import styled from "styled-components"



const LoaderContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex; 
    justify-content: center;
    align-items: center;
    font-size: 8em;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.75;
`

class MapContainer extends React.Component {
    constructor(props){
        super()

        this.state = {
            coords: {
                lat: props.initialLat,
                lng: props.initialLng
            },
            loadingResults: false,
            allResults: {
                hotelIds: '',  // es mas performate buscar en un string con todos los id que en un array de string
                hotels: [],
                pages: []
            },
            markers: {},
            searchParams: JSON.parse(queryString.parse(window.location.search).params),
            fitros: {}
        }
    }


    onZoomChanged(){
        console.log("onZoomChanged")
    }

    onDragend(mapProps, map){
        const gBounds = map.getBounds()

        const northEast = gBounds.getNorthEast()
        const southWest = gBounds.getSouthWest()

        const bounds = {
            northEastLat: northEast.lat(),
            northEastLng: northEast.lng(),
            southWestLat: southWest.lat(),
            southWestLng: southWest.lng()
        }

        console.log(bounds)

        try{
            this.getOnePageHotels(bounds, {})
        } catch(err){
            console.log(err)
        }
    }

    async getOnePageHotels(bounds, filtros){
        console.log("map props ", this.props)

        this.setState({loadingResults: true})
        //var result = await searchForCoordinates(this.state.searchParams, bounds, this.state.fitros)
        let newHotelsArray = await getHotelsByCoordinates(bounds)

        // filtrar solo los hoteles que todavia non estan cargados
        let hotelsToAdd    = newHotelsArray.filter(h =>this.state.allResults.hotelIds.indexOf(h.id) === -1) // se añade solo qos hotel que todavia no estan dentro el listado
                                           .slice(0, 20)

        console.log("hotelsToAdd ", hotelsToAdd)                                           
        let newAllHotelsId = this.state.allResults.hotelIds
        
        if(hotelsToAdd.length > 0)
            newAllHotelsId += hotelsToAdd.reduce((acc, curr) => `${acc};${curr.id}`, "")

        let newAllResults = Object.assign({}, this.state.allResults)
        newAllResults.hotelIds = newAllHotelsId

        this.setState({allResults: newAllResults})

        this.addNewMarkers(hotelsToAdd)
        this.setState({loadingResults: false})
        
        if(hotelsToAdd.length === 0)
            return

        const newResults = await searchByHotelsId(hotelsToAdd.map(h => h.id).reduce((acc, curr) => `${acc};${curr}`), "")
        
        newAllResults = Object.assign({}, this.state.allResults)
        newAllResults.hotels = newAllResults.hotels.concat(newResults.hotels)
        
        this.props.onChangeHotels(newAllResults.hotels)

        this.setState({allResults: newAllResults})  
    }
    
    addNewMarkers(newHotels){
        var newMarkers = newHotels.map(h => ({
            lat: parseFloat(h.lat),
            lng: parseFloat(h.lng),
            minPrice: h.minPrice,
            name: h.name            
        }))

        const markers = Object.assign({}, this.state.markers)
        newMarkers.map(m => markers[m.name] = m)

        this.setState({markers})
    }

    
    
    // onZoomChanged not fire
    // https://github.com/tomchentw/react-google-maps/issues/163

    render(){
        if (!this.props.google) {
            return <div>Loading...</div>;
          }
        
        const google = this.props.google

        return ( 
            <>           
                <Map
                    style={{
                        width: this.props.width,
                        height: this.props.height
                    }}
                    google={google}
                    zoom={14}
                    initialCenter={{ lat: this.state.coords.lat, lng: this.state.coords.lng}}                    
                    onDragend={this.onDragend.bind(this)}
                    onZoomChanged={this.onZoomChanged.bind(this)}
                >                            
                    { Object.keys(this.state.markers).map(m => {
                        const marker = this.state.markers[m]
                        return (
                            <Marker 
                                key={marker.name}
                                title={`${marker.minPrice} € | ${marker.name}`}
                                name={marker.name}
                                position={{lat: marker.lat, lng: marker.lng}} 
                                onClick={() => this.props.showHotelDetails(marker.name)}                     
                            /> 
                        )
                    })}                    
                </Map>                      
            </>
        )
    }

}

export default GoogleApiWrapper({apiKey: 'AIzaSyDGsFaj339wkwoHbhoaoZcO-0kvFxjmuU8'})(MapContainer)