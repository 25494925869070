export default {
    translation: {
        "send_by_email.message_sent_1":                     "Something went wrong...",
        "send_by_email.message_sent_0":                     "Email sent",
        "send_by_email.email-placeholder":                  "Your email",
        "send_by_email.message-placeholder":                "write a message",
        "send_by_email.title":                              "Send by email",
        "send_by_email.button":                             "Send",        

        "map.open-button":                                  "View Map",
        
        

        "hotel_blocking_error":                             "Booking Error",
        "hotel_blocking_error_button":                      "Book Another Hotel",

        "filters.filters":                                  "Open Filters",
        "resultDeal.totalPriceText":                        "Total price",

        "resultDeal.roomTypeTitle":                         "type of room",
        "resultDeal.changeRoomTitle":                       "change room",
        "resultDeal.changeRoomsTitle":                      "change rooms",
        "resultDistribution.roomsQuantity":                 "(__num__ rooms more)",

        "hotel_blocking.message":                           "We are creating your reservation...",

        "hotel_details_not_available":                      "Details not available",
        "distributions.up_to_x_person":                     "Uo to __num__ people",

        "hotel_details.description":                        "Description",
        "hotel_details.map":                                "Map",
        "hotel_details.reviews":                            "Reviews",
        "hotel_details.CheckIn":                            "Arrival",
        "hotel_details.CheckOut":                           "Leave",        
        "hotel_details.FacilitiesTitle":                    "Equipment",        
        "canariasComments.title":                           "Reviews Canarias.com",        
        "canariasComments.rateCleaning":                    "Cleaning",
        "canariasComments.rateDreamQuality":                "Sleep quality",
        "canariasComments.rateDrinkingSelection":           "Selection of beverages",
        "canariasComments.rateFood":                        "Food",
        "canariasComments.rateLocation":                    "Location",
        "canariasComments.rateQualityPriceRelation":        "Value for money",
        "canariasComments.rateRooms":                       "Bedrooms",
        "canariasComments.rateService":                     "Service",
        "canariasComments.resumeTitle":                     "Scores Canarias.com",
        "canariasComments.numberOfComments":                "Based on __num__ reviews",        
        "canariasComments.fromUser":                        "by",        
        "canariasComments.fromPlace":                       "from",    
        "canariasComments.trip_type_1":                     "Business trip",                                
        "canariasComments.trip_type_2":                     "Travel in couple",
        "canariasComments.trip_type_3":                     "Family travel",
        "canariasComments.trip_type_4":                     "Travel of friends",
        "canariasComments.trip_type_5":                     "Trip of solitaire",

        "TripadvisorComments.title":                        "Reviews TripAdvisor",        
        "TripadvisorComments.resumeTitle":                  "Scores TripAdvisor",                
        "TripadvisorComments.readMore":                     "Read more",        
        "TripadvisorComments.NumerOfComments":              "Based on __num__ reviews",        

        "TripadvisorComments.rate_value":                   "Value for money",        
        "TripadvisorComments.rate_cleanliness":             "Cleaning",        
        "TripadvisorComments.rate_sleep":                   "Sleep quality",                
        "TripadvisorComments.rate_service":                 "Service",        
        "TripadvisorComments.rate_location":                "Location",        
        "TripadvisorComments.rate_room":                    "Bedrooms",        
        
        "TripadvisorComments.TripType":                     "Type of Trip",              
        "TripadvisorComments.NULL":                         "n.d.", 
        "TripadvisorComments.business":                     "For business", 
        "TripadvisorComments.couples":                      "In couple", 
        "TripadvisorComments.family":                       "In family", 
        "TripadvisorComments.friends":                      "With friends", 
        "TripadvisorComments.solo":                         "Lonely", 

        
        "RoomNotesModalInfo":                               "Info",
        "search_box.horizontal_label_place":                "Use our Hotel finder to find your perfect hotel",
        "search_box.label_place":                           "Type a hotel, city...",
        "search_box.place_placeholder":                     "Type your hotel or destination",
        "search_box.label_pickup_date":                     "Entry date",
        "search_box.label_dropoff_date":                    "Departure date",
        "search_box.label_room_and_people":                 "No. of rooms and people",
        "search_box.search_button_text":                    "Search",
        "search_box.room_selection.confirm_button_text":    "Confirm",
        "search_box.room_selection.adult":                  "Adult",        
        "search_box.room_selection.adults":                 "Adults",
        "search_box.room_selection.child":                  "Child",        
        "search_box.room_selection.children":               "Children",
        "search_box.room_selection.childrenAges":           "Children ages",
        "search_box.room_selection.room":                   "Room",   
        "search_box.room_selection.rooms":                  "Rooms",          
        "search_box.place":                                 "Place",  
        "search_box.hotels":                                "Hotels",  
        "search_box.errors.errors_title":                   "There are the following errors: ", 
        "search_box.errors.select_place":                   "Select a place o a hotel", 
        "search_box.errors.wrong_start_date":               "The pickup date isn't valid", 
        "search_box.errors.wrong_end_date":                 "The drop off date isn't valid", 
        "search_box.errors.wrong_distributions":            "The distribution isn't valid",
        "filters.change_search":                            "Change Search",                
        "filters.main_title":                               "Filter by Name",
        "filters.filter_by_category":                       "Filter by Category",
        "filters.filter_by_deal":                           "Filter by Deal",
        "filters.filter_by_facilities":                     "Filter by Facilities",
        "filters.filter_by_price":                          "Filter by Price",
        "filters.button_filter_text":                       "Filter",
        "filters.button_remove_filter_text":                "Remove Filters",
        "deal.button_select_text":                          "Select",
        "deal.button_modify_text":                          "Modify",    
        "deal_selection.button_select_text":                "Select",

        "category.0":		"Not available",
        "category.0L":		"Not availablee",
        "category.1":		"1",
        "category.10":		"Luxury",
        "category.11":		"Offer",
        "category.13":		"13",
        "category.1L":		"1L",
        "category.2":		"2",
        "category.20":		"3L",
        "category.23":		"3",
        "category.26":		"26",
        "category.28":		"28",
        "category.29":		"29",
        "category.2L":		"2L",
        "category.3":		"3",
        "category.30":		"30",
        "category.31":		"31",
        "category.32":		"32",
        "category.34":		"34",
        "category.36":		"36",
        "category.3L":		"3L",
        "category.4":		"4",
        "category.40":		"40",
        "category.4L":		"4L",
        "category.5":		"5",
        "category.5L":		"5L",
        "category.8":		"First",
        "category.93":		"93",
        "category.A":		"A",
        "category.AB":		"Hostel",
        "category.AGRO":	"Agrotourism",
        "category.Ak":		"Touristic Apartment",
        "category.AP":		"Apartahotel",
        "category.AR":		"Rural Apartment",
        "category.AT":		"Apartment",
        "category.BA":		"Spa",
        "category.BB":		"BB",
        "category.BO":		"Boutique",
        "category.BU":		"Bungalow",
        "category.CA":		"Castle",
        "category.CAM":		"CAM",
        "category.CB":		"Cabin",
        "category.CC":		"Cave Houses",
        "category.CE":		"Special Category",
        "category.CH":		"Guesthouse",
        "category.CM":		"Rural Complex",
        "category.CO":		"Farmhouse",
        "category.CP":		"Camping",
        "category.CR":		"Rural house",
        "category.CS":		"Rural house with SPA",
        "category.CT":		"Rural Tourism Center",
        "category.Cz":		"Cz",
        "category.FO":		"Inn",
        "category.H1":		"Lodging House *",
        "category.H2":		"Lodging House **",
        "category.HO":		"Lodging House",
        "category.HP":		"Hospedería",
        "category.HR":		"Rural hotel",
        "category.HS":		"Hotel With SPA",
        "category.HT":		"Hotel",
        "category.IN":		"Not available",
        "category.LO":		"Lodge",
        "category.MH":		"Mini Hotel",
        "category.MO":		"Monastery",
        "category.MT":		"Motel",
        "category.PA":		"Hostel",
        "category.PE":		"Hotel Pension",
        "category.PENDI":	"Pendi",
        "category.PO":		"Pousada",
        "category.PR":		"Posada Real",
        "category.RA":		"RA",
        "category.RE":		"Residential",
        "category.RS":		"Superior Category Rural Hotel",
        "category.RT":		"Resort",
        "category.ST":		"Standard",
        "category.TU":		"Tourist",
        "category.TUS":		"Superior Tourist",
        "category.VI":		"Villa",
        "category.VV":		"Vacation Homes",

        "package.Add":      "Add an Experience",
        "package.Selected": "SELECTED",
        "package.Select":   "SELECT",
        "package.Book":     "Book",

        "availability.NoResults": "We cannot find availabilty. Try again by modifying your search.",

        "availability.orderBy.Recommended": "Recommended",
        "availability.orderBy.Price": "Price",
        "availability.orderBy.Category": "Category",
    }
}
