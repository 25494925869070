import React            from 'react'
import styled           from 'styled-components'
import BookingHelper    from './../../utility/bookingHelper'

export const ButtonSelect = styled.div`
    color: #fff;
    cursor: pointer;
    text-transform: uppercase; 
    padding: 5px;
    width: 150px; 
    text-align: center;
    background-color: #5cb85c;
    border-radius: 20px;

    @media (max-width: 1378px){
        width: 90%;
        margin-top: 40px;
    }

    @media (max-width: 768px){     
        margin-top: 10px;
    }
`

export default ({selectedRooms, packages, sendSelectedRoomsAndPackage, onCustomClick, text}) => 
    <ButtonSelect onClick={() => {
                        const selectedDistributionsId = selectedRooms.map(r => r.distributionId)
                        const roomPackages            = packages && packages.filter(p => selectedDistributionsId.filter(d => d.distributionId === p.distributionId))
                        const hasNotPackage           = roomPackages && roomPackages.length === 0 
                        const isExternalProvider       = selectedRooms[0].room.providerName !== 'Joinrates'
                        
                        if(isExternalProvider){
                            const multipleUrls = selectedRooms.map(r => r.room.bookingLink)
                            const singleUrl    = BookingHelper.groupBookingUrls(multipleUrls)

                            window.open(singleUrl)
                            return
                        }else if(hasNotPackage)
                            sendSelectedRoomsAndPackage()   
                            
                        onCustomClick(roomPackages)
                    }}
    >{text}</ButtonSelect> 