import {
    urls
} from "../utility/constants"

const getParamsFormUrl = () => JSON.parse(decodeURI(window.location.href).split('?')[1].split('params=')[1])

export const getHotelsByCoordinates = async bounds => {
    const data = new FormData()
    const urlParams = getParamsFormUrl()
    const urlBase = process.env.API_URL_BASE;
    
    data.append('northEastLat', bounds.northEastLat)
    data.append('northEastLng', bounds.northEastLng)
    data.append('southWestLat', bounds.southWestLat)
    data.append('southWestLng', bounds.southWestLng)
    data.append('pageSize',     urlParams.pageSize)
    data.append('pageNum',      urlParams.pageNum)

    const postUrl = urlBase + urls.getHotelsByCoordinates;/*window.location.href.indexOf("localhost") > 1
                     ? `http://localhost:5000${urls.getHotelsByCoordinates}`
                     : urls.getHotelsByCoordinates*/
    
    const response = await fetch(postUrl,
        {
            mode: 'cors',
            method: 'POST',
            body: data
        })

    return await response.json()
}

export const searchByHotelsId = async (hotelsId) => {
    const data = new FormData()
    const urlParams = getParamsFormUrl()
    const urlBase = process.env.API_URL_BASE;

    data.append('hotelsId', hotelsId)
    data.append('language', urlParams.language)
    data.append('startDate', urlParams.initDate)
    data.append('endDate', urlParams.endDate)
    data.append('isCanaryResident', urlParams.isCanaryResident === true)
    data.append('distributions', JSON.stringify(urlParams.distributions))
    data.append('filters', JSON.stringify(urlParams.filters ) || JSON.stringify({}))
    data.append('pageSize', urlParams.pageSize)
    data.append('pageNum', urlParams.pageNum)

    const postUrl = urlBase + urls.availabiltyByHotelsId;
    /*const postUrl = window.location.href.indexOf("localhost") > 1
            ? `http://localhost:5000${urls.availabiltyByHotelsId}`
            : urls.availabiltyByHotelsId*/

    const response = await fetch(postUrl,
        {
            mode: 'cors',
            method: 'POST',
            body: data
        })            

    return await response.json()
}

export const searchForCoordinates = async (searchParams, bounds, filtros) => {

    const data      = new FormData()
    const urlParams = getParamsFormUrl()
    const urlBase = process.env.API_URL_BASE;

    data.append('northEastLat', bounds.northEastLat)
    data.append('northEastLng', bounds.northEastLng)
    data.append('southWestLat', bounds.southWestLat)
    data.append('southWestLng', bounds.southWestLng)

    data.append('language',      urlParams.language)

    data.append('startDate',     urlParams.initDate)
    data.append('endDate',       urlParams.endDate)
    
    data.append('isCanaryResident', urlParams.isCanaryResident)
    
    data.append('distributions',    JSON.stringify(urlParams.distributions))    
    data.append('filters',          urlParams.filters)
    data.append('pageSize',         urlParams.pageSize)
    data.append('pageNum',          urlParams.pageNum)

    const postUrl = urlBase + urls.searchByCoordinates;
    /*const postUrl = window.location.href.indexOf("localhost") > -1 
                        ? `http://localhost:5000${urls.searchByCoordinates}`
                        : urls.searchByCoordinates*/

    const response = await fetch(postUrl,
                           {            
                                mode:     'cors',
                                method:   'POST',
                                body:     data 
                           })
    
    return await response.json()
}